var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "_tac _pb-2" }, [
    _vm._v(
      "\n    " + _vm._s(_vm.Resources.Layout.WebPushEmptyStateParagraph) + "\n"
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }