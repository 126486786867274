var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-view",
    [
      _c("account-navigation", { attrs: { "page-identity": _vm.pageID } }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "gfu-layout__main gfu-layout__main--narrow" },
        [
          _c("search-title", {
            class: _vm.searchTitleClassList,
            attrs: {
              title: _vm.Resources.MyProjects.PageHeader,
              "total-item-count": _vm.totalItemCount,
              "data-qa": "search-result",
            },
          }),
          _vm._v(" "),
          _vm.areFiltersVisible
            ? [
                _c("search-projects-header", {
                  attrs: {
                    "selected-filters": _vm.currentFilters,
                    "total-item-count": _vm.totalItemCount,
                    "sort-type": _vm.searchParams.sortType,
                  },
                  on: {
                    "filters-open": _vm.onFiltersOpen,
                    "filter-delete": _vm.onFilterDelete,
                    "sort-open": _vm.onSortOpen,
                  },
                }),
                _vm._v(" "),
                _c("search-projects-filter", {
                  attrs: {
                    "selected-filters": _vm.selectedFilters,
                    "is-drawer-open": _vm.isFilterDrawerOpen,
                    categories: _vm.projectCategories,
                  },
                  on: {
                    search: _vm.onSearch,
                    close: _vm.onFiltersClose,
                    clear: _vm.onFilterClear,
                  },
                }),
                _vm._v(" "),
                _c("search-projects-sort", {
                  attrs: {
                    "selected-filters": _vm.selectedFilters,
                    "is-drawer-open": _vm.isSortDrawerOpen,
                  },
                  on: { search: _vm.onSearch, close: _vm.onSortClose },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          !_vm.areProjectsLoaded
            ? _c(
                "div",
                { staticClass: "_flexcol _gap-6" },
                _vm._l(_vm.projectsToShowCount, function (p) {
                  return _c("skeleton-card", {
                    key: p,
                    attrs: { variant: "horizontal" },
                  })
                }),
                1
              )
            : [
                _vm.totalItemCount
                  ? _c(
                      "recommendation-list-provider",
                      {
                        attrs: {
                          items: _vm.projectsToShow,
                          "slider-position": 3,
                          "can-load-more-items": _vm.canLoadMoreRows,
                          "data-qa": "my-projects-items",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "project-engagement-card",
                                    _vm._b(
                                      {
                                        staticClass: "_mb-6",
                                        on: {
                                          action: _vm.onCardAction,
                                          "action-dissmissed":
                                            _vm.onActionDissmissed,
                                        },
                                      },
                                      "project-engagement-card",
                                      item,
                                      false
                                    )
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1279653907
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("project-recommendations", {
                          staticClass: "_my-6",
                          attrs: {
                            quantity: 8,
                            source: "myProjects",
                            "data-qa": "Recommendations",
                          },
                        }),
                      ],
                      1
                    )
                  : [
                      _c(
                        "box",
                        {
                          staticClass: "_mt-2",
                          attrs: { "data-qa": "search-result:EmptyState" },
                        },
                        [
                          _c(
                            "empty-state",
                            {
                              attrs: {
                                title:
                                  _vm.Resources.MyProjects.EmptyStateBoxHeader,
                              },
                            },
                            [
                              _vm.isAuthenticated
                                ? [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.Resources.MyProjects
                                            .EmptyStateBoxParagraph
                                        ) +
                                        "\n                            "
                                    ),
                                    _vm.isAuthenticated
                                      ? _c(
                                          "base-button",
                                          {
                                            staticClass: "_mt-3",
                                            attrs: { theme: "accent" },
                                            on: { click: _vm.onFiltersOpen },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.Resources.MyProjects
                                                    .EmptyStateBoxActionButton
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("project-recommendations", {
                        staticClass: "_mt-6",
                        attrs: {
                          quantity: 8,
                          source: "myProjects",
                          "data-qa": "Recommendations",
                        },
                      }),
                    ],
                _vm._v(" "),
                _vm.isProcessing
                  ? _c(
                      "div",
                      { staticClass: "_flexcol _gap-6" },
                      _vm._l(_vm.projectsToShowCount, function (p) {
                        return _c("skeleton-card", {
                          key: p,
                          attrs: { variant: "horizontal" },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
              ],
          _vm._v(" "),
          _vm.canLoadMoreRows && !_vm.isProcessing
            ? _c("load-more-trigger", { on: { "load-more": _vm.loadMoreRows } })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }