var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: ["gfu-comment-field", { "gfu-comment-field": !_vm.hasNickname }],
      attrs: { "data-qa": "project-comments-field:AddNew" },
    },
    [
      _vm.projectContext.isInTestMode
        ? _c(
            "base-notificator",
            { staticClass: "_mb-3", attrs: { "can-close": false } },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.Resources.Comments.CommentsInTestModeInfoParagraph
                  ) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: ["gfu-comment-field__wrap", { "is-focused": _vm.isFocused }],
          on: { click: _vm.focus },
        },
        [
          _vm.hasNickname
            ? _c(
                "div",
                { staticClass: "gfu-media gfu-media--middle _mb-1" },
                [
                  !_vm.isEdit
                    ? _c("base-image", {
                        staticClass:
                          "gfu-comment-field__avatar gfu-avatar gfu-avatar--clean _mr-1",
                        attrs: {
                          src: _vm.userContext.avatarUrl,
                          alt: _vm.user.nickname,
                          width: "24",
                          height: "24",
                          loading: "lazy",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "gfu-media__body gfu-comment-field__author",
                    },
                    [
                      !_vm.isEdit
                        ? _c("h5", { staticClass: "gfu-hd gfu-hd--h4 _mr-a" }, [
                            _vm._v(_vm._s(_vm.user.nickname)),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isEdit
                        ? _c(
                            "div",
                            {
                              staticClass: "gfu-bt gfu-bt--b2 _tc--light _mr-a",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.Resources.Comments
                                    .CommentFieldEditingHeader
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "tooltip",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "content",
                                fn: function () {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.Resources.Comments
                                              .CommentFieldCapabilitiesHeader
                                          )
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.Resources.Comments
                                              .CommentFieldCapabilitiesOption1
                                          ) +
                                          "\n                        "
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3676223390
                          ),
                        },
                        [_c("span", { staticClass: "_fa _fa--info" })]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "field",
            {
              staticClass: "_mt-0",
              attrs: {
                name: "text",
                "show-label": false,
                "display-name": "comment",
              },
              model: {
                value: _vm.text,
                callback: function ($$v) {
                  _vm.text = $$v
                },
                expression: "text",
              },
            },
            [
              _c("div", {
                ref: "commentInput",
                staticClass: "gfu-comment-field__editor",
                attrs: {
                  lang: _vm.selectedLanguage,
                  contenteditable: "true",
                  placeholder: _vm.Resources.Comments.CommentFieldPlaceholder,
                  "data-qa": "comment-input",
                },
                on: {
                  focus: _vm.onFocus,
                  blur: _vm.onBlur,
                  paste: _vm.onPaste,
                  keypress: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.onEnterKeypress.apply(null, arguments)
                  },
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    if (!$event.ctrlKey) return null
                    $event.preventDefault()
                    return _vm.onCtrlEnterKeydown.apply(null, arguments)
                  },
                },
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "gfu-fade" } }, [
        _c(
          "div",
          { staticClass: "gfu-comment-field__actions" },
          [
            _vm.isCancelable
              ? _c(
                  "a",
                  {
                    staticClass: "gfu-link gfu-link--accent _ttl",
                    attrs: {
                      href: "#",
                      "data-qa": "project-comment-button:Cancel",
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.onCancel.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.Resources.Common.ModalCancelButton))]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "base-button",
              {
                staticClass: "gfu-btn--micro _ml-3",
                attrs: {
                  theme: "accent",
                  enabled: _vm.isSubmitable,
                  "data-qa": "project-comment-button:Send",
                },
                on: { click: _vm.onSubmit },
              },
              [_vm._v(_vm._s(_vm.submitLabel))]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }