var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isExtended && _vm.productData
        ? [
            _c(
              "div",
              { class: ["gfu-product__amount-wrapper", _vm.wrapperClassList] },
              [
                _vm.showQuantityInput && !_vm.effectiveReadonly
                  ? _c(
                      "div",
                      { staticClass: "gfu-product__amount _fc _flex-basis-33" },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "gfu-product__amount-btn gfu-product__amount-btn--plus gfu-btn gfu-btn--icon gfu-btn--primary",
                            attrs: {
                              type: "button",
                              "data-qa": "add-product-to-cart-button:Plus",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.changeQuantity(1)
                              },
                            },
                          },
                          [_c("span", { staticClass: "_fa _fa--plus" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "gfu-product__amount-btn gfu-product__amount-btn--minus gfu-btn gfu-btn--icon gfu-btn--primary",
                            attrs: {
                              type: "button",
                              "data-qa": "add-product-to-cart-button:Minus",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.changeQuantity(-1)
                              },
                            },
                          },
                          [_c("span", { staticClass: "_fa _fa--minus" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "gfu-product__amount-input" },
                          [
                            _c("number-field", {
                              staticClass: "_mt-0",
                              attrs: {
                                name: "model.quantity",
                                "show-label": false,
                                integer: true,
                                min: "1",
                                "validator-layout": "none",
                                "data-qa": "add-product-to-cart-quantity",
                              },
                              on: { enter: _vm.displayProductSetOrSubmit },
                              model: {
                                value: _vm.effectiveQuantity,
                                callback: function ($$v) {
                                  _vm.effectiveQuantity = $$v
                                },
                                expression: "effectiveQuantity",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "gfu-product__submit _flex-basis-66",
                    attrs: { "data-qa": "add-product-to-cart-section" },
                  },
                  [
                    _vm.isDisabled
                      ? _c(
                          "tooltip",
                          {
                            attrs: {
                              text: _vm.tooltipMessage,
                              "data-qa": "add-product-to-cart-button-tooltip",
                            },
                          },
                          [
                            _c(
                              "base-button",
                              {
                                class: _vm.buttonClasses,
                                attrs: {
                                  enabled: false,
                                  display: "block",
                                  theme: "disabled",
                                  "data-qa":
                                    "add-product-to-cart-button:AddToCart",
                                },
                              },
                              [
                                _c("span", { staticClass: "gfu-btn__text" }, [
                                  _vm._v(_vm._s(_vm.buttonText)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "base-button",
                          {
                            class: [_vm.buttonClasses],
                            attrs: {
                              theme: _vm.buttonTheme,
                              display: "block",
                              "data-qa": "add-product-to-cart-button:AddToCart",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.displayProductSetOrSubmit.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "gfu-btn__text" }, [
                              _vm._v(_vm._s(_vm.buttonText)),
                            ]),
                            _vm._v(" "),
                            _vm.showIcon
                              ? _c("span", {
                                  class: [
                                    _vm.getIconPositionClass(),
                                    "_ga _ga--plus-bold-icon",
                                  ],
                                })
                              : _vm._e(),
                          ]
                        ),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm.isDisabled
        ? _c("div", [
            _vm.tooltipMessage
              ? _c(
                  "div",
                  [
                    _c(
                      "tooltip",
                      {
                        staticClass: "gfu-1of1",
                        attrs: {
                          text: _vm.tooltipMessage,
                          "show-on-init": _vm.forceShowTooltip,
                          "data-qa": "add-product-to-cart-button-tooltip",
                        },
                      },
                      [
                        _c(
                          "base-button",
                          {
                            class: _vm.buttonClasses,
                            attrs: {
                              enabled: false,
                              display: "block",
                              theme: "disabled",
                              "data-qa": "add-product-to-cart-button:AddToCart",
                            },
                          },
                          [
                            _c("span", { staticClass: "gfu-btn__text" }, [
                              _vm._v(_vm._s(_vm.buttonText)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "base-button",
                      {
                        class: _vm.buttonClasses,
                        attrs: {
                          enabled: false,
                          display: "block",
                          theme: "disabled",
                          "data-qa": "add-product-to-cart-button:AddToCart",
                        },
                      },
                      [
                        _c("span", { staticClass: "gfu-btn__text" }, [
                          _vm._v(_vm._s(_vm.buttonText)),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
          ])
        : _c(
            "base-button",
            {
              class: [_vm.buttonClasses, "add-product-button-marker"],
              attrs: {
                display: "block",
                theme: _vm.buttonTheme,
                "data-qa": "add-product-to-cart-button:AddToCart",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.displayOptionsOrSubmit.apply(null, arguments)
                },
              },
            },
            [
              _vm._t("buttonContents", function () {
                return [
                  _c("span", { staticClass: "gfu-btn__text" }, [
                    _vm._v(_vm._s(_vm.buttonText)),
                  ]),
                  _vm._v(" "),
                  _vm.showIcon
                    ? _c("base-icon", {
                        class: [_vm.getIconPositionClass()],
                        attrs: {
                          name: "plus-bold-icon",
                          "icon-namespace": "ga",
                        },
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
      _vm._v(" "),
      _c("progress-overlay", {
        attrs: { visible: _vm.isProcessing, sticky: true },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }