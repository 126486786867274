import { defineStore } from 'pinia';
import { useProjectContextStore } from '@/Stores/Web/projectContextStore';
import { apiClient } from '@/Clients/ApiClient.js';
import CartsService from '@/Services/CartsService';
import InitialState from '@/InitialState';
import { orderPaymentStatuses } from '@/Models';

const cartService = new CartsService(apiClient);

export const useProjectUserContextStore = defineStore({
    id: 'projectUserContext',
    state: () => ({
        projectUserContext: {
            projectID: null,
            userID: null,
            creditBalance: 0,
            backerNumber: null,
            hasOrderWithPaymentAction: false,
            relationType: null,
            // In case of v1 projectUserState is null
            ...InitialState.projectUserState?.projectUserContext ?? {},
        },
        orderWithPaymentAction: null,
    }),
    actions: {
        async loadOrderProcessingPayment() {
            const projectContextStore = useProjectContextStore();
            if (this.projectUserContext?.hasOrderWithPaymentAction && projectContextStore.isV2) {
                const { data } = await cartService.getOrderWithPaymentAction(projectContextStore.projectContext.projectID);
                if (data) {
                    this.orderWithPaymentAction = data;
                }
            }
        },
    },
    getters: {
        hasPositiveCreditsBalance: state => {
            return state.creditBalance > 0;
        },
        creditBalance: state => {
            return state.projectUserContext?.creditBalance ?? 0;
        },
        backerNumber: state => {
            return state.projectUserContext?.backerNumber ?? null;
        },
        isInProcessingPaymentMode: state => {
            return state.orderWithPaymentAction?.cart.orderPaymentStatus === orderPaymentStatuses.processingPayment;
        },
    },
});