var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "_pos-r _bgc--white _rounded--common gfu-background-swept _py-2 _px-2",
    },
    [_c("skeleton-line", { attrs: { height: 33 } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }