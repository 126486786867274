import router from '../Router';
import Scroller from '@/Utils/Scroller';
import { throttle } from '@/Utils';
import { displayMode } from './DisplayModes';
import { displayModeTypes } from '@/Utils/PwaUtility';

const allMagicAnchors = [];
let initializeQueued = false;
let isInitializing = true;
let isScrollingIntoView = false;
let isUpdatingRouteFromScrolling = false;
const isPWA = displayMode === displayModeTypes.pwa;

export default class MagicAnchor {
    static disableAll = false;
    static baseOffset = 0;
    // navbar height
    static defaultOffset = isPWA ? -160 : -78;
    static scrollLatency = 20; // ms

    constructor(targetElement, location, verticalOffset = null, scrollTop = false) {
        this.targetElement = targetElement;
        this.location = location;
        this.resolvedLocation = router.resolve(location);
        this.verticalOffset = verticalOffset || MagicAnchor.defaultOffset;
        this.scrollTop = scrollTop;
        this.isEnabled = true;

        this.createElements();

        allMagicAnchors.push(this);

        if (!initializeQueued) {
            initializeQueued = true;

            if (history.scrollRestoration) {
                history.scrollRestoration = 'manual';
            }

            document.addEventListener('DOMContentLoaded', () => {
                MagicAnchor.onRouteChange(router.currentRoute);
                router.afterEach(MagicAnchor.onRouteChange);
                window.addEventListener('scroll', throttle(MagicAnchor.onScroll, MagicAnchor.scrollLatency, {
                    leading: true,
                    trailing: true,
                }),
                { passive: true });
                isInitializing = false;
            });
        }
    }

    createElements() {
        this.containerElement = document.createElement('span');
        this.anchorElement = document.createElement('span');

        this.containerElement.style.cssText = 'display: block; position: relative;';
        this.anchorElement.style.cssText
            = `display: block; position: absolute; left: 0; top: ${ MagicAnchor.baseOffset + this.verticalOffset }px`;

        this.containerElement.appendChild(this.anchorElement);

        if (this.targetElement?.firstElementChild) {
            this.targetElement.insertBefore(this.containerElement, this.targetElement.firstElementChild);
        }
        else {
            this.targetElement.appendChild(this.containerElement);
        }
    }

    static onScroll() {
        if (isScrollingIntoView || MagicAnchor.disableAll)
            return;

        let closestAnchor = null;
        let closestAnchorDistance = 1000;

        allMagicAnchors.forEach(anchor => {
            if (!anchor.isEnabled)
                return;

            const boundingRect = anchor.anchorElement.getBoundingClientRect();
            const distance = Math.abs(boundingRect.top);

            if (distance <= closestAnchorDistance) {
                closestAnchor = anchor;
                closestAnchorDistance = distance;
            }
        });

        if (closestAnchor !== null && router.currentRoute.fullPath !== closestAnchor.resolvedLocation.route.fullPath) {
            isUpdatingRouteFromScrolling = true;
            router.replace(closestAnchor.location);
            isUpdatingRouteFromScrolling = false;
        }
    }

    static onRouteChange(to, from) {
        MagicAnchor.scrollToAnchor(to);
    }

    static scrollToAnchor(to) {
        if (isUpdatingRouteFromScrolling || MagicAnchor.disableAll)
            return;

        const matchingAnchor = allMagicAnchors.find(a => {
            return a.isEnabled && a.resolvedLocation.route.fullPath === to.fullPath;
        });

        if (matchingAnchor) {
            isScrollingIntoView = true;
            const scrollOptions = { duration: isInitializing ? 0 : 250 };

            if (matchingAnchor.scrollTop) {
                // scroll to top of window
                Scroller.toY(0, scrollOptions.duration, () => {
                    window.setTimeout(() => {
                        isScrollingIntoView = false;
                    }, 100);
                });
            }
            else {
                // scroll to matchingAnchor
                Scroller.to(matchingAnchor.anchorElement, scrollOptions.duration, () => {
                    window.setTimeout(() => {
                        isScrollingIntoView = false;
                    }, 100);
                });
            }
        }
    }
}