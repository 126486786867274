var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-layout-wrapper _flexcntr" }, [
    _c("div", { staticClass: "gfu-1of1 gfu-2of4--m gfu-1of3--l" }, [
      _c(
        "div",
        {
          staticClass: "gfu-box gfu-box--thick _mt-6 _mb-3",
          attrs: { "data-qa": "reset-password-box" },
        },
        [
          _c(
            "h1",
            { staticClass: "gfu-heading gfu-heading--soft gfu-box__title" },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.Resources.ResetPassword.ResetPasswordHeader) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "_tac _mb-1" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.Resources.ResetPassword.ResetPasswordParagraph) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass: "gfu-fields",
                  attrs: { "data-qa": "request-reset-password-form" },
                },
                [
                  _c(
                    "fieldset",
                    [
                      _c(
                        "field",
                        {
                          attrs: {
                            "display-name":
                              _vm.Resources.ResetPassword
                                .ResetPasswordEmailFieldLabel,
                            name: "model.email",
                            "data-qa": "request-reset-password-form:Email",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.email,
                                expression: "model.email",
                              },
                            ],
                            staticClass: "gfu-field__input",
                            attrs: {
                              type: "email",
                              name: "email",
                              "data-qa": "text-field-input",
                            },
                            domProps: { value: _vm.model.email },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.submit.apply(null, arguments)
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.model,
                                  "email",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "_tac _mt-6" },
                        [
                          _c(
                            "field",
                            [
                              _c(
                                "base-button",
                                {
                                  attrs: {
                                    theme: "accent",
                                    display: "block",
                                    width: "wide",
                                    "data-qa":
                                      "request-reset-password-form-button:ResetPassword",
                                  },
                                  on: { click: _vm.submit },
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.Resources.ResetPassword
                                          .ResetPasswordSubmitButton
                                      ) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("progress-overlay", {
                attrs: {
                  visible: _vm.isProcessing,
                  "data-qa": "request-reset-password-form:ProgressOverlay",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }