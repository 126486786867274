import { mapActions, mapState, mapStores } from 'pinia';
import { validationUnfollowProjectResult } from '@/Models.js';
import { useUserContextStore } from '@/Stores/Web/userContextStore.js';
import { useUserFactsStore } from '@/Stores/Web/userFactsStore.js';

import MessageBox from '@/Utils/MessageBox';
import UsersService from '@/Services/UsersService';
import AnalyticsTracking from '@/Plugins/AnalyticsTracking';
import { useProjectStore } from '@/Stores/Web/projectStore.js';
import Resources from '@/Resources.js';

export default {
    data: () => ({
        isFollowRequestProcessing: false,
    }),
    methods: {
        async followProject(projectId, projectName, section) {
            try {
                this.isFollowRequestProcessing = true;
                const follow = await this.userFactsStore.followProject(projectId, section);
                this.handleFollowRequest(follow.data.data, projectId, projectName);
            }
            catch (error) {
                this.$notify.error(error);
            }
            finally {
                this.isFollowRequestProcessing = false;
            }
        },
        async unfollowProject(projectId, projectName) {
            try {
                this.isFollowRequestProcessing = true;
                const validateUnfollow = await this.validateUnfollow(projectId);
                const unfollow = async () => {
                    const unfollow = await this.userFactsStore.unfollowProject(projectId);
                    this.handleUnfollowRequest(unfollow.data.data, projectId, projectName);
                };

                const unfollowValidationAction = {
                    [validationUnfollowProjectResult.promoItem]:
                        () => this.showPromoItemUnfollowConfirmation(unfollow),
                    [validationUnfollowProjectResult.none]:
                        () => unfollow(),
                };

                return unfollowValidationAction[validateUnfollow.validationResult]();
            }
            catch (error) {
                this.$notify.error(error);
            }
            finally {
                this.isFollowRequestProcessing = false;
            }
        },
        async validateUnfollow(projectId) {
            const userService = new UsersService(this.$http);
            const validation = await userService.validateUnfollow(projectId);

            if (validation.data.success) {
                return validation.data.data;
            }
            else {
                throw new Error(validation.data.message);
            }
        },
        toggleFollowProject(projectId, projectUrl, projectName, source) {
            const isUserFollowingProject = this.isUserFollowingProject(projectId);

            if (!this.userContextStore.isAuthenticated) {
                this.userFactsStore.showUserLoginModal({ action: 'follow-project', projectUrl });
            }
            else {
                isUserFollowingProject
                    ? this.unfollowProject(projectId, projectName)
                    : this.followProject(projectId, projectName, source);
                this.sendTrackingEvents(isUserFollowingProject, source, projectName);
            }
        },
        showPromoItemUnfollowConfirmation(yesCallback) {
            const title = Resources.Homepage.UnfollowProjectWarningTitle;
            const message = Resources.Homepage.UnfollowProjectWarningText;
            MessageBox.question(message, title, yesCallback);
        },
        isUserFollowingProject(projectId) {
            return this.userFactsStore.followedProjectsIDs.includes(projectId);
        },
        handleFollowRequest(data, projectId, projectName) {
            if (data.notificationSubscriptionChanged) {
                this.$notify.popupSuccess(Resources.format(Resources.Homepage.FollowProjectSuccessToast, projectName), { qa: 'FollowSuccess' });

                this.setProjectFollowerFacts({
                    projectID: projectId,
                    followersCount: this.getProjectFollowerCount(projectId) + 1,
                });
            }
        },
        handleUnfollowRequest(data, projectId, projectName) {
            if (data.notificationSubscriptionChanged) {
                this.$notify.info(Resources.format(Resources.Homepage.UnfollowProjectSuccessToast, projectName), { qa: 'UnfollowInfo' });

                this.setProjectFollowerFacts({
                    projectID: projectId,
                    followersCount: this.getProjectFollowerCount(projectId) - 1,
                });
            }
        },
        sendTrackingEvents(isFollowing, source, projectName) {
            const tracking = new AnalyticsTracking();
            if (isFollowing) {
                tracking.unfollowTracking({ projectName, source });
            }
            else {
                tracking.followTracking({ projectName, source });
            }
        },
        getProjectFollowerCount(projectID) {
            const facts = this.getProjectFollowerFacts || { projectID, followersCount: 0 };
            return facts.followersCount;
        },
        ...mapActions(useProjectStore, ['setProjectFollowerFacts']),
    },
    computed: {
        // @TODO: Move follower facts to project statistics
        ...mapState(useProjectStore, ['getProjectFollowerFacts']),
        ...mapStores(useUserContextStore, useUserFactsStore),
    },
};