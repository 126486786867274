
    import AnalyticsTracking from '@/Plugins/AnalyticsTracking';
    import { clientAnalyticsEventTypes } from '@/Models';
    export default {
        props: {
            eventType: { type: Number, default: null },
            projectName: { type: String, default: null },
            projectID: { type: Number, default: null },
            eventSource: { type: String, default: null },
        },
        render() {
            return null;
        },
        methods: {
            sendEvent() {
                const tracking = new AnalyticsTracking();
                switch (this.eventType) {
                    case clientAnalyticsEventTypes.followProject:
                        tracking.followTracking({ projectName: this.projectName, source: this.eventSource });
                        break;
                    default:
                        break;
                }
            },
        },
        mounted () {
            this.sendEvent();
        },
    };
