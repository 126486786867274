var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "project-explorer",
    _vm._b(
      {
        scopedSlots: _vm._u([
          {
            key: "prefix",
            fn: function () {
              return [
                _c("h2", { staticClass: "gfu-hd gfu-hd--h2 _mb-1" }, [
                  _vm._v(
                    _vm._s(
                      _vm.Resources.ProjectHome.ProjectRecommendationsTitle
                    )
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "localize",
                  {
                    attrs: {
                      resource:
                        _vm.Resources.ProjectHome.ProjectRecommendationsText,
                    },
                  },
                  [
                    _c(
                      "span",
                      { attrs: { "format-key": "0" } },
                      [
                        _c(
                          "base-link",
                          {
                            staticClass: "gfu-link gfu-link--accent",
                            attrs: { url: "/projects/search" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.Resources.ProjectHome
                                  .ProjectRecommendationsSearchLink
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "project-explorer",
      _vm.explorerProps,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }