import { defineStore } from 'pinia';

import { useCurrencyStore } from '@/Stores/Web/currencyStore';
import { useProjectContextStore } from '@/Stores/Web/projectContextStore';
import { useOrderStore } from '@/Stores/Web/orderStore';

import { apiClient } from '@/Clients/ApiClient';
import CheckoutService from '@/Services/CheckoutService';

const checkoutService = new CheckoutService(apiClient);

export const useInstallmentsStore = defineStore({
    id: 'installments',
    state: () => ({
        installments: [],
        currencySymbol: null,
        installmentValue: null,
        isChargingInstallments: false,
        actionType: null,
        newPlanAmountConverted: null,
        previousPlanAmountConverted: null,
        upgradeAmountConverted: null,
    }),
    actions: {
        async getInstallmentsData(projectId, exchangeRate, orderCode = null) {
            try {
                if (orderCode) {
                    return await checkoutService.calculateOrderInstallmentPlan(projectId, exchangeRate, orderCode);
                }

                return await checkoutService.calculateCartInstallmentPlan(projectId, exchangeRate);
            }
            catch (error) {
                throw new Error(error.message);
            }
        },
        setInstallments({ data }) {
            this.installments = data.installments;
            this.currencySymbol = data.currencySymbol;
            this.actionType = data.actionType;
            this.newPlanAmountConverted = data.newPlanAmountConverted;
            this.previousPlanAmountConverted = data.previousPlanAmountConverted;
            this.upgradeAmountConverted = data.upgradeAmountConverted;
            // We take the last installment because the first installment may be larger if it cannot be divided evenly.
            this.installmentValue = data.installments[data.installments.length - 1].amountConverted;
        },
        async handleInstallmentsDataFlow() {
            const { projectID, installmentsSettings } = useProjectContextStore();
            const { conversionRate } = useCurrencyStore();

            const numberOfInstallments = installmentsSettings?.installmentsNumber;
            const { data } = await this.getInstallmentsData(projectID, conversionRate, numberOfInstallments);
            this.setInstallments(data);
        },
        setActionStatus(status) {
            this.isChargingInstallments = status;
        },
    },
    getters: {
        installmentsCount() {
            return this.installments?.length;
        },
        firstInstallmentAmount() {
            return this.installments[0]?.amountConverted;
        },
        canBePaidInInstallments() {
            const { installmentsSettings } = useProjectContextStore();
            const { currentOrderDetails } = useOrderStore();
            return currentOrderDetails?.isEligibleForInstallments
            && currentOrderDetails?.cart.effectivePayableAmount >= installmentsSettings?.installmentsMinPaymentAmount;
        },
    },
});