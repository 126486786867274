var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.classNames },
    [
      _c(
        "tooltip",
        {
          staticClass: "_screen _screen--all",
          attrs: { text: _vm.description },
        },
        [
          _c("base-icon", {
            class: _vm.classNames,
            attrs: {
              name: _vm.iconName,
              size: "3x",
              "icon-namespace": _vm.iconNamespace,
            },
          }),
          _vm._v(" "),
          _c("h5", { staticClass: "gfu-hd gfu-hd--h4 _mt-3" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }