var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "gfu-box _mb-6",
          attrs: { "data-qa": "basic-contact-box" },
        },
        [
          _c(
            "h2",
            {
              staticClass: "gfu-hd gfu-hd--h1 _mb-4 _pt-2",
              attrs: { "data-qa": "contact-form-title" },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.Resources.Contact.TopicsSectionHeader) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "gfu-filters",
              attrs: { "data-qa": "contact-form-option:ProjectSearch" },
            },
            [
              _c("text-field", {
                staticClass: "gfu-flex__item--grow _mt-0",
                attrs: {
                  placeholder:
                    _vm.Resources.Contact.ContactSearchFormPlaceholder,
                  "display-name": _vm.Resources.Contact.ContactSearchFormName,
                  "auto-complete": "off",
                },
                model: {
                  value: _vm.searchTerm,
                  callback: function ($$v) {
                    _vm.searchTerm = $$v
                  },
                  expression: "searchTerm",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("h3", { staticClass: "gfu-hd gfu-hd--h4 _mt-3" }, [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.searchTerm.length === 0
                    ? _vm.Resources.Contact.ContactSearchSuggestedTitle
                    : _vm.Resources.Contact.ContactSearchResultsTitle
                ) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _vm._l(_vm.projectsCategoryGroups, function (project) {
            return _c("issue-card", {
              key: `project-${project.projectID}`,
              attrs: {
                header: project.name,
                "image-url": project.thumbImageUrl,
                subtitle: _vm.Resources.format(
                  _vm.Resources.Contact.ContactSearchResultByCreatorLabel,
                  project.creator.name
                ),
                "data-qa": `issue-card-project:${project.projectID}`,
              },
              on: {
                "header-click": function ($event) {
                  return _vm.selectProject(project)
                },
              },
            })
          }),
          _vm._v(" "),
          _vm.canLoadMore && !_vm.searchTerm.length > 0
            ? _c(
                "button",
                {
                  staticClass:
                    "gfu-contact-more gfu-btn gfu-btn--block gfu-btn--light-alt _mt-3",
                  attrs: { "data-qa": "contact-form-option:LoadMore" },
                  on: { click: _vm.loadRelevantProjectsPromise },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.Resources.Common.LoadMoreButton) +
                      "\n            "
                  ),
                  _c("span", {
                    staticClass:
                      "_fa _fa--angle-down fa-2x _tc--accent _ml-1 _tvam",
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isSearching
            ? _c(
                "div",
                {
                  staticClass: "gfu-issue__no-results _mt-1",
                  attrs: { "data-qa": "contact-form:Searching" },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.Resources.Contact.ContactSearchInProgressLabel)
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isSearching &&
          _vm.projectsCategoryGroups.length === 0 &&
          _vm.searchTerm.length > 0
            ? _c(
                "div",
                {
                  staticClass: "gfu-issue__no-results _mt-1",
                  attrs: { "data-qa": "contact-form:NoProjectFound" },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.Resources.Contact.ContactSearchNoResultsParagraph
                      )
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "h4",
            {
              staticClass: "gfu-hd gfu-hd--h4 _mt-3",
              attrs: { "data-qa": "contact-form-title" },
            },
            [_vm._v(_vm._s(_vm.Resources.Contact.OtherIssuesSectionHeader))]
          ),
          _vm._v(" "),
          _vm._l(_vm.othersCategoryGroups, function (categoryGroup) {
            return _c("issue-card", {
              key: `categoryGroup-${categoryGroup.value}`,
              attrs: {
                header: categoryGroup.displayName,
                "data-qa": `contact-form-option:${categoryGroup.displayName}`,
              },
              on: {
                "header-click": function ($event) {
                  return _vm.selectGroup(categoryGroup)
                },
              },
            })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "gfu-box",
          attrs: { "data-qa": "business-contact-box" },
        },
        [
          _c(
            "h2",
            {
              staticClass: "gfu-hd gfu-hd--h1 _mb-4",
              attrs: { "data-qa": "contact-form-title" },
            },
            [_vm._v(_vm._s(_vm.Resources.Contact.BusinessContactSectionHeader))]
          ),
          _vm._v(" "),
          _vm._l(_vm.businessCategoryGroups, function (categoryGroup) {
            return _c("issue-card", {
              key: `categoryGroup-${categoryGroup.value}`,
              attrs: {
                header: categoryGroup.displayName,
                "data-qa": `contact-form-option:${categoryGroup.displayName}`,
              },
              on: {
                "header-click": function ($event) {
                  return _vm.selectGroup(categoryGroup)
                },
              },
            })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("progress-overlay", {
        attrs: { sticky: true, visible: _vm.isProcessing },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }