var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tooltip",
    { attrs: { "info-text": _vm.title, enabled: _vm.title } },
    [
      _c(
        "div",
        { staticClass: "_flex" },
        [
          !_vm.isLoaded
            ? _c("skeleton-avatar", {
                staticClass: "gfu-avatar _bgc--light",
                style: {
                  width: _vm.avatarSize + "px",
                  height: _vm.avatarSize + "px",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("base-image", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLoaded,
                expression: "isLoaded",
              },
            ],
            class: _vm.classNames,
            attrs: {
              src: _vm.avatarUrl,
              alt: _vm.alt,
              width: _vm.avatarSize,
              height: _vm.avatarSize,
            },
            on: { load: _vm.onLoad },
          }),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }