var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "_flexcol _gap-2" }, [
    _c("div", { staticClass: "gfu-scroll-section" }, [
      _c("div", { staticClass: "_flex _-mx-2 gfu-scroll-container" }, [
        _c(
          "div",
          { staticClass: "_px-2" },
          [
            !_vm.hasMoreThanOneCategory ? _vm._t("prefix") : _vm._e(),
            _vm._v(" "),
            _vm.hasMoreThanOneCategory
              ? _c(
                  "nav",
                  { staticClass: "_flex _gap-2" },
                  _vm._l(_vm.mappedCategories, function (category) {
                    return _c(
                      "base-tab",
                      {
                        key: category.key,
                        attrs: {
                          tag: "a",
                          href: category.url,
                          title: category.label,
                          variant: "link",
                          "is-active": category.isActive,
                          "data-qa": `explore-category:${category.label}`,
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onCategoryClick(category.key)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(category.label) +
                            "\n                    "
                        ),
                      ]
                    )
                  }),
                  1
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      !_vm.isTablet
        ? _c(
            "div",
            [
              _c(
                "base-button",
                {
                  attrs: {
                    theme: "white",
                    shape: "circle",
                    shadow: "tight",
                    disabled: _vm.isScrollLeftDisabled,
                    "data-qa": "left-arrow-button",
                  },
                  on: { click: _vm.onScrollLeft },
                },
                [
                  _c("base-icon", {
                    staticClass: "_tc--accent",
                    attrs: { name: "chevron-left", size: "lg" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "base-button",
                {
                  attrs: {
                    theme: "white",
                    shape: "circle",
                    shadow: "tight",
                    disabled: _vm.isScrollRightDisabled,
                    "data-qa": "right-arrow-button",
                  },
                  on: { click: _vm.onScrollRight },
                },
                [
                  _c("base-icon", {
                    staticClass: "_tc--accent",
                    attrs: { name: "chevron-right", size: "lg" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "_pos-r _-mx-2" }, [
      _c(
        "div",
        {
          ref: "scrollContainer",
          class: [
            "_flex gfu-scroll-container gfu-project-explorer-card-wrapper",
            { "is-busy": _vm.isBusy },
          ],
        },
        [
          !_vm.isProcessing && _vm.hasItems
            ? [
                _vm._l(_vm.projectItems, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.props.projectID,
                      class: [_vm.cardWrapperClasses, "_pa-2 _shrink-0"],
                      attrs: {
                        "data-qa": `home-explore-project:${item.props.projectID}`,
                      },
                    },
                    [
                      _c(
                        item.component,
                        _vm._b(
                          { tag: "component" },
                          "component",
                          item.props,
                          false
                        )
                      ),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _vm.canLoadMoreRows
                  ? [
                      _c(
                        "intersection-trigger",
                        {
                          attrs: {
                            root: _vm.$refs.scrollContainer,
                            "root-margin": `0px 0px 0px 0px`,
                            threshold: 0,
                          },
                          on: { intersected: _vm.onIntersected },
                        },
                        _vm._l(_vm.placeholders, function (placeholder) {
                          return _c(
                            "div",
                            {
                              key: placeholder,
                              class: [
                                _vm.cardWrapperClasses,
                                "_pa-2 _shrink-0",
                              ],
                            },
                            [_c("skeleton-card", { staticClass: "_h-full" })],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  : _vm._e(),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "_flex _grow-1" },
                  _vm._l(_vm.placeholders, function (placeholder) {
                    return _c(
                      "div",
                      {
                        key: placeholder,
                        class: [_vm.cardWrapperClasses, "_h-full"],
                      },
                      [_c("skeleton-card", { staticClass: "_h-full" })],
                      1
                    )
                  }),
                  0
                ),
              ],
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _vm.currentCategory
      ? _c(
          "div",
          { staticClass: "_flex _jc-fe" },
          [
            _c(
              "base-link",
              {
                staticClass: "gfu-link gfu-link--accent",
                attrs: {
                  url: _vm.currentCategory.url,
                  title: "view all projects",
                  "data-qa": `view-projects-button:${_vm.currentCategory.actionLabel}`,
                },
                on: { click: _vm.onViewAllClick },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.currentCategory.actionLabel) +
                    "\n            "
                ),
                _c("base-icon", { attrs: { name: "chevron-right" } }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }