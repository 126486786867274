var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("footer-navigation", {
        attrs: { "current-page": _vm.Resources.Layout.ContactLink },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-layout-wrapper" }, [
        _c("div", { staticClass: "gfu-grid gfu-grid--gutter _flex" }, [
          _c(
            "div",
            {
              staticClass:
                "gfu-grid__cell gfu-1of3--l gfu-4of6--m gfu-1of1 _mx-a",
            },
            [
              _c(
                "div",
                {
                  staticClass: "gfu-box gfu-box--thick _my-3",
                  attrs: { "data-qa": "email-sent-box" },
                },
                [
                  _c(
                    "h2",
                    {
                      staticClass:
                        "gfu-heading gfu-heading--soft gfu-box__title gfu-box__title--success",
                      attrs: { "data-qa": "request-sent-confirmation" },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.Resources.Contact.PlatformContactRequestSentTitle
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "gfu-box__section" }, [
                    _vm._v(
                      _vm._s(
                        _vm.Resources.Contact.PlatformContactRequestSentText
                      )
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }