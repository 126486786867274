var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "box",
    {
      staticClass: "gfu-project-engagement-card",
      attrs: { "data-qa": `project-engagement-card-ID:${_vm.projectID}` },
    },
    [
      _c(
        "base-link",
        {
          staticClass: "gfu-thumb gfu-project-engagement-card__media _jc-c",
          attrs: { url: _vm.projectPageUrl },
        },
        [
          _c(
            "picture",
            { staticClass: "gfu-thumb__item", attrs: { loading: "lazy" } },
            [
              _c("source", {
                attrs: { media: "(max-width: 768px)", srcset: _vm.imageUrl },
              }),
              _vm._v(" "),
              _c("source", {
                attrs: {
                  media: "(min-width: 769px)",
                  srcset: _vm.thumbImageUrl,
                },
              }),
              _vm._v(" "),
              _c("img", { attrs: { src: _vm.imageUrl, alt: _vm.name } }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-project-engagement-card__body" },
        [
          _c(
            "div",
            { staticClass: "gfu-bt gfu-bt--caption _tc--light _mt-0 _mb-1" },
            [
              _c(
                "strong",
                {
                  class: ["_ttu", _vm.phaseLabelTheme],
                  attrs: { "data-qa": "project-card:ProjectPhaseLabel" },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.phaseLabel) +
                      "\n            "
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "h3",
            { staticClass: "gfu-hd gfu-hd--h2 _mb-3" },
            [
              _c(
                "base-link",
                {
                  attrs: { url: _vm.projectPageUrl, "data-qa": "project-name" },
                },
                [_vm._v(_vm._s(_vm.name))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasAction
            ? _vm._l(_vm.projectEngagementCardActions, function (action) {
                return _c(
                  "project-action-notificator",
                  _vm._b(
                    {
                      key: action.actionLabel,
                      on: { "action-dismiss": _vm.handleActionDismiss },
                    },
                    "project-action-notificator",
                    action,
                    false
                  )
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "gfu-project-engagement-card__row",
              attrs: { "data-qa": "project-engagement-row" },
            },
            [
              _c(
                "span",
                { attrs: { "data-qa": "engagement-type-description" } },
                [_vm._v(_vm._s(_vm.engagementLabel))]
              ),
              _vm._v(" "),
              _c(
                "base-link",
                {
                  staticClass: "gfu-link gfu-link--accent",
                  attrs: {
                    url: _vm.involvementUrl,
                    "data-qa": `engagement-type-action:${_vm.engagementActionLabel}`,
                  },
                },
                [_vm._v(_vm._s(_vm.engagementActionLabel))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.additionalPledgeInformation, function (row) {
            return _c(
              "div",
              {
                key: row.label,
                staticClass: "gfu-project-engagement-card__row",
                attrs: { "data-qa": row.dataQa },
              },
              [
                _c("span", { attrs: { "data-qa": row.labelDataQa } }, [
                  _vm._v(_vm._s(row.label)),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "_tc--dark" },
                  [
                    _c("b", { attrs: { "data-qa": row.valueDataQa } }, [
                      _vm._v(_vm._s(row.value)),
                    ]),
                    _vm._v(" "),
                    row.tooltipVisibility
                      ? _c("tooltip", {
                          attrs: { "icon-name": "info" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "content",
                                fn: function () {
                                  return [
                                    _c(
                                      "project-engagement-card-tooltip-content",
                                      { attrs: { content: row.tooltip } }
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "gfu-project-engagement-card__labels",
              attrs: { "data-qa": "project-starting-ending-badge" },
            },
            _vm._l(_vm.labels, function (label) {
              return _c(
                "base-badge",
                {
                  key: label.name,
                  staticClass: "_mr-1 _my-1",
                  attrs: { theme: label.theme },
                },
                [_vm._v(_vm._s(label.name))]
              )
            }),
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm.hasBallonActions
        ? _c("balloon-actions", {
            attrs: {
              actions: _vm.actions,
              mode: _vm.balloonActionsModes.grouped,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }