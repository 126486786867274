var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-standalone-layout _flexcol _jc-c _ai-c" },
    [
      _c(
        "div",
        { staticClass: "_pb-9" },
        [
          _c(
            "box",
            { staticClass: "_flexcol _jc-c _ai-c" },
            [
              _c("base-icon", {
                staticClass: "_tc--error _lh-1 _mb-2",
                attrs: { name: "times-circle", size: "4x" },
              }),
              _vm._v(" "),
              _c(
                "base-heading",
                { attrs: { tag: "h2", "data-qa": "payment-error:Title" } },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.Resources.Checkout.PaymentErrorBoxTitle) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "_my-3 _tac _px-8",
                  attrs: { "data-qa": "payment-error:Description" },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.Resources.Checkout.PaymentErrorBoxDescription
                      ) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "base-button",
                {
                  attrs: {
                    tag: "a",
                    theme: "accent",
                    "data-qa": "payment-error:GoToCheckoutButton",
                  },
                  on: { click: _vm.onGoToCheckout },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.Resources.Checkout.PaymentErrorGoToCheckoutLabel
                      ) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "base-link",
                {
                  staticClass: "gfu-link _ttl _mt-1",
                  attrs: {
                    url: _vm.yourPledgeUrl,
                    "data-qa": "payment-error:ViewYourPledgeButton",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onGoToYourPledge.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.Resources.Checkout.PaymentErrorViewYourPledgeLabel
                      ) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }