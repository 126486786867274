var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.allowSearch && _vm.sections.length > 0
        ? _c(
            "div",
            { staticClass: "gfu-filters" },
            [
              _c("text-field", {
                staticClass: "gfu-flex__item--grow _mt-0",
                attrs: {
                  "display-name": _vm.searchFieldLabel,
                  placeholder: _vm.searchFieldPlaceholder,
                  "auto-complete": "off",
                  "data-qa": "contact-form-search",
                },
                model: {
                  value: _vm.searchTerm,
                  callback: function ($$v) {
                    _vm.searchTerm = $$v
                  },
                  expression: "searchTerm",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.resultsToDisplay, function (section) {
        return [
          section.projectFaqSectionID
            ? _c("issue-card-foldable", {
                key: section.projectFaqSectionID,
                ref: "sections",
                refInFor: true,
                attrs: {
                  id: section.projectFaqSectionID,
                  "show-icons": _vm.showIcons,
                  header: section.encodedHeader,
                  content: section.content,
                  "edited-at": section.editedAt,
                  "search-term-regex": _vm.searchTermRegex,
                  "data-qa": `contact-form-option:${section.projectFaqSectionID}`,
                },
                on: {
                  "header-click": function ($event) {
                    return _vm.onSectionHeaderClick(section)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    section.projectFaqSectionID
                      ? {
                          key: "subContent",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "_mt-6" }, [
                                section.usefullness === undefined
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gfu-issue__option-collapsible-sub-content-wrapper",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gfu-issue__option-collapsible-sub-content-text",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.Resources.Faq
                                                    .FeedbackFormParagraph
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gfu-issue__option-collapsible-sub-content",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "gfu-btn gfu-btn--soft gfu-btn--micro gfu-btn--thin gfu-btn--capitalize gfu-btn--accent gfu-issue__option-collapsible-sub-content-button",
                                                attrs: {
                                                  "data-qa":
                                                    "issue-box-button:Yes",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.markSectionUsefulness(
                                                      section,
                                                      true
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "_fa _fa--check-circle gfu-btn__icon gfu-issue__option-collapsible-sub-content-button-icon",
                                                }),
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(
                                                      _vm.Resources.Common
                                                        .ModalYesButton
                                                    ) +
                                                    "\n                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "gfu-btn gfu-btn--soft gfu-btn--micro gfu-btn--thin gfu-btn--capitalize gfu-btn--primary gfu-issue__option-collapsible-sub-content-button",
                                                attrs: {
                                                  "data-qa":
                                                    "issue-box-button:No",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.markSectionUsefulness(
                                                      section,
                                                      false
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "_fa _fa--times-circle gfu-btn__icon gfu-issue__option-collapsible-sub-content-button-icon",
                                                }),
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(
                                                      _vm.Resources.Common
                                                        .ModalNoButton
                                                    ) +
                                                    "\n                            "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gfu-notificator gfu-notificator--not-empty _ma-0 _mt-3",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gfu-notificator-item gfu-notificator-item--permanent gfu-notificator-item--fill-mobile",
                                            class: [
                                              section.usefullness
                                                ? "gfu-notificator-item--positive"
                                                : "gfu-notificator-item--negative",
                                            ],
                                          },
                                          [
                                            section.usefullness === true
                                              ? _c(
                                                  "span",
                                                  {
                                                    attrs: {
                                                      "data-qa":
                                                        "issue-box-helpful:Yes",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                " +
                                                        _vm._s(
                                                          _vm.Resources.format(
                                                            _vm.Resources.Faq
                                                              .PositiveFeedbackSubmittedParagraph,
                                                            _vm.projectName
                                                          )
                                                        ) +
                                                        "\n                            "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  {
                                                    attrs: {
                                                      "data-qa":
                                                        "issue-box-helpful:No",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "localize",
                                                      {
                                                        attrs: {
                                                          resource:
                                                            _vm.Resources.Faq
                                                              .NegativeFeedbackSubmittedParagraph,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "gfu-link gfu-link--accent-plain",
                                                            attrs: {
                                                              href: "",
                                                              "format-key": "0",
                                                              "data-qa":
                                                                "issue-box-contact-link",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.onSectionNegativeFeedbackClick(
                                                                  section
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                        " +
                                                                _vm._s(
                                                                  _vm.Resources
                                                                    .Common
                                                                    .ClickHereButton
                                                                ) +
                                                                "\n                                    "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                          ]
                                        ),
                                      ]
                                    ),
                              ]),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              })
            : _c("issue-card", {
                key: section.header,
                attrs: {
                  header: section.encodedHeader,
                  "search-term-regex": _vm.searchTermRegex,
                  "show-icons": _vm.showIcons,
                  "data-qa": `contact-form-option:${section.categoryKey}`,
                },
                on: {
                  "header-click": function ($event) {
                    return _vm.onSectionHeaderClick(section)
                  },
                },
              }),
        ]
      }),
      _vm._v(" "),
      _vm.sections.length === 0 ? _vm._t("empty-result") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.shouldInvokeSearch && _vm.searchResults.length === 0,
              expression: "shouldInvokeSearch && searchResults.length === 0",
            },
          ],
          staticClass: "gfu-issue__no-results",
          attrs: {
            searchTerm: _vm.searchTerm,
            "data-qa": "contact-form-search:NoResults",
          },
        },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.Resources.Common.EmptyStateSearchParagraph)),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }