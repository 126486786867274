import { defineStore } from 'pinia';
import { apiClient } from '@/Clients/ApiClient';
import UserNotificationsService from '@/Services/UserNotificationsService';

export const usePlatformNotificationStore = defineStore({
    id: 'platformNotification',
    state: () => ({
        unreadNotificationCount: 0,
        hasUnreadUserPendingActions: false,
        notifications: [],
        pendingActions: [],
    }),
    actions: {
        async fetchUnreadCount() {
            const userNotificationsService = new UserNotificationsService(apiClient);
            const response = await userNotificationsService.getUnreadNotificationsCount();
            const { hasUnreadUserPendingActions, unreadNotificationsCount } = response.data;

            this.unreadNotificationCount = unreadNotificationsCount;
            this.hasUnreadUserPendingActions = hasUnreadUserPendingActions;
        },
        async fetchUserNotifications() {
            const userNotificationsService = new UserNotificationsService(apiClient);
            const response = await userNotificationsService.getUserNotifications();
            const { notifications, pendingActions } = response.data;

            this.notifications = notifications;
            this.pendingActions = pendingActions;
        },
        setPendingActions(pendingActions) {
            this.pendingActions = pendingActions;
        },
    },
});