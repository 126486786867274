var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        title: _vm.Resources.Checkout.UtilizeBonusAmountModalTitle,
        icon: "piggy-bank",
        "is-processing": _vm.isProcessing,
        "is-visible": _vm.isVisible,
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "_flexwrap-reverse _ai-c _gap-2" },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "gfu-link gfu-link--accent _ttl _grow-1 _tac",
                      attrs: {
                        href: "",
                        "data-qa": "modal-link:KeepBonusAmount",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.close.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.Resources.Checkout
                              .UtilizeBonusAmountCancelButton
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "base-button",
                    {
                      staticClass: "_grow-1",
                      attrs: {
                        size: "default",
                        width: "compact",
                        "text-transform": "none",
                        wide: true,
                        bold: true,
                        theme: "accent",
                        icon: "excel",
                        enabled: !_vm.isProcessing,
                        "data-qa": "modal-button:UseBonusAmount",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.Resources.Checkout.UtilizeBonusAmountSubmitButton
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("p", [
        _vm._v(
          _vm._s(_vm.Resources.Checkout.UtilizeBonusAmountModalDescription)
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }