
import { getFormattedPrice } from '@/Components/Orders/OrderUtility.js';
import { orderItemTypes, orderItemPaymentStatuses } from '@/Models';
import Resources from '@/Resources';

// This validation is on the backend, so it must be consistent...
export const maxDefaultQuantity = 99999;

export const cartTableModes = {
    display: 'display',
    edit: 'edit',
};

export function createModelForEditOrderItem(orderItem) {
    return {
        price: orderItem.price,
        taxValue: orderItem.taxValue,
        basePrice: orderItem.basePrice,
        maxQuantity: orderItem.maxQuantity,
        imageUrl: orderItem.imageUrl,
        name: orderItem.name,
        options: orderItem.options,
        productID: orderItem.productId,
        productSetItems: orderItem?.subitems?.map(subitem => ({
            canChangeOptions: subitem.canChangeOptions,
            orderItemID: subitem.orderItemID,
            productID: orderItem.productId,
            quantity: subitem.quantity,
            setItem: {
                ...subitem.product,
                options: subitem?.product?.options?.map((option, index) => ({
                    ...option,
                    ...subitem.options.find(o => o.productOptionID === option.productOptionID) || {},
                })) || [],
            },
            setItemID: subitem.product.productID,
            sortOrder: subitem.product.sortOrder,
        })),
        productUrl: orderItem.productUrl,
        quantity: orderItem.quantity,
        type: orderItem.orderItemType,
        orderItemID: orderItem.orderItemId,
    };
}

export function createProductOrderItemsModel(order, handleTax, isInEditMode) {
    return order?.productOrderItems?.filter(i => [
        orderItemTypes.productSet,
        orderItemTypes.product,
    ].includes(i.orderItemType)).map(i => ({
        productId: i.productID,
        name: i.displayName,
        basePrice: i.product.price,
        orderItemId: i.orderItemID,
        price: getFormattedPrice(handleTax, i.netPriceConverted, i.priceConverted, i.quantity),
        taxValue: Math.abs(i.priceConverted - i.netPriceConverted),
        taxConverted: i.taxConverted,
        quantity: i.quantity,
        validationResult: i.validationResult,
        isActionRequired: i.isActionRequired,
        handleTax,
        orderItemSource: i.orderItemSource,
        canBeDeleted: i.canBeDeleted && isInEditMode,
        canBeEdited: i.canBeEdited && isInEditMode,
        canChangeOptions: i.canChangeOptions && isInEditMode,
        canChangeQuantity: i.canChangeQuantity && isInEditMode,
        imageUrl: i.product.imageUrl.replace('smallthumb', 'productimage'),
        options: createProductOptionsModel(i, isInEditMode),
        currencySymbol: order?.cart.currencySymbol,
        maxQuantity: Math.max(i.product.copiesAvailable, i.product.perUserCopiesAvailable) || maxDefaultQuantity,
        orderItemType: i.orderItemType,
        productType: i.product.type,
        paymentDate: i.paymentDate,
        paymentStatus: i.paymentStatus,
        subitems: order?.productOrderItems?.filter(si => si.parentOrderItemID === i.orderItemID)
            .map(i => ({
                ...i,
                productId: i.productID,
                name: i.displayName,
                quantity: i.quantity,
                options: i.options,
                currencySymbol: order?.cart.currencySymbol,
                imageUrl: i.product.imageUrl.replace('smallthumb', 'productimage'),
                productType: i.product.type,
                canChangeOptions: i.canChangeOptions && isInEditMode,
            })),
    }));
}

function createProductOptionsModel(orderItem, isInEditMode) {
    const { options: productOptions = [] } = orderItem.product || {};

    if (!isInEditMode) {
        return orderItem.options.map((option, index) => ({
            ...option,
            text: option.translatedValueText,
            values: productOptions[index]?.values || [],
        }));
    }

    return productOptions.map(option => {
        const selectedOption = orderItem.options.find(
            ({ productOptionID }) => productOptionID === option.productOptionID,
        ) || {};
        return {
            ...option,
            ...selectedOption,
        };
    });
}

export function groupOrderItems(productOrderItems, customOrderItems) {
    const unpaidItemsModel = {
        title: Resources.YourPledge.CartTableUnpaidItemsTitle,
        productOrderItems: productOrderItems.filter(i => !isPaid(i)),
        customOrderItems: customOrderItems.filter(i => !isPaid(i)),
    };

    const groupedPaidItemsModel = groupPaidOrderItemsByDate(
        productOrderItems.filter(i => isPaid(i)),
        customOrderItems.filter(i => isPaid(i)),
    );

    const originalOrderModel = {
        title: Resources.YourPledge.CartTableOriginalOrderTitle,
        ...groupedPaidItemsModel.shift(),
    };

    const modifiedOrderModel = groupedPaidItemsModel.map(group => ({
        title: Resources.format(Resources.YourPledge.CartTableItemsAddedOnTitle, group.paymentDate),
        ...group,
    }));

    return [originalOrderModel, ...modifiedOrderModel, unpaidItemsModel]
        .filter(group => group.productOrderItems?.length > 0 || group.customOrderItems?.length > 0);
}

function isPaid(orderItem) {
    return orderItem.paymentStatus !== orderItemPaymentStatuses.unpaid && orderItem.paymentDate;
}

function groupPaidOrderItemsByDate(productOrderItems, customOrderItems) {
    const groupedItems = {};

    function addToGroup(items, type) {
        items.forEach(item => {
            const dateTime = new Date(item.paymentDate).toISOString(); // Extract full datetime
            if (!groupedItems[dateTime]) {
                groupedItems[dateTime] = { productOrderItems: [], customOrderItems: [] };
            }
            groupedItems[dateTime][type].push(item);
        });
    }

    addToGroup(productOrderItems, 'productOrderItems');
    addToGroup(customOrderItems, 'customOrderItems');

    const groupedArray = Object.keys(groupedItems).map(dateTime => ({
        paymentDate: new Date(dateTime),
        productOrderItems: groupedItems[dateTime].productOrderItems,
        customOrderItems: groupedItems[dateTime].customOrderItems,
    }));

    // Sort the result by date, from oldest to newest
    groupedArray.sort((a, b) => new Date(a.paymentDate) - new Date(b.paymentDate));

    return groupedArray;
}