var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-navbar-item gfu-navbar-dropdown" },
    [
      _c(
        "toggle-trigger",
        {
          class: _vm.toggleTriggerClasses,
          attrs: {
            tag: "button",
            tabindex: "0",
            "active-class": "is-visible",
            "auto-collapse": true,
            "container-full-screen-width": _vm.isMobile,
          },
          on: { collapse: _vm.onCollapse },
        },
        [
          _c(
            "base-icon",
            {
              staticClass: "_pos-r",
              attrs: {
                name: "bell",
                size: "lg",
                "data-qa": "header-actions:Notifications",
              },
              on: { click: _vm.onNotificationIconClick },
            },
            [
              _vm.isNotificationBadgeVisible
                ? _c(
                    "div",
                    {
                      class: _vm.notificationBadgeClasses,
                      attrs: {
                        "data-qa":
                          "notification-icon:UnseenNotificationsNumber",
                      },
                    },
                    [
                      _vm.unseenNotificationsCount
                        ? _c("span", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.unseenNotificationsCount) +
                                "\n                "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "toggle-container",
        {
          staticClass: "gfu-navbar-dropdown__content",
          attrs: { "active-class": "is-visible", tabindex: "0" },
          on: { "active-change": _vm.onNotificationManagerOpen },
        },
        [
          _vm.isBaseBalloonVisible
            ? _c(
                "base-balloon",
                {
                  attrs: {
                    width: "wide",
                    position: "right",
                    "is-pointer-visible": false,
                    "data-qa": "notification-box-content",
                  },
                },
                [
                  !_vm.isContentLoaded
                    ? _c(
                        "div",
                        { staticClass: "_px-2" },
                        _vm._l(_vm.placeholders, function (placeholder) {
                          return _c("skeleton-notification", {
                            key: placeholder,
                            staticClass: "_mt-2",
                          })
                        }),
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "gfu-push-notification-list _flexcol" },
                        [
                          _vm.pendingActions.length
                            ? _c(
                                "div",
                                { staticClass: "_bgc--primary-dark _py-2" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "gfu-balloon__top-title _tc--white _px-2 _pb-2",
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.Resources.Layout
                                              .ActionRequiredSectionTitle
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "_flexcol _gap-1" },
                                    _vm._l(
                                      _vm.pendingActions,
                                      function (action) {
                                        return _c(
                                          "notification-action",
                                          _vm._b(
                                            {
                                              key: action.userPendingActionID,
                                              on: {
                                                "action-dismissed":
                                                  _vm.onActionDismissed,
                                              },
                                            },
                                            "notification-action",
                                            action,
                                            false
                                          )
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "_flex _jc-sb _pa-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "gfu-balloon__top-title" },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.Resources.Layout
                                          .ActivitySectionTitle
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.isAuthenticated
                                ? _c(
                                    "base-link",
                                    {
                                      staticClass: "_tc--accent _tdn",
                                      attrs: {
                                        url: _vm.subscriptionManagementUrl,
                                        "data-qa":
                                          "notification-box-link:CommunicationPreferences",
                                      },
                                      on: { click: _vm.onLinkClick },
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.Resources.Layout
                                              .CommunicationPreferencesLink
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.isPushNotificationSettingsVisible
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "_bgc--light _rounded--common _mx-2 _mb-2",
                                },
                                [
                                  _c(_vm.component, {
                                    tag: "component",
                                    attrs: {
                                      "active-change": "",
                                      notifications: _vm.notifications,
                                    },
                                    on: {
                                      "hide-push-notification-settings":
                                        _vm.onHidePushNotificationSettings,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.hasPushNotificationCapability &&
                          !_vm.isAuthenticated
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "_bgc--light _rounded--common _mx-2 _mb-2",
                                },
                                [_c("push-notification-unauthorized-user")],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isAuthenticated
                            ? _c("push-notification-list", {
                                attrs: {
                                  "active-change": "",
                                  notifications: _vm.notifications,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }