var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "gfu-table__row gfu-tree__leaf gfu-table__row--single gfu-table__row--nested gfu-table__row--outline",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "gfu-table__cell gfu-table__cell-row gfu-table__cell-product-option _pa-0",
          class: [
            {
              "gfu-table__cell-product-option--non-editable": !_vm.allowEditing,
            },
          ],
        },
        [
          _c(
            "div",
            {
              staticClass: "_screen--none _screen--s",
              attrs: { href: _vm.item.product.productUrl },
            },
            [
              _c("div", { staticClass: "gfu-table__thumb _fl" }, [
                _c("img", {
                  attrs: {
                    src: _vm.item.product.imageUrl,
                    alt: _vm.item.displayName,
                  },
                }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "gfu-table__cell gfu-table__cell--grow _pt-1 _pb-1",
            },
            [
              _c(
                "span",
                {
                  staticClass: "gfu-table__text--small _screen--not-s",
                  attrs: { "data-qa": "cart-table:ProductQuantity" },
                },
                [_vm._v(_vm._s(_vm.item.quantity) + "x ")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "gfu-table__text--strong gfu-table__text--small",
                  attrs: {
                    to: _vm.itemDetailsRoute,
                    "data-qa": "cart-table:ProductName",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onItemDetailsClick.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.item.displayName) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.canEditOption
                ? _c(
                    "div",
                    {
                      staticClass:
                        "gfu-1of1 gfu-11of12--s gfu-2of3--m gfu-flex__container",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "gfu-table__cell gfu-table__cell--grow",
                        },
                        _vm._l(
                          _vm.item.product.options.filter(
                            (o) => o.values.length > 0
                          ),
                          function (option) {
                            return _c(
                              "div",
                              {
                                key: `set-item-option-${option.productOptionID}`,
                              },
                              [
                                _c("product-option-field", {
                                  ref: "option-sub-" + _vm.item.orderItemID,
                                  refInFor: true,
                                  attrs: {
                                    "label-class": "gfu-field__label--light",
                                    "field-class":
                                      "gfu-field-select--tiny-text gfu-field-select--squeezed",
                                    option: option,
                                    small: true,
                                    "selected-option-value-id":
                                      _vm.getProductOptionValueID(
                                        _vm.item.options,
                                        option.productOptionID
                                      ),
                                    "order-item-id": _vm.item.orderItemID,
                                  },
                                  on: { change: _vm.setOrderItemOption },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "gfu-table__cell gfu-table__option-action gfu-table__option-action--large",
                        },
                        [
                          _vm.canSplitItem
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "gfu-btn gfu-btn--circle gfu-btn--light-flat gfu-btn--icon",
                                  attrs: { disabled: _vm.isProcessing },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.splitSetItem()
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass:
                                      "_ga _ga--split-icon gfu-btn__icon",
                                    attrs: {
                                      title: "Split item",
                                      "data-qa":
                                        "begin-order-form-button:SplitItem",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.canRemoveItem
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "gfu-btn gfu-btn--circle gfu-btn--light-flat gfu-btn--icon",
                                  attrs: { disabled: _vm.isProcessing },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.removeSetItem()
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass: "_fa _fa--times gfu-btn__icon",
                                    attrs: {
                                      title: "Undo split",
                                      "data-qa":
                                        "begin-order-form-button:UndoSplit",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  )
                : _vm.item.product.options.length > 0
                ? _c(
                    "div",
                    { attrs: { "data-qa": "cart-table:ProductOptionValue" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.getReadonlyProductOptionValuesList(_vm.item)
                          ) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "gfu-table__cell gfu-table__cell--actions _tac _flexb15 _flexb10--s _screen--none _screen--s _flexcntr",
          attrs: { "data-qa": "cart-table:ProductQuantity" },
        },
        [
          _vm._v("\n        " + _vm._s(_vm.item.quantity) + "\n        "),
          _vm.isQuantityWrong
            ? _c(
                "tooltip",
                {
                  staticClass:
                    "gfu-table__amount-error-wrapper gfu-table__amount-error-wrapper--inline",
                  attrs: {
                    text: _vm.quantityErrorText,
                    "data-qa": "quantity-error-warning-icon-tooltip",
                  },
                },
                [
                  _c("span", {
                    staticClass: "gfu-table__amount-error",
                    attrs: {
                      "data-qa":
                        "cart-table-item-row-quantity-error:WarningIcon",
                    },
                  }),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "gfu-table__cell _tar _flexb35 _flexb25--s _flexb15--m _screen--none _screen--s",
          attrs: { "data-text": "Total price" },
        },
        [_vm._v("-")]
      ),
      _vm._v(" "),
      _vm.allowEditing
        ? _c("div", {
            staticClass:
              "gfu-table__cell _tar _pa-0 _flexb5 _screen--none _screen--s",
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }