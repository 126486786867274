var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("footer-navigation", {
        attrs: { "current-page": _vm.Resources.Layout.AboutUsLink },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-info-hero _mb-6" }, [
        _c(
          "video",
          {
            staticClass: "gfu-info-hero-video gfu-info-hero-video--m",
            attrs: { autoplay: "", loop: "", muted: "" },
            domProps: { muted: true },
          },
          [
            _c("source", {
              attrs: { src: "/content/movies/static/about-us/about-us-l.mp4" },
            }),
            _vm._v(" "),
            _c("source", {
              attrs: { src: "/content/movies/static/about-us/about-us-l.webm" },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "video",
          {
            staticClass: "gfu-info-hero-video gfu-info-hero-video--s",
            attrs: { autoplay: "", loop: "", muted: "" },
            domProps: { muted: true },
          },
          [
            _c("source", {
              attrs: { src: "/content/movies/static/about-us/about-us-s.mp4" },
            }),
            _vm._v(" "),
            _c("source", {
              attrs: { src: "/content/movies/static/about-us/about-us-s.webm" },
            }),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "gfu-info-hero__cover" }),
        _vm._v(" "),
        _c("div", { staticClass: "gfu-info-hero__content" }, [
          _c(
            "h1",
            {
              staticClass:
                "gfu-hd gfu-hd--d2 gfu-hd--decorative _tc--accent-inverse",
            },
            [_vm._v(_vm._s(_vm.Resources.Common.MainTagline))]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "_mt-4",
            domProps: {
              innerHTML: _vm._s(_vm.Resources.AboutUs.VideoSectionParagraph),
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-about-us" },
        [
          _c(
            "div",
            { staticClass: "gfu-layout__main gfu-layout__main--narrow" },
            [
              _c("div", { staticClass: "gfu-box gfu-box--huge" }, [
                _c("div", { staticClass: "_mt-7 _mb-6" }, [
                  _c(
                    "h2",
                    {
                      ref: "history",
                      staticClass: "gfu-hd gfu-hd--h1",
                      attrs: { id: "history" },
                    },
                    [_vm._v(_vm._s(_vm.Resources.AboutUs.HistorySectionHeader))]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "gfu-info-media _mb-6" }, [
                  _c("div", { staticClass: "gfu-info-media__body _tc--gray" }, [
                    _c(
                      "h3",
                      {
                        ref: "the-beginning",
                        staticClass:
                          "gfu-hd gfu-hd--h3 _mb-3 gfu-heading--top-divider",
                        attrs: { id: "the-beginning" },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.Resources.AboutUs.BeginningSectionHeader)
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.Resources.AboutUs.BeginningSectionParagraph
                        ),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _vm._m(0),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "gfu-info-media gfu-info-media--reverse _mb-6",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "gfu-info-media__body _tc--gray" },
                      [
                        _c(
                          "h3",
                          {
                            ref: "the-evolution",
                            staticClass:
                              "gfu-hd gfu-hd--h3 _mb-3 gfu-heading--top-divider",
                            attrs: { id: "the-evolution" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.Resources.AboutUs.EvolutionSectionHeader
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.Resources.AboutUs.EvolutionSectionParagraph
                            ),
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(1),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "gfu-info-media _mb-6" }, [
                  _c("div", { staticClass: "gfu-info-media__body _tc--gray" }, [
                    _c(
                      "h3",
                      {
                        ref: "today",
                        staticClass:
                          "gfu-hd gfu-hd--h3 _mb-3 gfu-heading--top-divider",
                        attrs: { id: "today" },
                      },
                      [_vm._v(_vm._s(_vm.Resources.AboutUs.TodaySectionHeader))]
                    ),
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.Resources.AboutUs.TodaySectionParagraph) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(2),
                ]),
                _vm._v(" "),
                _c(
                  "h2",
                  {
                    ref: "team",
                    staticClass: "gfu-hd gfu-hd--h1 _mt-7 _mb-6",
                    attrs: { id: "team" },
                  },
                  [_vm._v(_vm._s(_vm.Resources.AboutUs.TeamSectionHeader))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "_mb-8" },
                  [
                    _c("team-members-slider", {
                      attrs: { members: _vm.teamMembers },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "_tac" }, [
                  _vm._m(3),
                  _vm._v(" "),
                  _c("h4", { staticClass: "gfu-hd gfu-hd--h2 _mb-4" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.Resources.AboutUs.TeamSectionAdditionalParagraph
                        ) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "gfu-btn gfu-btn--soft gfu-btn--primary gfu-btn--wide",
                      attrs: {
                        href: "mailto:apply@gamefound.com?subject=Application",
                        target: "_blank",
                        rel: "noopener",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.Resources.AboutUs.TeamSectionButtonText)
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "_mt-7 _mb-6" }, [
                  _c(
                    "h2",
                    {
                      ref: "safety-and-security",
                      staticClass: "gfu-hd gfu-hd--d1",
                      attrs: { id: "safety-and-security" },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.Resources.AboutUs.SafetyAndSecuritySectionHeader
                        )
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "gfu-info-media _mb-6" }, [
                  _c("div", { staticClass: "gfu-info-media__body _tc--gray" }, [
                    _c(
                      "h3",
                      {
                        ref: "human-touch",
                        staticClass:
                          "gfu-hd gfu-hd--h3 _mb-3 gfu-heading--top-divider",
                        attrs: { id: "human-touch" },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.Resources.AboutUs.HumanTouchSectionHeader)
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.Resources.AboutUs.HumanTouchSectionParagraph
                        ) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(4),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "gfu-info-media gfu-info-media--reverse _mb-6",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "gfu-info-media__body _tc--gray" },
                      [
                        _c(
                          "h3",
                          {
                            ref: "reliability",
                            staticClass:
                              "gfu-hd gfu-hd--h3 _mb-3 gfu-heading--top-divider",
                            attrs: { id: "reliability" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.Resources.AboutUs.ReliabilitySectionHeader
                              )
                            ),
                          ]
                        ),
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.Resources.AboutUs.ReliabilitySectionParagraph
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(5),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "_mt-7 _mb-6" }, [
                  _c(
                    "h2",
                    {
                      ref: "community",
                      staticClass: "gfu-hd gfu-hd--h1",
                      attrs: { id: "community" },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.Resources.AboutUs.CommunitySectionHeader)
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "gfu-by gfu-bt--b1" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.Resources.AboutUs.CommunitySectionParagraph) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "_mt-7 _mb-6" }, [
                  _c(
                    "h2",
                    {
                      ref: "gamefound-in-numbers",
                      staticClass: "gfu-hd gfu-hd--h1",
                      attrs: { id: "gamefound-in-numbers" },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.Resources.AboutUs.GamefoundInNumbersSectionHeader
                        )
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "gfu-info-stats" },
                  [
                    _c(
                      "become-a-creator-stats",
                      _vm._b(
                        {},
                        "become-a-creator-stats",
                        _vm.creatorStatistics,
                        false
                      )
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "h2",
                  {
                    ref: "partners",
                    staticClass: "gfu-hd gfu-hd--h1 _mt-7 _mb-3",
                    attrs: { id: "partners" },
                  },
                  [_vm._v(_vm._s(_vm.Resources.AboutUs.PartnersSectionHeader))]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "_mb-3" }, [
                  _vm._v(
                    _vm._s(_vm.Resources.AboutUs.PartnersSectionSubheader)
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "_mb-6" },
                  [
                    _c("partners-slider", {
                      attrs: { partners: _vm.partners },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "_tac _mb-8" },
                  [
                    _c(
                      "base-link",
                      {
                        staticClass:
                          "gfu-btn gfu-btn--soft gfu-btn--primary gfu-btn--wide",
                        attrs: { url: _vm.links.becomeACreatorUrl },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.Resources.AboutUs.PartnersSectionButtonText
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "gfu-info-media _mb-3" }, [
                  _c("div", { staticClass: "gfu-info-media__body _tc--gray" }, [
                    _c(
                      "h2",
                      {
                        ref: "legal",
                        staticClass: "gfu-hd gfu-hd--h1 _mb-3",
                        attrs: { id: "legal" },
                      },
                      [_vm._v(_vm._s(_vm.Resources.AboutUs.LegalSectionHeader))]
                    ),
                    _vm._v(" "),
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.Resources.AboutUs.LegalSectionParagraph
                        ),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _vm._m(6),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "gfu-bt gfu-bt--caption _tc--light _flexcol _gap-1",
                  },
                  [
                    _c(
                      "localize",
                      {
                        attrs: {
                          resource: _vm.Resources.AboutUs.LegalSectionCaption,
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "gfu-link gfu-link--accent",
                            attrs: {
                              "format-key": "0",
                              href: "https://www.adyen.com/",
                              target: "_blank",
                              rel: "nofollow noopener",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.Resources.AboutUs.LegalSectionLink)
                            ),
                          ]
                        ),
                        _vm._v(".\n                    "),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "localize",
                      {
                        attrs: {
                          resource:
                            _vm.Resources.AboutUs.LegalSectionDocsParagraph,
                        },
                      },
                      [
                        _c(
                          "base-link",
                          {
                            staticClass: "gfu-link gfu-link--accent",
                            attrs: {
                              "format-key": "0",
                              url: _vm.termsOfServiceUrl,
                              target: "_blank",
                              rel: "nofollow noopener",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.Resources.AboutUs
                                  .LegalSectionTermsOfServiceLink
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "base-link",
                          {
                            staticClass: "gfu-link gfu-link--accent",
                            attrs: {
                              "format-key": "1",
                              url: _vm.privacyPolicyUrl,
                              target: "_blank",
                              rel: "nofollow noopener",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.Resources.AboutUs
                                  .LegalSectionPrivacyPolicyLink
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "_mt-7 _mb-3" }, [
                  _c(
                    "h2",
                    {
                      ref: "reach-out",
                      staticClass: "gfu-hd gfu-hd--h1",
                      attrs: { id: "reach-out" },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.Resources.AboutUs.ReachOutSectionHeader)
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "gfu-card-list gfu-card-list--wrap" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "gfu-card-list__item gfu-1of1 gfu-1of2--l _mt-3",
                      },
                      [
                        _c("div", { staticClass: "gfu-info-contact" }, [
                          _vm._m(7),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "gfu-info-contact__content" },
                            [
                              _c("p", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Resources.AboutUs
                                      .ContactForBackerParagraph
                                  ),
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "_mt-2" },
                            [
                              _c(
                                "base-link",
                                {
                                  staticClass: "gfu-link gfu-link--accent",
                                  attrs: {
                                    url: _vm.links.contactForBackersUrl,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.Resources.AboutUs.ContactForBackerLink
                                    ) + " "
                                  ),
                                  _c("span", {
                                    staticClass: "_fa _fa--angle-right",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "gfu-card-list__item gfu-1of1 gfu-1of2--l _mt-3",
                      },
                      [
                        _c("div", { staticClass: "gfu-info-contact" }, [
                          _vm._m(8),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "gfu-info-contact__content" },
                            [
                              _c("p", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Resources.AboutUs
                                      .ContactForCreatorParagraph
                                  ),
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "_mt-2" },
                            [
                              _c(
                                "base-link",
                                {
                                  staticClass: "gfu-link gfu-link--accent",
                                  attrs: {
                                    href: _vm.links.contactForCreatorsUrl,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.Resources.AboutUs
                                        .ContactForCreatorLink
                                    ) + " "
                                  ),
                                  _c("span", {
                                    staticClass: "_fa _fa--angle-right",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "gfu-card-list__item gfu-1of1 gfu-1of2--l _mt-3",
                      },
                      [
                        _c("div", { staticClass: "gfu-info-contact" }, [
                          _vm._m(9),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "gfu-info-contact__content" },
                            [
                              _c("p", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Resources.AboutUs
                                      .ContactForMediaParagraph
                                  ),
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "_mt-2" },
                            [
                              _c(
                                "base-link",
                                {
                                  staticClass: "gfu-link gfu-link--accent",
                                  attrs: { url: _vm.links.contactForMediaUrl },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.Resources.AboutUs.ContactForMediaLink
                                    ) + " "
                                  ),
                                  _c("span", {
                                    staticClass: "_fa _fa--angle-right",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "gfu-card-list__item gfu-1of1 gfu-1of2--l _mt-3",
                      },
                      [
                        _c("div", { staticClass: "gfu-info-contact" }, [
                          _vm._m(10),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "gfu-info-contact__content" },
                            [
                              _c("p", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Resources.AboutUs
                                      .ContactForBusinessParagraph
                                  ),
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "_mt-2" },
                            [
                              _c(
                                "base-link",
                                {
                                  staticClass: "gfu-link gfu-link--accent",
                                  attrs: { url: _vm.links.businessContactUrl },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.Resources.AboutUs
                                        .ContactForBusinessLink
                                    ) + " "
                                  ),
                                  _c("span", {
                                    staticClass: "_fa _fa--angle-right",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "gfu-card-list__item gfu-1of1 gfu-1of2--l _mt-3",
                      },
                      [
                        _c("div", { staticClass: "gfu-info-contact" }, [
                          _vm._m(11),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "gfu-info-contact__content" },
                            [
                              _c("p", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Resources.AboutUs
                                      .ContactForRecruitmentParagraph
                                  ),
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "_mt-2" }, [
                            _c(
                              "a",
                              {
                                staticClass: "gfu-link gfu-link--accent",
                                attrs: {
                                  href: "mailto:apply@gamefound.com?subject=Application",
                                  target: "_blank",
                                  rel: "noopener",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.Resources.AboutUs
                                      .ContactForRecruitmentLink
                                  ) + " "
                                ),
                                _c("span", {
                                  staticClass: "_fa _fa--angle-right",
                                }),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "gfu-card-list__item gfu-1of1 gfu-1of2--l _mt-3",
                      },
                      [
                        _c("div", { staticClass: "gfu-info-contact" }, [
                          _vm._m(12),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "gfu-info-contact__content" },
                            [
                              _c("p", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Resources.AboutUs
                                      .ContactForMarketingParagraph
                                  ),
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "_mt-2" },
                            [
                              _c(
                                "base-link",
                                {
                                  staticClass: "gfu-link gfu-link--accent",
                                  attrs: { url: _vm.links.promotionContactUrl },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.Resources.AboutUs
                                        .ContactForMarketingLink
                                    ) + " "
                                  ),
                                  _c("span", {
                                    staticClass: "_fa _fa--angle-right",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "gfu-bt _mt-2" },
                  [
                    _c(
                      "localize",
                      {
                        attrs: {
                          resource:
                            _vm.Resources.AboutUs.ContactInformationParagraph,
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              "format-key": "0",
                              href: "tel:+48716611438",
                            },
                          },
                          [_vm._v("+48 71 661 14 38")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "gfu-bt _mt-2" },
                  [
                    _c(
                      "localize",
                      {
                        attrs: {
                          resource:
                            _vm.Resources.AboutUs
                              .GeneralProductSafetyRegulationContactParagraph,
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              "format-key": "0",
                              href: "mailto:authorities@gamefound.com",
                            },
                          },
                          [_vm._v("authorities@gamefound.com")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("table-of-contents"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "gfu-info-media__aside" }, [
      _c("img", {
        attrs: {
          src: "/content/images/static/about-us/handles.svg",
          alt: "The beginning",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "gfu-info-media__aside" }, [
      _c("img", {
        attrs: {
          src: "/content/images/static/about-us/evolution.svg",
          alt: "The evolution",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "gfu-info-media__aside _mt-3" }, [
      _c("img", {
        attrs: {
          src: "/content/images/static/about-us/stairs.svg",
          alt: "Today",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("picture", [
      _c("source", {
        attrs: {
          srcset: "/content/images/static/about-us/team-faces-l.svg?v=1",
          media: "(min-width: 992px)",
        },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "gfu-1of1 _mb-4",
        attrs: {
          src: "/content/images/static/about-us/team-faces-s.svg?v=1",
          alt: "Team Gamefound",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "gfu-info-media__aside" }, [
      _c("img", {
        attrs: {
          src: "/content/images/static/about-us/robot.svg",
          alt: "Human touch",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "gfu-info-media__aside" }, [
      _c("img", {
        attrs: {
          src: "/content/images/static/about-us/reliability.svg",
          alt: "Reliability",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "gfu-info-media__aside" }, [
      _c("img", {
        attrs: {
          src: "/content/images/static/about-us/map-of-europe.svg",
          alt: "Legal",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "gfu-info-contact__icon" }, [
      _c("span", { staticClass: "_fa _fa--user" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "gfu-info-contact__icon" }, [
      _c("span", { staticClass: "_fa _fa--user-edit" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "gfu-info-contact__icon" }, [
      _c("span", { staticClass: "_fa _fa--users-crown" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "gfu-info-contact__icon" }, [
      _c("span", { staticClass: "_fa _fa--user-tie" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "gfu-info-contact__icon" }, [
      _c("span", { staticClass: "_fa _fa--user-hard-hat" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "gfu-info-contact__icon" }, [
      _c("span", { staticClass: "_fa _fa--user-headset" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }