import ServiceBase from './ServiceBase';

export default class CheckoutService extends ServiceBase {
    constructor($http) {
        super($http);
    }

    getAmendableOrders(projectID) {
        return this.request('get', 'checkout/getAmendableOrders', { params: { projectID } });
    }

    getCartBasicInfo(projectID, orderCode) {
        return this.request('get', 'checkout/getCartBasicInfo', { params: { projectID, orderCode } });
    }

    saveCartBasicInfo(cartInfo) {
        return this.request('post', 'checkout/saveCartBasicInfo', cartInfo);
    }

    saveCartLocation(locationInfo) {
        return this.request('post', 'checkout/saveCartLocation', locationInfo);
    }

    calculateCartInstallmentPlan(projectID, exchangeRate) {
        return this.request('get', 'checkout/calculateCartInstallmentPlan', { params: { projectID, exchangeRate } });
    }

    calculateOrderInstallmentPlan(projectID, exchangeRate, orderCode) {
        return this.request('get', 'checkout/calculateOrderInstallmentPlan', { params: { projectID, exchangeRate, orderCode } });
    }

    utilizeBonusAmount(projectID, orderCode) {
        return this.request('post', 'checkout/utilizeBonusAmount', { projectID, orderCode });
    }
}