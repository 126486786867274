var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_px-2" },
    [
      _c(
        "base-link",
        { staticClass: "gfu-link--nofx", attrs: { url: _vm.urlAction } },
        [
          _c("push-notification-list-item", {
            attrs: {
              "image-url": _vm.actionImage,
              "data-qa": `pending-action:${_vm.userPendingActionID}`,
            },
            scopedSlots: _vm._u([
              {
                key: "message-title",
                fn: function () {
                  return [_c("span", [_vm._v(_vm._s(_vm.actionText))])]
                },
                proxy: true,
              },
              {
                key: "message-text",
                fn: function () {
                  return [
                    _c(
                      "span",
                      { staticClass: "gfu-bt gfu-bt--caption _tc--lighter" },
                      [
                        _vm._v(
                          _vm._s(_vm.Resources.Layout.InLabel) +
                            " " +
                            _vm._s(_vm.projectName)
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "actions",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "_mt-1 _tar" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "_tc--accent",
                            attrs: { "data-qa": "action-dismiss" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.onDissmissClick.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.Resources.Layout.DismissActionButtonText
                                ) +
                                "\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "base-button",
                          {
                            staticClass: "_ttl",
                            attrs: {
                              theme: "accent",
                              size: "micro",
                              tag: "a",
                              href: _vm.urlAction,
                              "data-qa": `call-to-action:${_vm.actionKey}`,
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.actionLabel) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }