
import { cardBrands } from '@/Utils/AdyenUtil';
import { externalScripts, applePay } from '@/Config';
import { loadScript } from '@/Utils/ScriptUtility';
import Log from '@/Utils/Log';

const { isEnabled } = applePay;
const currentVersion = 3;

// https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest/1916122-supportednetworks
const brandMap = {
    [cardBrands.MasterCard]: 'masterCard',
    [cardBrands.Visa]: 'visa',
    [cardBrands.Amex]: 'amex',
    [cardBrands.Discover]: 'discover',
    [cardBrands.JCB]: 'jcb',
    [cardBrands.Maestro]: 'maestro',
    [cardBrands.Cartebancaire]: 'cartebancaire',
};

const allowedCardBrands = [cardBrands.MasterCard, cardBrands.Visa, cardBrands.Amex, cardBrands.JCB, cardBrands.Discover, cardBrands.Cartebancaire];

export default class ApplePayService {
    adyenConfig = {
        merchantName: null,
    };
    constructor(adyenConfig) {
        this.adyenConfig = adyenConfig;
    }

    async initAsync() {
        if (!isEnabled) {
            return false;
        }

        const result = await this.loadScriptAsync();

        if (result) {
            this.isInitialized = true;
            return true;
        }

        return false;
    }

    async loadScriptAsync() {
        return await loadScript(externalScripts.applePayUrl);
    }

    async isAvailableAsync() {
        if (!isEnabled) {
            return false;
        }

        if (!this.isInitialized) {
            return false;
        }

        if (document.location.protocol !== 'https:') {
            return false;
        }

        return await window.ApplePaySession && ApplePaySession.canMakePayments() && ApplePaySession.supportsVersion(currentVersion);
    }

    invoke(currencyCode, amount, options) {
        const paymentRequest = this.preparePaymentRequest(currencyCode, amount);
        this.session = new ApplePaySession(currentVersion, paymentRequest);
        this.session.onvalidatemerchant = event => this.onValidateMerchantAsync(event, options.onValidateMerchant);
        this.session.onpaymentauthorized = event => this.onPaymentAuthorizedAsync(event, options.onPaymentAuthorized);
        this.session.oncancel = event => this.onPaymentCancel(event, options.onPaymentCancel);

        this.session.begin();
    }

    // We don't use the event because adyen handles that behind the curtains
    async onValidateMerchantAsync(event, onValidateMerchant) {
        try {
            const sessionObject = await onValidateMerchant();
            this.session.completeMerchantValidation(sessionObject);
        }
        catch (error) {
            Log.error(error);
            this.session.abort();
        }
    }

    async onPaymentAuthorizedAsync(event, onPaymentAuthorized) {
        try {
            await onPaymentAuthorized(event.payment);
            this.session.completePayment(ApplePaySession.STATUS_SUCCESS);
        }
        catch (error) {
            Log.error(error);
            this.session.completePayment(ApplePaySession.STATUS_FAILURE);
        }
    }

    onPaymentCancel(event, onPaymentCancel) {
        onPaymentCancel();
    }

    preparePaymentRequest(currencyCode, amount) {
        return {
            countryCode: 'PL',
            currencyCode,
            total: {
                label: this.adyenConfig.merchantName,
                amount,
            },
            supportedNetworks: allowedCardBrands.map(brand => brandMap[brand]),
            merchantCapabilities: ['supports3DS'],
        };
    }
}
