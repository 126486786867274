import Resources from '@/Resources.js';

export const secondsInDay = 86400;
export const secondsInHour = 3600;
export const secondsInMinute = 60;
export const millisecondsInDay = secondsInDay * 1000;
export const millisecondsInDayHour = secondsInHour * 1000;
export const millisecondsInMinute = secondsInMinute * 1000;

export function getConversionResources() {
    return [{
        unit: 'd',
        factor: secondsInDay,
        name: Resources.ProjectHome.HumanizeDatePluralizedDay, // 'day'
    },
    {
        unit: 'h',
        factor: secondsInHour,
        name: Resources.ProjectHome.HumanizeDatePluralizedHour, // 'hour'
    },
    {
        unit: 'm',
        factor: secondsInMinute,
        name: Resources.ProjectHome.HumanizeDatePluralizedMinute, // 'minute'
    },
    {
        unit: 's',
        factor: 1,
        name: Resources.ProjectHome.HumanizeDatePluralizedSecond, // 'second'
    },
    {
        unit: 'ms',
        factor: 0.001,
        name: Resources.ProjectHome.HumanizeDatePluralizedMilisecond, // 'millisecond'
    }];
}
/**
 * Humanizes seconds to text.
 * @example
 * // humanize(60, 's') will create "60 seconds".
 * @example
 * // humanize(500, 'h') will return "0 hours" because the result it always rounded down .
 * @param {number} seconds - the time value in seconds.
 * @param {undefined|string} unit - can be one of following values: [undefined, 'd', 'h', 'm', 's']. If not specified the maximum the the highest supported unit is used.
 * @returns The time value in text format.
 */
export const humanize = (seconds, unit) => {
    let conversion = null;
    if (!unit) {
        const maximumSupportedUnitIndex = getConversionResources()
            .map(c => c.factor)
            .sort((a, b) => b - a)
            .findIndex(c => c <= seconds);
        conversion = getConversionResources()[maximumSupportedUnitIndex];
    }
    else {
        conversion = getConversionResources().find(c => c.unit === unit);
    }

    const value = Math.floor(seconds / conversion.factor);
    const unitName = Resources.pluralize(value, conversion.name);
    return `${ value } ${ unitName }`;
};

// creates a string which contains humanized form with up to two parts.
// for example twoPartHumanize(61) will return "1 minute 1 second"
// if the second part is 0 then it's omitted. This means twoPartHumanize(60) will return "1 minute"
export const twoPartHumanize = (seconds) => {
    if (seconds < 0)
        throw new Error('seconds value must be greater or equal 0');

    const maximumSupportedUnitIndex = getConversionResources()
        .map(c => c.factor)
        .sort((a, b) => b - a)
        .findIndex(c => c <= seconds);

    const firstPartConversion = getConversionResources()[maximumSupportedUnitIndex];
    const humanizedFirstPart = humanize(seconds, firstPartConversion.unit);
    const secondPartConversion = getConversionResources()[maximumSupportedUnitIndex + 1];
    return maximumSupportedUnitIndex < getConversionResources().length - 1 && seconds % firstPartConversion.factor >= secondPartConversion.factor
        ? Resources.format(
            Resources.ProjectHome.HumanizeDateTwoPartsLabel,
            humanizedFirstPart,
            humanize(seconds % firstPartConversion.factor, secondPartConversion.unit))
        : humanizedFirstPart;
};

export const threePartHumanize = (seconds) => {
    if (seconds < 0)
        throw new Error('seconds value must be greater or equal 0');

    const conversionResources = getConversionResources();
    const sortedFactors = conversionResources.map(c => c.factor).sort((a, b) => b - a);
    const firstIndex = sortedFactors.findIndex(f => f <= seconds);

    const firstPart = conversionResources[firstIndex];
    const firstHumanized = humanize(seconds, firstPart.unit);
    let remainingSeconds = seconds % firstPart.factor;

    if (firstIndex >= conversionResources.length - 1 || remainingSeconds === 0) {
        return firstHumanized;
    }

    const secondPart = conversionResources[firstIndex + 1];
    const secondHumanized = humanize(remainingSeconds, secondPart.unit);
    remainingSeconds %= secondPart.factor;

    if (firstIndex + 1 >= conversionResources.length - 1 || remainingSeconds === 0) {
        return Resources.format(Resources.ProjectHome.HumanizeDateTwoPartsLabel, firstHumanized, secondHumanized);
    }

    const thirdPart = conversionResources[firstIndex + 2];
    const thirdHumanized = humanize(remainingSeconds, thirdPart.unit);

    return Resources.format(Resources.ProjectHome.HumanizeDateThreePartsLabel, firstHumanized, secondHumanized, thirdHumanized);
};

export function dateToISOString(date) {
    if (!(date instanceof Date))
        throw new Error('param should be instance of Date');

    const day = `0${ date.getDate() }`.slice(-2);
    const month = `0${ date.getMonth() + 1 }`.slice(-2);
    return `${ date.getFullYear() }-${ month }-${ day }`;
}

export function dateToISODateString(date) {
    if (!(date instanceof Date))
        throw new Error('param should be instance of Date');

    return date.toISOString().split('T')[0];
}

export function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

export function parseValidDate(value) {
    const date = new Date(value);
    return date instanceof Date && !isNaN(date) ? date : null;
}

export function humanizedTimeAgo(startDate, endDate = new Date(Date.now())) {
    const difference = endDate.getTime() - startDate.getTime();
    const seconds = Math.floor(difference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);
    const ago = (timeLabel) => Resources.format(Resources.ProjectHome.HumanizeDateAgoLabel, timeLabel);

    if (years > 0) {
        return `${ years } ${ ago(Resources.pluralize(years, Resources.ProjectHome.HumanizeDatePluralizedYear)) }`;
    }
    else if (months > 0) {
        return `${ months } ${ ago(Resources.pluralize(months, Resources.ProjectHome.HumanizeDatePluralizedMonth)) }`;
    }
    else if (days > 0) {
        return `${ days } ${ ago(Resources.pluralize(days, Resources.ProjectHome.HumanizeDatePluralizedDay)) }`;
    }
    else if (hours > 0) {
        return `${ hours } ${ ago(Resources.pluralize(hours, Resources.ProjectHome.HumanizeDatePluralizedHour)) }`;
    }
    else if (minutes > 0) {
        return `${ minutes } ${ ago(Resources.pluralize(minutes, Resources.ProjectHome.HumanizeDatePluralizedMinute)) }`;
    }
    else {
        return `${ seconds } ${ ago(Resources.pluralize(seconds, Resources.ProjectHome.HumanizeDatePluralizedSecond)) }`;
    }
}

export function monthsDifference(dateFrom, dateTo) {
    const fromMonth = dateFrom.getMonth();
    const toMonth = dateTo.getMonth();
    const fromYear = dateFrom.getFullYear();
    const toYear = dateTo.getFullYear();

    const diff = toMonth - fromMonth + 12 * (toYear - fromYear);

    return diff >= 0 ? diff : -diff;
}

export function truncateDate(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}
export function differenceInCalendarDays(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (end < start) {
        throw new Error('End date must be after start date');
    }

    const startOfDayStart = new Date(start.getFullYear(), start.getMonth(), start.getDate());
    const startOfDayEnd = new Date(end.getFullYear(), end.getMonth(), end.getDate());

    return Math.ceil((startOfDayEnd - startOfDayStart) / (1000 * 3600 * 24) + 1);
}
export default {
    humanize,
    twoPartHumanize,
    isValidDate,
};
