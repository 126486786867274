var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasNotificationToDisplay
    ? _c(
        "div",
        { attrs: { "data-qa": "notifications-box:NotificationList" } },
        [
          _vm.hasUnseenNotification
            ? _c(
                "div",
                { staticClass: "_pa-2" },
                [
                  _c("span", { staticClass: "_tc--lighter _ttu" }, [
                    _vm._v(_vm._s(_vm.Resources.Layout.UnseenLabel)),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.unseenNotification, function (notification) {
                    return _c(
                      "push-notification-list-item",
                      _vm._b(
                        {
                          key: notification.pushNotificationID,
                          staticClass: "_mt-1",
                          attrs: {
                            "data-qa": `unseen-notification:${notification.userNotificationID}`,
                          },
                        },
                        "push-notification-list-item",
                        notification,
                        false
                      )
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasSeenNotification
            ? _c(
                "div",
                { staticClass: "_bgc--light _pa-2" },
                [
                  _c("span", { staticClass: "_tc--lighter _ttu" }, [
                    _vm._v(_vm._s(_vm.Resources.Layout.SeenLabel)),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.seenNotification, function (notification) {
                    return _c(
                      "push-notification-list-item",
                      _vm._b(
                        {
                          key: notification.pushNotificationID,
                          staticClass: "_mt-1",
                          attrs: {
                            "data-qa": `already-seen-notification:${notification.userNotificationID}`,
                          },
                        },
                        "push-notification-list-item",
                        notification,
                        false
                      )
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ]
      )
    : _c("push-notification-list-empty-state")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }