var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-badge",
    { attrs: { theme: _vm.theme, "data-qa": "countdown-badge" } },
    [
      _vm._v("\n    " + _vm._s(_vm.Resources.Layout.EndingInLabel) + " "),
      _c("timer-component", {
        attrs: {
          tag: "span",
          timestamp: _vm.campaignEnd,
          "data-qa": "timer-component",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ seconds, minutes }) {
              return [
                _vm._v(
                  "\n        " +
                    _vm._s(minutes) +
                    ":" +
                    _vm._s(seconds) +
                    "\n    "
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }