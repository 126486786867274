
    const resources = {
    "AboutUs": {
        "BeginningSectionHeader": "The beginning",
        "BeginningSectionParagraph": "Gamefound was established in 2016 by Marcin Świerkot, the CEO of Awaken Realms, and Szymon Matyjaszek, the highly talented developer, serving as CTO. Initially, it was developed as a pledge manager for select creators who realized that successful crowdfunding campaigns require specialized tools for fulfillment. Awaken Realms was the first to utilize this new system, and it wasn't long before other creators joined, propelling Gamefound's growth as an independent platform.",
        "CommunitySectionHeader": "Community",
        "CommunitySectionParagraph": "Gamefound is created to accelerate great projects in the gaming world, and our users are tabletop fans. We are part of this community and that's why we understand what creators and backers need. Simply speaking, all we do, we do from our own experience.",
        "ContactForBackerLink": "Contact for backers",
        "ContactForBackerParagraph": "If you're a <strong>backer</strong> and need to contact us or one of the creators.",
        "ContactForBusinessLink": "business contact",
        "ContactForBusinessParagraph": "If you have any <strong>business-related</strong> topic to talk about.",
        "ContactForCreatorLink": "contact for creators",
        "ContactForCreatorParagraph": "If you're a <strong>creator</strong> and want to run your campaign on Gamefound.",
        "ContactForMarketingLink": "contact for promotion",
        "ContactForMarketingParagraph": "If you want to use our <strong>marketing services</strong>.",
        "ContactForMediaLink": "contact for the media",
        "ContactForMediaParagraph": "If you're from the <strong>media</strong> or are an influencer.",
        "ContactForRecruitmentLink": "recruitment contact",
        "ContactForRecruitmentParagraph": "If you want to join our team and <strong>work at Gamefound</strong>.",
        "ContactInformationParagraph": "Call us at {0}. Call cost according to the operator's tariff or price list.",
        "EvolutionSectionHeader": "The evolution",
        "EvolutionSectionParagraph": "Over the years, the platform has continuously evolved, with new features and improvements being added regularly. This evolution accelerated over time. In March 2019, we celebrated reaching 100,000 backers. Exactly one year later, on March 5, 2020, the 100th creator launched a project on our platform. On December 17, 2020, Gamefound hosted its first fully-fledged crowdfunding campaign with ISS Vanguard, marking a significant milestone. This campaign was a tremendous success, setting the stage for hundreds of remarkable projects that followed.",
        "GamefoundInNumbersSectionHeader": "Gamefound in numbers",
        "GeneralProductSafetyRegulationContactParagraph": "Single point of contact for supervisory authorities for the purposes of the General Product Safety Regulation: {0}.",
        "HistorySectionHeader": "History",
        "HumanTouchSectionHeader": "Human touch",
        "HumanTouchSectionParagraph": "At Gamefound, we prioritize your trust and comfort. If our help center doesn't have the answers, or if you wish to discuss or share feedback, we're always ready to assist with prompt responses. For all projects, a dedicated account manager is available for direct contact, ensuring personalized support.",
        "LegalSectionCaption": "To learn more about how the payments are processed on our platform, go {0}.",
        "LegalSectionDocsParagraph": "Here you can find our platform's {0} and {1}.",
        "LegalSectionHeader": "Legal",
        "LegalSectionLink": "here",
        "LegalSectionParagraph": "Gamefound is an EU-based limited liability company incorporated under the laws of Poland. We're a global crowdfunding platform accepting creators from tens of countries and pledges from nearly all over the world.<br/><br/> Gamefound's name and logo are legally protected as registered trademarks, and any use of them in any way is prohibited without our explicit consent.<br/><br/> Gamefound operates in full compliance with EU regulations. We cooperate with Adyen as the payment provider for Gamefound, fully compliant with the most recent PCI DSS standard, which is the key security standard within the industry, ensuring smooth and secure processing of the payments made within the platform.",
        "LegalSectionPrivacyPolicyLink": "Privacy Policy",
        "LegalSectionTermsOfServiceLink": "Terms of Service",
        "PartnersSectionButtonText": "Become a creator",
        "PartnersSectionHeader": "Partners",
        "PartnersSectionSubheader": "They trusted us:",
        "ReachOutSectionHeader": "Reach out",
        "ReliabilitySectionHeader": "Reliability",
        "ReliabilitySectionParagraph": "We know how important it is to provide you with a platform you can trust and run your project on with peace of mind. As a creator, you want everything to work smoothly when your long-awaited campaign goes live. And it does, thanks to the proper technology behind Gamefound and a great team of specialists responsible for its efficiency and reliability.",
        "SafetyAndSecuritySectionHeader": "Safety and security",
        "StatisticBackersCountHeader": "Backer|Backers",
        "StatisticCreatorsCountHeader": "Creator|Creators",
        "StatisticProjectsCountHeader": "Project|Projects",
        "StatisticValueOfPledgesCounterHeader": "Value of pledges",
        "StatisticValueOfPledgesTooltipText": "The total value in EUR has been calculated using monthly average exchange rates.",
        "TeamSectionAdditionalParagraph": "Our team consists of over 50 talented specialists, with half dedicated to advancing our technology.",
        "TeamSectionButtonText": "Join us!",
        "TeamSectionHeader": "Team",
        "TodaySectionHeader": "Today",
        "TodaySectionParagraph": "We have surpassed the milestone of one million backers and have hosted projects from hundreds of creators. Gamefound experiences exponential growth each year, attracting new creators and backers. Today, Gamefound serves as a comprehensive platform where creators can manage their campaigns from the initial announcement and acquisition of the first follower, to fulfilling the final order, and even managing long-tail sales of remaining stock. The major shareholders of Gamefound are Marcin Świerkot, CEO, and Ravensburger, which joined as a strategic investor in February 2022.",
        "VideoSectionParagraph": "Gamefound is all about making game creation faster and simpler. We're a platform that brings together supporters and game developers, not just for board games, but for all kinds of gaming projects. Here, creators can concentrate on what they do best, while backers can easily find and support the games they love. We offer a range of services, including crowdfunding, pledge management for any campaign, and marketing support, all in one hassle-free place."
    },
    "Account": {
        "LoginToGamefoundBenefitsListHeader": "Log in to Gamefound to:",
        "GamefoundAccountBenefitsListItem1": "Follow and back projects you like",
        "GamefoundAccountBenefitsListItem2": "Easily pay for your pledges",
        "GamefoundAccountBenefitsListItem3": "Participate in the life of a project",
        "GamefoundAccountBenefitsListItem4": "Create your own project",
        "ExternalLoginProviderChoiceButton": "Sign in with {0}",
        "GamefoundLoginFormChoiceHeader": "or log in with your Gamefound account.",
        "LogInHeader": "Welcome back!",
        "LogInButton": "Log in",
        "EmailFieldLabel": "Email",
        "TwoFactorAuthenticationEmailSecurityCodeLeadParagraph": "We’ve emailed you a security code to ensure it’s really you. Please enter it below to log in:",
        "TwoFactorAuthenticationEmailSecurityCodeResendParagraphPart1": "Haven’t received your code? No worries, you can {0}.",
        "TwoFactorAuthenticationEmailSecurityCodeResendParagraphPart2": "resend the code",
        "TwoFactorLoginStepCancelParagraphPart1": "Want to start over? {0}.",
        "TwoFactorLoginStepCancelParagraphPart2": "Go back to login",
        "SecurityCodeFieldLabel": "Security code",
        "TwoFactorAuthenticationEmailSecurityCodeResentNoitificationMessage": "The security code has been resent.",
        "PasswordFieldLabel": "Password",
        "SignUpLink": "Don't have an account yet? Sign up.",
        "ForgotPasswordLink": "Forgot your password?",
        "SignUpCallToActionIntroParagraph": "Don't have an account?",
        "SignUpCallToActionButton": "Sign up",
        "InvalidPasswordError": "Invalid email or password.",
        "InvalidEmailSecurityCode": "This is not the right code. Make sure you use the latest code sent to your email.",
        "WaitUntilResendSecurityCode": "Please wait at least 1 minute before resending the code.",
        "SignUpToGamefoundBenefitsListHeader": "Set up an account on Gamefound to:",
        "GamefoundSignUpFormChoiceHeader": "or create a Gamefound account in 2 easy steps.",
        "LogInCallToActionIntroParagraph": "Already have an account?",
        "LogInLink": "Already have an account? Log in.",
        "SignUpConsequenceIntroParagraph": "By completing this form, you agree to our {0}.",
        "SignUpConsequencePrivacyPolicyLink": "Privacy Policy",
        "ContinueActionButton": "Continue",
        "SignUpHeader": "Sign up",
        "CompleteAccountPageTitle": "Create account",
        "LogInPageTitle": "Log in",
        "SignUpPageTitle": "Sign up",
        "CompleteAccountEmailFieldLabel": "Your email address",
        "CompleteAccountFullNameFieldLabel": "Your full name",
        "CompleteAccountHeader": "Hi!",
        "CompleteAccountIntroParagraph": "Just a few more things before we start.",
        "CompleteAccountPasswordFieldLabel": "Your new account password",
        "CompleteAccountPasswordValidationMessage": "Your password must be combination of at least six letters, numbers and punctuation marks (such as ! and &).",
        "CompleteAccountPrivacyPolicyLink": "Privacy Policy",
        "CompleteAccountTermsConsentLabel": "I accept {0} and {1}.",
        "CompleteAccountTermsOfServiceFieldName": "terms of service",
        "CompleteAccountTosLink": "Gamefound Terms of Service",
        "ExternalSignInFacebookPermissionDeniedErrorMessage": "To log in with Facebook, you need to allow sharing your email address.",
        "ExternalSignInGooglePermissionDeniedErrorMessage": "To log in with Google, you need to allow sharing your email address.",
        "AccountCreatedActivationLinkInstructionParagraph": "<strong>Please check your email and follow the instructions found in our message.</strong>",
        "AccountCreatedActivationLinkSentParagraph": "We have sent you a message with a personal activation link.",
        "AccountCreatedHeader": "Your account is almost ready!",
        "ContinueBrowsingActionButton": "Continue browsing",
        "BackToHomePageButton": "Go back home",
        "InvalidInvitationLinkHeader": "Invalid invitation link",
        "InvalidInvitationLinkParagraph": "This invitation link does not work anymore. It is either invalid or has already been used.",
        "AuthenticatePageHeader": "Log in or sign up",
        "LogInOption": "I am a returning user. I would like to log in.",
        "SignUpOption": "I am a new user. I want to create an account.",
        "ReCaptchaLoginVerificationFailedErrorMessage": "We've noticed some unusual activity. You can't log in.",
        "ReCaptchaInvalidTokenErrorMessage": "Invalid reCaptcha token",
        "ReCaptchaMissingTokenErrorMessage": "Missing reCaptcha token",
        "LoginModalCommentText": "In order to comment you need to log in.",
        "LoginModalFollowText": "In order to follow you need to log in.",
        "LogInModalTitle": "Please log in",
        "LoginModalSuccessToastDescriptionMessage": "You can adjust notifications in your communication preferences.",
        "LoginModalSuccessToastTitle": "You're now following {0}.",
        "ReCaptchaSignupVerificationFailedErrorMessage": "We've noticed some unusual activity. You can't sign up.",
        "CompleteAccountNewsletterConsentLabel": "Subscribe to the Gamefound newsletters {0}",
        "CompleteAccountNewsletterTooltipText": "Get updates about interesting campaigns on Gamefound. We send these messages about once a month.",
        "CompleteAccountNicknameFieldLabel": "Nickname",
        "CompleteAccountEmailAlreadyInUse": "There already is an active user with this email.",
        "CompleteAccountInvalidActivationKey": "Invalid activation key",
        "CreateCreatorAccountHeader": "Become a creator",
        "CreateCreatorAccountLoginOptionParagraph": "I already have a Gamefound account.",
        "CreateCreatorAccountSingUpOptionParagraph": "I don't have a Gamefound account yet.",
        "CreateCreatorAccountStepOneParagraph1": "Your creator profile will be linked to the following Gamefound account: {0}",
        "CreateCreatorAccountStepOneParagraph2": "In order to become a Gamefound creator, first you need to have a Gamefound account.",
        "CreateCreatorAccountStepOneText": "1. Log in / Sign up",
        "CreateCreatorAccountStepTwoParagraph": "Select how your creator profile will be displayed to Gamefound users.",
        "CreateCreatorAccountStepTwoText": "2. Pick your creator profile name",
        "CreateCreatorAccountSubheader": "Complete the steps below to become a Gamefound creator and get your first project going.",
        "SignUpButton": "Sign up"
    },
    "AccountSettings": {
        "AccountDetailsSectionTitle": "Account details",
        "AccountSettingsPageTitle": "Account Settings",
        "ApiEmailMustBeNewError": "New email should be actually new.",
        "ApiEmailNotAvailableError": "New email is already taken.",
        "ApiEmailSendingFailedError": "Error while sending email notification.",
        "ApiIncorrectPasswordError": "Password is incorrect.",
        "ApiInvalidSocialURLError": "Invalid {0} URL.",
        "ApiInvalidUserIDError": "Invalid user ID.",
        "ApiNewPasswordMustBeNewError": "New password should be actually new.",
        "ApiNewPasswordRequiredError": "New password cannot be empty.",
        "ApiNicknameNotAvailableError": "Nickname is not available.",
        "ApiOldPasswordIncorrectError": "Old password is incorrect.",
        "ApiOldPasswordRequiredError": "Old password cannot be empty.",
        "ApiPasswordRequiredError": "Password cannot be empty.",
        "ApiUnauthorizedError": "You are not authorized to perform this action.",
        "AvatarChangedSuccessToast": "Avatar has been changed.",
        "AvatarFieldLabel": "User Avatar",
        "AvatarManagerModalAvatarGeneratorTabName": "Avatar generator",
        "AvatarManagerModalCustomImageTabName": "Custom image",
        "AvatarManagerModalHeader": "Manage avatar",
        "AvatarManagerModalRandomizeAvatarLabel": "randomize avatar",
        "AvatarUploadedSuccessToast": "Your avatar has been uploaded.",
        "BioFieldDescription": "Write a few words about yourself. This description will be visible to all users viewing your profile. Description can't be longer than 255 characters.",
        "BioFieldLabel": "Bio",
        "ChangeEmailCurrentEmailFieldLabel": "Your current email address",
        "ChangeEmailFailedEmailTakenText": "Could not complete password reset request. The email is already taken.",
        "ChangeEmailFailedInvalidLinkText": "Could not complete email change request. The link is either invalid or expired.",
        "ChangeEmailFailedSystemErrorText": "Could not activate account due to system error. Please try again.",
        "ChangeEmailFailedTitle": "Email change",
        "ChangeEmailGoogleFbWarningText": "If you've used your Google or Facebook accounts to create or log into this account, changing the email will make it impossible to log in using Google or Facebook accounts you used before.",
        "ChangeEmailInfoText": "Complete this form to update your email address. We will send you a message with a confirmation link that completes the process.",
        "ChangeEmailNewEmailFieldLabel": "New email address",
        "ChangeEmailPasswordFieldLabel": "Password",
        "ChangeEmailPasswordInfoText": "For security reasons we need to ask you for your current password.",
        "ChangeEmailPendingChangeInfoText": "We have sent you an email with instructions to confirm your new email ({0})",
        "ChangeEmailRevertButton": "Revert",
        "ChangeEmailSuccessAccountSettingsLink": "My account settings",
        "ChangeEmailSuccessHomeLink": "Go back home",
        "ChangeEmailSuccessText": "You can use your new email to log in from now on.",
        "ChangeEmailSuccessTitle": "Your email has been changed.",
        "ChangeEmailTitle": "Change email address",
        "ChangePasswordCharacterRequirementError": "Your password must be combination of at least six letters, numbers and punctuation marks (such as ! and &).",
        "ChangePasswordConfirmNewPasswordFieldLabel": "Repeat new password",
        "ChangePasswordCurrentPasswordFieldLabel": "Current password",
        "ChangePasswordCurrentPasswordText": "For security reasons we need to ask you for your current password.",
        "ChangePasswordInfoText": "Complete this form to update your password.",
        "ChangePasswordNewPasswordFieldLabel": "New password",
        "ChangePasswordPasswordsDontMatchError": "Passwords don't match.",
        "ChangePasswordTitle": "Change password",
        "ChangesSavedSuccessToast": "Your changes have been saved.",
        "DeleteAccountConfirmationInfo": "This will erase all your account data. If you have any outstanding orders, we will keep processing them, but you won't be able to manage them through your account any more.",
        "DeleteAccountConfirmationTitle": "Delete account",
        "DeleteAccountConfirmButton": "Delete account",
        "DeleteAccountLink": "here",
        "DeleteAccountPasswordFieldLabel": "Your account password",
        "DeleteAccountPasswordInfo": "You need to re-enter your account password to continue.",
        "DeleteAccountSectionText": "If you wish to delete your Gamefound account, please follow the procedure",
        "DeleteAccountSectionTitle": "Delete account",
        "DeleteCreditCardButton": "remove",
        "DeleteCreditCardConfirmationText": "You're about to delete the saved card details. If you delete it, you'll have to enter card details next time you make a payment. Are you sure you want to remove it?",
        "DeleteCreditCardConfirmationTitle": "Remove Card",
        "DeleteCreditCardSuccessToast": "Sucessfully removed the card.",
        "EmailChangedSuccessToast": "We have sent you an email with instructions to confirm your new email address.",
        "EmailFieldLabel": "Email",
        "EmailFieldPlaceholder": "example@example.com",
        "FacebookFieldDescription": "The address of your Facebook account.",
        "FacebookFieldLabel": "Facebook",
        "FacebookFieldPlaceholder": "https://www.facebook.com/profile",
        "FullNameFieldDescription": "This is how we will address you.",
        "FullNameFieldLabel": "Full name",
        "FullNameFieldPlaceholder": "Firstname Lastname",
        "InstagramFieldDescription": "The address of your Instagram account.",
        "InstagramFieldLabel": "Instagram",
        "InstagramFieldPlaceholder": "https://www.instagram.com/profile",
        "IsPublicFieldLabel": "This profile is public",
        "IsPublicFieldOffLabel": "This profile is private.",
        "IsPublicFieldOnLabel": "This profile is public.",
        "NicknameFieldDescription": "This is how others will see you on Gamefound (in comments, reviews etc.).",
        "NicknameFieldLabel": "Nickname",
        "NicknameFieldPlaceholder": "Nickname",
        "NoSavedCreditCardsInfoText": "You haven't saved any card data yet.",
        "PasswordChangedSuccessToast": "Your password has been changed.",
        "PasswordFieldLabel": "Password",
        "ProfileCustomizationSectionTitle": "Profile customization",
        "SavedCreditCardInfoText": "Your card information is safely and securely stored by Adyen. Gamefound doesn't store your payment information apart from the type and last four digits of your card number. Saving your card allows to quickly finalize payments on the platform. You won't have to provide card details every time you pay.",
        "SavedCreditCardsSectionTitle": "Saved Card",
        "TwitterFieldDescription": "The address of your Twitter account.",
        "TwitterFieldLabel": "Twitter",
        "TwitterFieldPlaceholder": "https://www.twitter.com/profile",
        "ValidationInvalidSocialLink": "This link is not valid.",
        "YoutubeFieldDescription": "The address of your Youtube account.",
        "YoutubeFieldLabel": "Youtube",
        "YoutubeFieldPlaceholder": "https://www.youtube.com/@channel"
    },
    "Blog": {
        "BackToMainPageLink": "Back to the main page",
        "EmptyStateListParagraph": "There are no available posts at the moment.",
        "PageHeader": "Blog"
    },
    "CategoryType": {
        "AddonsDisplayName": "Add-ons",
        "RewardsDisplayName": "Rewards",
        "UnspecifiedDisplayName": "Unspecified"
    },
    "Checkout": {
        "BackToCartButton": "Go back to your pledge",
        "BillingAddressTitle": "Billing address",
        "BillingCountryMissingErrorLabel": "Billing country is missing.",
        "CardPaymentMethodName": "Card payment",
        "CheckboxDigitalDeliveryAgreementMessage": "I expressly agree to perform the agreement and to send me the digital codes I ordered, via email, immediately upon conclusion of the purchase process. I understand that, as the result of the above consent, I will lose my right of withdrawal from the agreement within 14 days since its conclusion.",
        "CheckboxSaveCardMessage": "Save this card for future payments.",
        "CheckboxTermsAndConditionsAgreementMessage": "I agree to the {0} and {1}.",
        "CheckboxTermsAndConditionsAgreementName": "Terms of Service and {0}",
        "CheckboxEUVatPayerConfirmationMessage": "I'm registered EU VAT payer",
        "CheckboxEUVatPayerConfirmationTooltipText": "Validated EU VAT payers are billed in net prices. You need to have a valid EU VAT number for cross-border transactions to use this option.",
        "CheckboxUKVatPayerConfirmationMessage": "I'm registered UK VAT payer",
        "CheckboxUKVatPayerConfirmationTooltipText": "Validated UK VAT payers are billed in net prices. You need to have a valid UK VAT number for cross-border transactions to use this option.",
        "CheckoutAvailableBanksLabel": "Available banks",
        "CheckoutCannotCreatePaymentErrorMessage": "Unexpected error when trying to create payment.",
        "CheckoutCreditCardCardholderEmailLabel": "Cardholder's email",
        "CheckoutCreditCardCardholderNameLabel": "Cardholder's name",
        "CheckoutCreditCardExpiryDateLabel": "Expiry date",
        "CheckoutCreditCardNumberLabel": "Card number",
        "CheckoutCreditCardSecurityLabel": "CVV/CVC",
        "CheckoutCrowdfundingShippingInfoParagraph": "Shipping details and fees aren't collected at this stage. The project creator will ask you to settle the shipping cost at a later date.",
        "CheckoutDccMoreInfoParagraph": "Original price of {0} was converted to {1}. ({2})",
        "CheckoutDigitalProductsTermsSectionTitle": "Digital Products terms acceptance",
        "CheckoutFooterCompanyInformationParagraph": "The Website is run by Gamefound sp. z o.o. (Trading name: Gamefound) with its registered office at ul. św. Mikołaja 58 50-127 Wrocław, registered in the Register of Entrepreneurs of the National Court Register maintained by the District Court for Wrocław-Fabryczna, VIth Commercial Division, under KRS number 0000778227.",
        "CheckoutFooterDccSectionParagraph": "Gamefound may convert your payment to your local currency. Conversion is optional, you are free to complete the payment in the project currency. If you decide to pay in one of the supported currencies instead of the project currency, Gamefound will convert the payment using the European Central Bank rate + {0}. In case your payment is subsequently refunded by the creator, the refund value will be calculated using the same exchange rate as applied to the original payment.",
        "CheckoutFooterDccSectionReadMoreLink": "Read more",
        "CheckoutFooterDccSectionTitle": "Currency conversion",
        "CheckoutFooterGamefoundLegalSectionTitle": "Gamefound is not a store",
        "CheckoutFooterProcessingPaymentsSectionParagraph": "Payments are fully and securely processed by {0}. Gamefound doesn't store your payment information apart from the schema and last four digits of your card number.",
        "CheckoutFooterProcessingPaymentsSectionTitle": "Processing payments",
        "CheckoutFooterV1ProjectDisclaimer": "We don't own projects on Gamefound. Delivering rewards, settling disputes and issuing refunds is in the hands of project creators.",
        "CheckoutFooterV2ProjectDisclaimer": "We don't own projects on Gamefound. Delivering rewards, settling disputes and issuing refunds is in the hands of project creator:",
        "CheckoutFooterV2ProjectIndividualLegalEntity": "Creator is a natural person - your consumer rights may not apply.",
        "CheckoutGamefoundSuggestionCurrencyLabel": "Converted by gamefound",
        "CheckoutGoBackLink": "go back",
        "CheckoutHeader": "Pledge summary",
        "CheckoutHeaderProjectByCreatorTitle": "{0} by {1}",
        "CheckoutInstallmentsAvailabilityParagraph": "Pledge {0} more to unlock",
        "CheckoutInstallmentsAvailabilityTooltip": "Minimum payment amount to unlock installments:",
        "CheckoutKlarnaPaymentMethodLabel": "Pay now with klarna.",
        "CheckoutLatePledgeShippingInfoParagraph": "You'll be asked to add shipping details and settle additional fees once the pledge manager is open.",
        "CheckoutLocationDetailsSectionTitle": "Shipping and tax details",
        "CheckoutPayButton": "Pay",
        "CheckoutPaymentCancelledByUserMessage": "Payment cancelled by user",
        "CheckoutPaymentMethodSectionTitle": "Payment method",
        "CheckoutPaymentOptionsInfoNotificatorMessage": "At this stage we only verify your card. Your actual payment will be collected if the campaign reaches its goal when it ends. Until then you can freely change or withdraw your pledge.",
        "CheckoutPaymentOptionsSectionTitle": "Payment options",
        "CheckoutPaymentOptionsWarningNotificatorMessage": "There was an error when processing your payment. Please complete the payment manually if you want to receive your pledge.",
        "CheckoutPaymentTypeInstallmentsBadge": "{0} installments",
        "CheckoutPaymentTypeInstallmentsDisabledLabel": "Pay using Stretch Pay",
        "CheckoutPaymentTypeInstallmentsLabel": "Pay {0} monthly",
        "CheckoutInstallmentsDetailsInfoLabel": "You're about to pay the first of {0} installments, with the total of {1}.",
        "CheckoutPaymentTypeMoneyLabel": "Pay in full",
        "CheckoutPledgeButton": "Pledge",
        "CheckoutPledgeIdentificationLabel": "Pledge ID:",
        "CheckoutPledgeSummaryStepLabel": "Pledge summary & payment",
        "CheckoutProductNotAvailableInSelectedLocationLink": "edit your pledge",
        "CheckoutProductNotAvailableInSelectedLocationMessage": "Some products can't be shipped to this location. To complete your pledge, please remove them from your cart. {0}",
        "CheckoutProductNotAvailableInSelectedLocationParagraph": "You cannot place an order with items that cannot be shipped to this location. Please remove them from your cart to proceed with the order",
        "CheckoutProjectCurrencyLabel": "Project currency",
        "CheckoutShippingStepLabel": "Shipping",
        "CheckoutSubHeader": "Verify your pledge content and proceed to payment.",
        "CheckoutSubmitOrderButton": "Submit order",
        "CheckoutTransactionCurrencyHeader": "Transaction currency",
        "CheckoutUnsavedChangedWarningMessage": "You have unsaved changes in this pledge. If you continue, the information you entered will be lost. Are you sure you want to leave this page?",
        "CPFNumberLabel": "CPF number",
        "CPFNumberRequired": "CPF number is required",
        "CPFNumberTooltipLabel": "The valid number format is XXX.XXX.XXX-XX",
        "CreditCardPaymentMethodName": "Credit Card",
        "DifferentBillingAddressLabel": "My billing address is different",
        "EnterBlikCodeDescription": "Enter the 6-digit code and confirm the transaction in your banking app.",
        "EnterBlikCodeLabel": "Enter Blik code",
        "EspagoPaymentMethodName": "Credit card",
        "EstimatedStartOfDeliveryLabel": "estimated start of delivery:",
        "ExternalTransferMethodOption": "External transfer",
        "InvalidPaymentOptionErrorMessage": "Please select a payment method.",
        "InvalidShippingCostConfigurationErrorLabel": "There seems to be a problem with shipping cost configuration in this project. Please contact the project owner if the problem persists.",
        "IssuerDropdownPlaceholder": "Select your bank",
        "LegacyCheckoutPaymentMethodSectionSubtitle": "You will be asked for your card details.",
        "LegacyCheckoutPaymentMethodSectionTitle": "Select payment method",
        "LocalizedAddressLabel": "Localized address",
        "LocalizedAddressRequired": "Localized address is required",
        "LocalizedAddressTooltipLabel": "To ensure proper shipping handling in non-Latin alphabet areas, please enter your complete address with any unique local characters.",
        "ManageYourPledgeButton": "Manage your pledge",
        "NewOrdersNotAllowedErrorMessage": "This project does not accept new orders at this time.",
        "NIDNumberLabel": "NID number",
        "NIDNumberRequired": "NID number is required",
        "NIDNumberTooltipLabel": "The correct number format is 10 digits",
        "OnlineBankingFIPaymentMethodLabel": "Paytrail (Online Banking FI)",
        "OnlineBankingPLPaymentMethodLabel": "Przelewy24 (Online Banking PL)",
        "OrderContainsLocationRestrictedItemsErrorMessage": "Order contains items not available in current location.",
        "OrderInformationHeader": "Order information",
        "OrderNotFoundErrorMessage": "Could not find specified order.",
        "OtherPaymentMethodsLabel": "Other payment methods",
        "PaymentCollectionTypeDeferredLabel": "Deferred",
        "PaymentCollectionTypeInstantLabel": "Instant",
        "PaymentMethodDeferredDisclaimerParagraph": "At this stage we only verify your card. The payment will be collected only after the campaign ends successfully. Until then you can freely change or withdraw your pledge.",
        "PaymentMethodInstantDisclaimerParagraph1": "If you choose this payment method, you will be charged instantly. No funds will be paid out to the creator until the campaign ends successfully. If the campaign doesn't end successfully, you'll get a full refund.",
        "PaymentMethodInstantDisclaimerParagraph2": "You'll be able to upgrade your pledge freely.",
        "PaymentMethodInstantDisclaimerParagraph3": "Once paid, all pledge upgrades must be settled using the same payment method.",
        "PaymentMethodInstantDisclaimerParagraph4": "If you change your mind, you can ask the creator to cancel your pledge and receive a full refund up until the end of the crowdfunding campaign.",
        "PaymentMethodInstantDisclaimerParagraph5": "After the campaign ends successfully, standard campaign cancelation policy applies.",
        "PayPalPaymentMethodName": "Credit card / PayPal™",
        "PreviouslyUsedPaymentMethodLabel": "Previously used",
        "ProductsOutOfStockErrorMessage": "Cannot invoke payment. One or more products are out of stock.",
        "RecentPaymentMethodsLabel": "Recent payment methods",
        "RedirectToPaymentProviderTooltipText": "Redirecting to payment provider...",
        "RefundAndCancellationPolicyLink": "Refund and Cancellation Policy",
        "RegisteredVatPayerCheckboxLabel": "I am a registered EU VAT payer",
        "RemoveItemButton": "remove item",
        "ResumePaymentNotificatorMessage": "Shipping details and fees aren't collected at this stage. The project creator will ask you to settle the shipping cost at a later date.",
        "ResumePaymentPageSubHeader": "Complete the payment process to receive your pledge.",
        "RRNNumberLabel": "RRN number",
        "RRNNumberRequired": "RRN number is required",
        "RRNNumberTooltipLabel": "The correct number format is 13 digits",
        "SavedPaymentMethodLabel": "Saved",
        "ShippingCostAdditionalValueLabel": "additional",
        "ShippingCostLabel": "cost:",
        "ShippingCostReducedValueLabel": "reduced by",
        "ShippingCostWithoutTaxLabel": "net cost:",
        "ShippingFormAddressTitle": "Address",
        "ShippingFormNotificatorParagraph": "Changing shipping address is not allowed for this project.",
        "ShippingFormShippingAddressTitle": "Shipping address",
        "ShippingLocationModificationDisabledErrorMessage": "Cannot modify shipping country.",
        "ShippingMethodSectionTitle": "Shipping method",
        "ShippingModelChangedSuccessToast": "Shipping cost was added to your pledge.",
        "CheckoutOrderSummaryStepLabel": "Order summary & payment",
        "ManageYourOrderButton": "Manage your order",
        "StoreShippingPageSubheader": "Please check the order content and fill in your shipping and billing information.",
        "CheckoutTermsOfServiceLabel": "Terms of Service",
        "StoreCheckboxTermsAndConditionsAgreementMessage": "I agree to the store's {0} and {1}.",
        "StoreCheckoutHeader": "Order summary",
        "StoreCheckoutSubHeader": "Verify your order content and proceed to payment.",
        "ShippingModelsNotAvailableErrorMessage": "No shipping models available. Please contact site admins.",
        "ShippingPageHeader": "Shipping",
        "ShippingPageSubheader": "Please check the pledge content and fill in your shipping and billing information.",
        "ShippingPageTitle": "Pledge information",
        "ShippingSubLocationModificationDisabledErrorMessage": "Cannot modify shipping state/province.",
        "StripePaymentMethodName": "Credit card / Stripe™",
        "TermsAndConditionsForBackersLink": "Terms of Service for Backers",
        "UnauthorizedAmendOrderErrorMessage": "You must be logged in to amend a previous order.",
        "UnauthorizedShippingModificationErrorMessage": "You must be logged in to change shipping.",
        "UnfinishedPledgeErrorMessage": "You must finish your current pledge.",
        "UserAddressItemLabel": "Address",
        "UserCityLabel": "City",
        "UserCountryLabel": "Country",
        "UserFirstAddressLabel": "Address line 1",
        "UserFullNameLabel": "Full name",
        "UserNotesLabel": "Additional notes",
        "UserPhoneNumberLabel": "Phone number",
        "UserPostalCodeLabel": "Postal code",
        "UserSecondAddressLabel": "Address line 2",
        "UserShippingCountryDisabledParagraph": "If your shipping country changes before shipment, you have to contact the creator to modify it.",
        "UserShippingCountryLabel": "Shipping country",
        "UserShippingCountryParagraph": "Choose the country where the pledge will be sent so we can apply the proper tax rate. If your shipping country changes by shipment time, you have to contact the creator to modify it. Shipping cost will be defined later by the creator.",
        "UserShippingCountryPlaceholder": "Type or select country name",
        "UserStateOrProvinceLabel": "State/province",
        "UserSubLocationLabel": "Sublocation",
        "UserSubLocationPlaceholder": "Type a state/province name",
        "UserThirdAddressLabel": "Address line 3",
        "UserVatNumberLabel": "Tax identification number",
        "CancelInstallmentPlanErrorMessage": "Cannot cancel started installment plan.",
        "StoreCheckoutProductNotAvailableInSelectedLocationLink": "edit your order",
        "StoreCheckoutProductNotAvailableInSelectedLocationMessage": "Some products can't be shipped to this location. To complete your order, please remove them from your cart. {0}",
        "StoreCheckoutTermsOfServiceLabel": "Gamefound Terms of Service for Stores",
        "OrderItemNotFound": "Order item not found.",
        "UtilizeBonusAmountCancelButton": "Keep bonus in pledge",
        "UtilizeBonusAmountModalDescription": "Your pledge includes an unused bonus amount. You can use this amount to lower your payment or keep it in your pledge to further support the creator. Your choice will modify your cart total.",
        "UtilizeBonusAmountModalTitle": "Manage your bonus amount",
        "UtilizeBonusAmountSubmitButton": "Use bonus now",
        "UtilizeBonusAmountSuccessToastMessage": "Your bonus has been applied to your payment.",
        "CheckoutPaymentTypeUpgradeInstallmentsLabel": "Upgrade your stretch pay plan to {0} monthly",
        "UpgradeInstallmentsRemainingValueLabel": "Remaining amount of previous installment plan",
        "UpgradeInstallmentsTotalValueLabel": "Total installment plan after upgrade",
        "UpgradeInstallmentsUpgradeValueLabel": "Upgrade amount",
        "PaymentMethodDeferredPayPalDisclaimerParagraph": "At this stage, your PayPal account is only authorized. The payment will be collected only after the campaign ends successfully. Until then, you can freely change or withdraw your pledge.",
        "UnsuportedPaymentMethodMessage": "Sorry, your order is using {0} payment method, which is not available in the app. Use your browser to continue",
        "ReCaptchaCheckoutVerificationFailedErrorMessage": "We've noticed some unusual activity. You can't check out.",
        "PaymentProcessingBoxActionLabel": "Go to project page",
        "PaymentProcessingBoxDescription": "We're processing your payment now. For selected payment method, it may take up to {0} {1}. You may wait here for result or continue browsing. We will email you with the operation result.",
        "HumanizeDatePluralizedMinute": "minute|minutes",
        "PaymentProcessingBoxTitle": "Payment in progress",
        "PaymentErrorBoxDescription": "There was an issue processing your payment. Please check your payment method's validity and available funds and try again.",
        "PaymentErrorBoxTitle": "Payment error",
        "PaymentErrorGoToCheckoutLabel": "Go to checkout",
        "PaymentErrorViewYourPledgeLabel": "View your pledge",
        "UserUnableToDeleteOrderItemErrorMessage": "Order item can't be deleted.",
        "PledgeInstallmentsPaymentDateEndgameAdditionalInfo": "The first installment is scheduled for the crowdfunding end date, which may vary depending on the duration of the project's 'endgame' phase.",
        "PaymentNotFoundErrorMessage": "Payment was not found."
    },
    "CheckoutValidation": {
        "AddOnsOnlyPledgeErrorMessage": "You can't check out with add-ons only. Add a reward to your pledge to continue.",
        "AddProductPreviewValidationErrorMessage": "You can't add this product to your pledge because this is a preview of {0}.",
        "AmendOrderNotPossibleErrorMessage": "Amending this order is not possible at this time.",
        "BillingCountryNotFoundErrorMessage": "Billing country not found.",
        "BillingStateMissingErrorMessage": "Billing state is missing.",
        "BillingStateNotValidErrorMessage": "Billing state is not valid.",
        "ChangeShippingNotPossibleErrorMessage": "Shipping cannot be changed at this time.",
        "ChangingPaymentMethodUnavailableErrorMessage": "You can't change the payment method at this time.",
        "CheckShippingConfigurationInfoMessage": "Make sure the shipping configuration is valid.",
        "CouldNotAmendOrderErrorMessage": "Could not amend order.",
        "CouldNotChangeShippingErrorMessage": "Could not change shipping.",
        "CouldNotInvokePaymentErrorMessage": "Could not invoke payment.",
        "CreateOrderNotPossibleErrorMessage": "Could not create order.",
        "CurrencyExchangeRateChangedInfoMessage": "Currency exchange rate has changed. Refresh the page to update the payment amount.",
        "DisabledOrderCurrencyMessage": "You can't modify this pledge because its currency has been disabled.",
        "EditingPledgeNotFinishedErrorMessage": "You must finish editing your current pledge.",
        "EmptyCartErrorMessage": "You can't go through checkout because your pledge is empty.",
        "EmptyPledgeButtonTooltipText": "You can't continue with an empty pledge.",
        "EmptyPledgeErrorMessage": "Pledge is empty.",
        "IncorrectCheckoutModeErrorMessage": "Incorrect checkout mode.",
        "IncorrectPledgeStatusErrorMessage": "Incorrect pledge status.",
        "IncorrectProjectPhaseErrorMessage": "Incorrect project phase.",
        "IncorrectProjectVersionErrorMessage": "Incorrect project version.",
        "InstallmentPlanNotAvailableErrorMessage": "Installment plan not available for this order.",
        "InsufficientCreditAmountActionErrorMessage": "You don't have enough credits to finish this action.",
        "InsufficientCreditAmountErrorMessage": "You don't have enough credits to complete this order.",
        "InsufficientOrderValueMessage1": "This includes {0:C2} maximum payment amount and {1:C2} of your available credits.",
        "InsufficientOrderValueMessage2": "This includes {0:C2} maximum payment amount and {1:C2} of your available discount.",
        "InsufficientOrderValueMessage3": "This includes {0:C2} minimum payment amount and {1:C2} of your available credits.",
        "InsufficientOrderValueMessage4": "This includes {0:C2} minimum payment amount and {1:C2} of your available discount.",
        "InvalidArchiveProductErrorMessage": "You can't add this product to your pledge because this is an archive view of the original crowdfunding campaign.",
        "InvalidAuthorizationParamsErrorMessage": "Invalid authorization params.",
        "InvalidNumberOfInstallmentsCheckoutMessage": "Invalid number of installments. Currently the order payment can be split into {0} installments.",
        "InvalidNumberOfInstallmentsMessage": "Invalid number of installments.",
        "InvalidPaymentMethodErrorMessage": "Invalid payment method.",
        "InvalidPaymentTypeForPaypalMethodErrorMessage": "PayPal is not supported for orders with StretchPay.",
        "InvalidPledgeValueMessage": "You can't submit this pledge because its net value is lower than you've already paid.",
        "InvalidProjectContextErrorMessage": "Invalid project context.",
        "InvalidProjectPhaseErrorMessage": "Invalid project phase.",
        "InvalidShippingConfigurationErrorMessage": "Invalid shipping configuration.",
        "InvalidEUVatNumberErrorMessage": "You have provided an invalid VAT number.",
        "InvalidUKVatNumberErrorMessage": "You have provided an invalid VAT number format. UK VAT numbers should be GB followed by either 9 or 12 digits.",
        "InvalidZipCodeFormatErrorMessage": "Zip code has to be provided in ##### or #####-#### form.",
        "MaxCostReachedErrorMessage": "You cannot pledge more than {0:C2}.",
        "MinCostReachedErrorMessage": "You cannot pledge less than {0:C2}.",
        "NewOrdersUnavailableErrorMessage": "Sorry, the project does not accept new orders at this time.",
        "NoScheduledPaymentsInfoMessage": "Pledge does not have any scheduled payments.",
        "OrderModifiedSuccessfullyMessage": "You have successfully modified your order.",
        "OrderNotAwaitingPaymentStatusErrorMessage": "This order is not awaiting payment.",
        "OrderNotFoundErrorMessage": "Order not found.",
        "OrderReachedMaxAmountErrorMessage": "Your pledge exceeds the maximum amount of {0:C2}.",
        "OrderSuccessfullyCreatedMessage": "Order success",
        "OrderUnderpaymentErrorMessage": "We received your payment for the pledge but its content and price have changed. Please pay the price difference to receive your pledge.",
        "PaymentIncompleteErrorMessage": "Your payment is incomplete.",
        "PaymentInInstallmentsIsNotAvailableMessage": "Payment in installments is no longer available.",
        "PaymentInInstallmentsUnavailableErrorMessage": "Conditions for payment in installments not met.",
        "PaymentInitializationErrorMessage": "Could not initiate payment. Please try again. Contact us if the problem persists.",
        "PaymentMethodNotSupportedForInstallmentPaymentMessage": "Chosen payment method is not supported for installment payments.",
        "PaymentMethodUpdatedInfoMessage": "Payment method updated.",
        "PaymentNotAuthorizedErrorMessage": "Could not authorize payment.",
        "PaymentNotCompletedErrorMessage": "Your payment has not been completed. Please try again.",
        "PaymentNotRequiredMessage": "This order does not require payment at this time.",
        "PaymentOrderOverpaidErrorMessage": "You can't submit this pledge because its net value is lower than you've already paid.",
        "PaymentProblemErrorMessage": "There was a problem with your payment ({0}). Please try again or contact us if the problem persists.",
        "PaymentProblemTryAgainErrorMessage": "There was a problem with your payment. Please try again or contact us if the problem persists.",
        "PaymentsUnavailableErrorMessage": "This project no longer accepts payments.",
        "PlaceOrderInsufficientCreditAmountErrorMessage": "You don't have enough credits to place this order.",
        "PledgeNotFinishedErrorMessage": "You must finish your current pledge.",
        "PledgeNotFoundErrorMessage": "Pledge not found.",
        "ProjectPhaseNotValidErrorMessage": "The current project phase is not valid.",
        "RemoveUnpaidItemsErrorMessage": "You can't add any new items to your pledge. Remove the unpaid items from your cart or revert changes to proceed.",
        "RequireDigitalDeliveryAgreementErrorMessage": "You must accept the digital delivery agreement before proceeding with order.",
        "RequiredOptionsNotChosen": "Not all required options in the pledge were chosen.",
        "RequireTosAcceptanceErrorMessage": "You must accept the \"Terms of Service and Refund and Cancellation Policy\" before proceeding with pledge.",
        "SaveOrderNotPossibleErrorMessage": "Could not save order.",
        "ShippingAddressChangeUnavailableErrorMessage": "Cannot modify shipping address.",
        "ShippingConfigurationNotValidErrorMessage": "Shipping configuration is not valid.",
        "ShippingCostCalculationFailedMessage": "Error while calculating shipping costs.",
        "ShippingCountryMissingErrorMessage": "Shipping country is missing.",
        "ShippingCountryNotFoundErrorMessage": "Shipping country not found.",
        "ShippingDetailsNotValidErrorMessage": "Shipping and billing {0} have to be the same.",
        "ShippingMethodUnavailableMessage": "Shipping method cannot be used.",
        "ShippingStateInvalidErrorMessage": "Shipping state is not valid.",
        "ShippingStateMissingErrorMessage": "Shipping state is missing.",
        "SingleAdditionOnlyValidationErrorMessage": "This item can't be added to your pledge separately.",
        "TooManyCopiesErrorMessage": "Too many copies of an item were added to the pledge.",
        "UnauthorizedOrderAccessErrorMessage": "You don't have access to this order.",
        "UnchangedPledgeInfoMessage": "Your pledge is unchanged.",
        "UnhandledErrorMessage": "Please contact us if the problem persists.",
        "AddUnpublishedProductValidationErrorMessage": "You can't add this product to your pledge because this is an unpublished product.",
        "ProductUnavailable": "One or more products are unavailable",
        "InvalidProductSetErrorMessage": "One or more items from the set require an action.",
        "DeferredPaymentIsNotAvailableMessage": "Deferred payment is no longer available.",
        "PaymentAmountMismatchErrorMessage": "Your payment amount has changed during checkout. Refresh the screen to view the updated pledge summary before proceeding with payment.",
        "ParentOrderNotFoundErrorMessage": "Parent order not found.",
        "OrderNotProcessingPaymentStatusErrorMessage": "This order is not processing payment.",
        "TipMaximumAmount": "Maximum amount is {0}.",
        "TipMinimumAmount": "Minimum amount is {0}.",
        "InvalidCartActivationKey": "Activation key is invalid.",
        "MissingCartActivationKey": "Activation key is missing.",
        "UserUnableToEditPledgeErrorMessage": "Sorry, you cannot modify your pledge at this time.",
        "UserUnableToRevertPledgeChangesErrorMessage": "Can't revert changes on the current cart because it doesn't have a parent order.",
        "CartValueLowerThanPaidMessage": "You can't submit this pledge because its value is lower than you've already paid.",
        "OrderProcessingPaymentMessage": "Your payment is being processed.",
        "OrderNotFinishedErrorMessage": "You must finish your current order.",
        "OrderPaymentStatusCannotBeProcessingPaymentErrorMessage": "The order status must not be processing payment."
    },
    "Comments": {
        "BackerBadge": "Backer",
        "BackerBadgeTooltip": "This person backed this project.",
        "CancelEditCommentAction": "Cancel Edit",
        "CommentDeleteConfirmationMessage": "Are you sure you want to delete this comment?",
        "CommentFieldCapabilitiesHeader": "You can use:",
        "CommentFieldCapabilitiesOption1": "# to add a tag",
        "CommentFieldCommentSubmitButton": "comment",
        "CommentFieldEditingHeader": "Editing a comment",
        "CommentFieldEditSubmitButton": "edit",
        "CommentFieldPlaceholder": "Write your message here...",
        "CommentFieldReplySubmitButton": "reply",
        "CommentReportedAndHiddenMessageText": "Comment hidden.",
        "CommentReportReasonHarassmentDisplayName": "Harassment or offensive language",
        "CommentReportReasonHateSpeechDisplayName": "Hate speech",
        "CommentReportReasonMisinformationDisplayName": "Misinformation",
        "CommentReportReasonOtherDisplayName": "Other reason",
        "CommentReportReasonSpamDisplayName": "Unwanted commercial content or spam",
        "CommunityGuidelinesLink": "community guidelines",
        "CreatorBackerBadge": "Prior backer",
        "CreatorBackerBadgeTooltip": "This person backed this creator in the past.",
        "CreatorBadge": "Creator",
        "DeleteCommentAction": "Delete",
        "EditCommentAction": "Edit",
        "EmptyStateHeader": "There are no comments yet.",
        "EmptyStateParagraph": "Be the first to leave one!",
        "GamefoundStaffBadge": "Gamefound",
        "GoToUserDetailsCommentAction": "Go to user details (admin)",
        "HideCommentAction": "Hide",
        "LoadMoreButton": "Load more",
        "LoadMoreChildCommentsButton": "load more comments ({0})",
        "LoadNewMessagesButton": "Load {0} {1}",
        "LogInCallToActionButton": "Log In",
        "LogInToCommentHeader": "Log in to comment",
        "LogInToCommentParagraph": "You must be logged in to comment. Don't have an account? {0}.",
        "LogInToReplyTooltip": "In order to reply you need to be logged in.",
        "MostPopularSortTypeButton": "most popular",
        "MostRecentSortTypeButton": "most recent",
        "NewMessagesLabel": "new comment|new comments",
        "PageHeader": "Comments",
        "PinCommentAction": "Pin",
        "PinningUnpinningMessageText": "Pinning or unpinning a comment may take up to 30 sec.",
        "PledgeMasterBadge": "Pledge master",
        "PledgeMasterBadgeTooltip": "This person helped a lot of projects come to life.",
        "ProjectRecommendationsTitle": "Other projects you may like",
        "ReplyCommentAction": "Reply",
        "ReportAndHideCommentModalInformation": "This action will immediately hide the comment. Each hidden comment will be verified if they violate our community standards by the Gamefound moderation team. Unjustified use may result in losing the ability to hide comments.",
        "ReportAndHideCommentModalParagraph": "To hide a comment you need to provide a reason to help us understand the context of your decision. The reason will be visible only to the Gamefound team.",
        "ReportAndHideCommentModalTitle": "Hide a comment",
        "ReportCommentAction": "Report",
        "ReportCommentModalParagraph": "In order to report a comment you need to provide a reason.",
        "ReportCommentModalReasonDescriptionFieldLabel": "Reason description",
        "ReportCommentModalReasonFieldLabel": "Reason",
        "ReportCommentModalTitle": "Report a comment",
        "ReportSubmittedMessageText": "Comment report has been submitted.",
        "SearchTagButton": "tags and filters",
        "SearchTagsFieldPlaceholder": "search for tags",
        "SetNicknameModalNicknameFieldLabel": "Nickname",
        "SetNicknameModalParagraph": "In order to comment you need to choose a nickname. You can always change it in your account settings.",
        "SetNicknameModalTitle": "Set nickname",
        "SignUpLink": "Sign up",
        "TagSearchGoToTagResult": "Go to #{0}",
        "TagSearchNoMatchesResult": "No matches",
        "TagsEmptyStateHeader": "There are no popular tags to show yet.",
        "TagsSectionHeader": "Tags and filters",
        "UnhideCommentAction": "Unhide",
        "UnpinCommentAction": "Unpin",
        "UserBannedDescription": "We've restricted your ability to comment due to a violation of our {0}.",
        "UserBannedHeader": "You are blocked from leaving comments",
        "CommentsPageNavbarLink": "Comments",
        "ProjectCommentsDisabledCreatorMessage": "Users cannot see the comments page of your project because it's currently disabled. Please contact us at business@gamefound.com to enable comments.",
        "ProjectCommentsDisabledUserMessage": "The creator hasn't enabled the comment section yet.",
        "CommentItemStatusEditedLabel": "edited",
        "CommentPreviewItemInLabel": "in:",
        "LogInToVoteTooltip": "In order to vote you need to be logged in.",
        "CreatorCommentsFilterButton": "creator comments",
        "MyCommentsFilterButton": "my comments",
        "PathfinderBadgeTooltip": "This person helped to make Gamefound a better place by giving us valuable feedback.",
        "CommentsInTestModeInfoParagraph": "Comments are not in test mode and will be publicly visible.",
        "AuthorNotFoundMessageText": "Cannot find author for comment."
    },
    "Common": {
        "PageMetaDescription": "Gamefound is where amazing gaming projects come to life. Made by gamers for gamers to easily manage crowdfunding and pledge managers.",
        "UnauthorizedInsufficientPermissionsMessage": "You don't have permission to complete this action.",
        "SomethingWentWrongContactMessage": "Something went wrong. Contact us if this problem persists.",
        "MissingOrInvalidParamMessage": "Missing or invalid '{0}' param in request.",
        "UnauthorizedMessage": "You are not authorized to perform this action.",
        "FileTooLargeMaxMBSizeMessage": "File is too large. Maximum allowed file size is {0}MB.",
        "FileTypeNotSupportedMessage": "Files of type '{0}' are not supported.",
        "NoImageSuppliedMessage": "No image supplied.",
        "InvalidSocialMediaUrlMessage": "Invalid {0} URL.",
        "DangerousStringDetectedMessage": "Dangerous string detected.",
        "SomethingWentWrongMessage": "Something went wrong.",
        "InvalidImagePresetMessage": "Invalid image preset.",
        "ValidationFieldRequired": "This field is required.",
        "ValidationMinLength": "The {0} field must be at least {1} characters.",
        "SearchResultsPluralizedLabel": "result|results",
        "SearchFieldPlaceholder": "type to search",
        "CheckboxRequiredValidationMessage": "You need to accept {0}.",
        "BackerBadgeText": "Backer",
        "FollowerBadgeText": "Follower",
        "ModalNoButton": "No",
        "ModalOkButton": "Ok",
        "ModalYesButton": "Yes",
        "ModalSaveButton": "Save",
        "ServerErrorToastSubtitle": "Server error",
        "ValidationAlphaDash": "The {0} field may contain alpha-numeric characters as well as dashes and underscores.",
        "ValidationEmail": "The {0} field must be a valid email.",
        "ValidationMaxLength": "The field length may not be greater than {0} characters.",
        "ModalBackButton": "back",
        "ModalCloseButton": "close",
        "ModalContinueButton": "Continue",
        "MoreInfoHeader": "More info",
        "ValidationMaxValue": "The {0} field must be {1} or less.",
        "ValidationMinValue": "The {0} field must be {1} or more.",
        "ModalCancelButton": "cancel",
        "ModalConfirmButton": "Confirm",
        "SortTypeAlphabeticalAscLabel": "A-Z",
        "SortTypeAlphabeticalDescLabel": "Z-A",
        "SortTypeDefaultLabel": "Default",
        "SortTypeMostFundedLabel": "Most funded",
        "SortTypeMostPopularLabel": "Most popular",
        "SortTypeNewestLabel": "Newest",
        "SortTypeOldestLabel": "Oldest",
        "PleaseSelectPlaceholder": "Please select...",
        "AddressCityLabel": "City:",
        "AddressCountryLabel": "Country:",
        "AddressFullNameLabel": "Full name:",
        "AddressLinesLabel": "Address:",
        "AddressPhoneLabel": "Phone number:",
        "AddressPostalCodeLabel": "Postal code:",
        "AddressSublocationLabel": "State/province:",
        "AddressTaxNumberLabel": "Tax identification number:",
        "AddressUserNotesLabel": "Additional notes:",
        "PostedOnPublishDateLabel": "Posted on {0}",
        "ReadMoreActionButton": "Read more",
        "BreadcrumbsBlogLink": "Blog",
        "BreadcrumbsHomeLink": "Home",
        "BackToMainPageLink": "Back to the main page",
        "FormSubmitButton": "Submit",
        "GoBackButton": "go back",
        "LoadMoreButton": "Load more",
        "LastUpdatedAtLabel": "last update:",
        "EmptyStateSearchParagraph": "No matching results found.",
        "ClickHereButton": "click here",
        "PlayVideoButton": "Play",
        "UploadImagesButton": "Upload images",
        "IncompleteFieldErrorMessage": "Incomplete field",
        "AmountTableHeader": "Amount",
        "DateTableHeader": "Date",
        "NotesTableHeader": "Notes",
        "PaymentMethodTableHeader": "Payment method",
        "StagePaymentStatusTableHeader": "Stage (Payment status)",
        "AutocompleteFieldCreateNewLabel": "create new",
        "AutocompleteFieldMatchingItemsLabel": "Matching items",
        "AutocompleteFieldNoMatchesLabel": "No matches",
        "ValidationOptionRequired": "Please choose one of the options.",
        "ReportIssueActionButton": "report issue",
        "ShowLessLabel": "show less",
        "ShowMoreLabel": "show more",
        "CaptchaDisclaimerCaption": "This site is protected by reCAPTCHA and the Google {0} and {1} apply.",
        "CaptchaPrivacyPolicyLink": "Privacy Policy",
        "CaptchaTermsOfServiceLink": "Terms of Service",
        "InvalidProjectInformationErrorMessage": "Invalid project information",
        "MainTagline": "The Future of Creativity",
        "ItemsCounterLabel": "{0} of {1}",
        "WrongOrMissingLocationErrorMessage": "Wrong or missing location.",
        "WrongOrMissingSubLocationErrorMessage": "Wrong or missing subLocation.",
        "EditActionLabel": "Edit",
        "RemoveActionLabel": "Remove",
        "SortTypeEndingSoonLabel": "Ending soon",
        "HideContentLabel": "Hide",
        "ShowAllContentLabel": "Show all",
        "InvalidImageDimensionsMessage": "Both width and height of the image can't be greater than {0} pixels.",
        "NoVideoSuppliedErrorMessage": "No video supplied.",
        "ActionNotAvailableMessage": "This action is not available at the moment.",
        "SortTypeMostDiscussedLabel": "Most discussed",
        "SortTypeRecentUpdatesLabel": "Recently added updates"
    },
    "Contact": {
        "PageSubtitle": "If you have any questions regarding a specific project or technical issues with Gamefound, please use the guide below to get help.",
        "PageTitle": "Contact us",
        "ContactSearchResultByCreatorLabel": "by {0}",
        "BusinessContactSectionHeader": "Business contact",
        "ContactCooperationHeader": "Cooperation",
        "ContactCreatorHeader": "Creator",
        "ContactMediaHeader": "Media",
        "ContactPlatformRelatedHeader": "Platform related",
        "ContactSearchFormName": "Search for a project",
        "ContactSearchFormPlaceholder": "Start typing to find a project...",
        "ContactSearchInProgressLabel": "Searching...",
        "ContactSearchNoResultsParagraph": "No matching results found.",
        "ContactSearchResultsTitle": "Search results",
        "ContactSearchSuggestedTitle": "Projects suggested for you",
        "OtherIssuesSectionHeader": "Other issues",
        "TopicsSectionHeader": "What do you need help with?",
        "AccountProblemContactTopicTitle": "There is a problem with my account",
        "CancelRefundContactTopicTitle": "I want to cancel my pledge/I want a refund",
        "ContactSearchFormDefaultName": "Search topics",
        "ContactSearchFormDefaultPlaceholder": "Start typing to find a topic...",
        "ContactSearchTopicsFormName": "Search help topics",
        "ContactSearchTopicsFormPlaceholder": "Start typing to find a help topic...",
        "ContactSelectProjectLink": "click here",
        "ContactSelectProjectNotification": "Keep in mind that Gamefound is only a tool used by the game creators. We are not responsible for production and distribution of the games. All order and shipping related questions should be directed to the creators. If your question is related to a particular project, {0} to select a project.",
        "CreatorAccountProblemContactTopicTitle": "I'm a creator and have a problem with my account or project",
        "CreatorAnotherContactTopicTitle": "I have another topic I'd like to discuss",
        "CreatorCrowdfundingContactTopicTitle": "I want to use Gamefound as a crowdfunding platform",
        "CreatorPledgeManagerContactTopicTitle": "I want to use Gamefound as a pledge manager",
        "DifferentProblemContactTopicTitle": "I have a different problem",
        "DifferentQuestionContactTopicTitle": "I have a different question",
        "FeatureRequestContactTopicTitle": "I have a feature request",
        "FormEmailFieldLabel": "Your email address",
        "FormMessageFieldLabel": "Your message",
        "FormSubjectFieldLabel": "Subject",
        "InfluencerContactTopicTitle": "I am an influencer",
        "InformationSuggestionContactTopicTitle": "I need information/I have a suggestion about a project",
        "JournalistContactTopicTitle": "I am a journalist",
        "MarketingServicesContactTopicTitle": "I'm interested in Gamefound's marketing services",
        "OfferContactTopicTitle": "I have an offer",
        "PaymentProblemContactTopicTitle": "There is a problem with my payment",
        "PlatformContactRequestSentText": "We received your message and sent you a copy of your email.",
        "PlatformContactRequestSentTitle": "Your message was sent!",
        "ProjectContactRequestSentText": "We sent you a copy of your message to your mailbox.",
        "ProjectContactRequestSentTitle": "Your message was sent to the creator!",
        "QuestionContactTopicTitle": "I have a question",
        "ShippingQuestionContactTopicTitle": "I have a question related to shipping",
        "TechnicalProblemContactTopicTitle": "Technical issue with the site",
        "CouldNotSendContactNotificationEmailErrorMessage": "Could not send contact notification email.",
        "InvalidContactCategoryKeyErrorMessage": "Invalid category key was used. Could not send contact notification email.",
        "ReCaptchaInvalidTokenErrorMessage": "Invalid reCaptcha token",
        "ReCaptchaMissingTokenErrorMessage": "Missing reCaptcha token",
        "ReCaptchaVerificationFailedErrorMessage": "We've noticed some unusual activity. You can't send this message.",
        "TranslationIssueContactTopicTitle": "Translation issue",
        "AdfoundContactTopicTitle": "I have a question about Adfound"
    },
    "Cookies": {
        "AcceptCookiesHeader": "Gamefound is using cookies",
        "AcceptCookiesParagraph": "We use cookies to let you use the website properly, improve its functionalities and get feedback from users.",
        "AnalyticCookiesHeader": "Analytic cookies",
        "AnalyticCookiesParagraph": "Analytical cookies collect data about how you use our website and monitor its performance to improve our website's functionality and to measure, without your personal data identification, efficiency of our marketing activities. Among other things by using analytical cookies we create aggregate statistics and analysis, which can help us understand how you use our website. It helps us quickly identify and fix all errors, which you can encounter and provide you the high quality of browsing the website.",
        "CookieSettingsUpdatedMessage": "Your cookie settings have been updated.",
        "PageHeader": "Cookie settings",
        "SettingsBoxHeader": "Dear user",
        "SettingsBoxParagraph": "Below you will find information about cookies we use. Please check your cookies settings and choose which cookies you agree for us to use and install. For the purpose of using our website and its proper functioning, it is necessary to use technical cookies",
        "TechnicalCookiesHeader": "Technical cookies",
        "TechnicalCookiesParagraph": "Technical cookies don't collect any information about you, that could be used for marketing purposes or to remember places you visited in the Internet. They are necessary for the proper use of the website – using website without those cookies is impossible."
    },
    "CrowdfundingGuide": {
        "CampaignLaunchSectionHeader": "Campaign launch",
        "CampaignLaunchSectionParagraph": "At this stage, a project becomes available for backers to pledge to it. When you go to the project page, you can see what rewards the creator offers for a specific pledge. As long as the campaign is active, you can change your pledge, add some items or cancel it. You won't be charged until the campaign ends successfully.",
        "ExplainingStepsSectionHeader": "Explaining all steps",
        "ExplainingStepsSectionItem1Header": "Pledging",
        "ExplainingStepsSectionItem1Paragraph": "Ok, so let's say that you want to support a project. To do so, you have to pledge to it. What does that mean? A pledge is a promise that you'll pay the declared amount given in the pledge once the crowdfunding campaign ends successfully. Let's pledge! You can find all the pledge options (rewards) on the main page of the crowdfunding site. Once you select a reward and declare the amount you want to support the project with, you'll be asked to provide payment information (usually debit/credit card details). And that's it. You will be charged only if the campaign reaches its goal (or exceeds it).",
        "ExplainingStepsSectionItem2Header": "The goal and stretch goals",
        "ExplainingStepsSectionItem2Paragraph": "When the project is launched, the creator declares its goal - the amount of funds needed to make the project happen. When the goal is reached, backers are charged for their pledges. But reaching the campaign's goal is not the end of crowdfunding. This is when stretch goals step in. Stretch goals are additional targets for the campaign. They include freebies, extensions, upgraded quality of the product, etc. Each stretch goal is a reward for meeting a threshold, often backer numbers or reaching a specific funding level.",
        "ExplainingStepsSectionItem3Header": "Add-ons",
        "ExplainingStepsSectionItem3Paragraph": "Apart from rewards, you can also add different items to your pledge. Creators sometimes offer add-ons, such as fan items, game expansions, and gadgets like sleeves and playmats. These are items that are not included in rewards but can be added on their own. They are usually not necessary to fully enjoy rewards, but some can certainly provide extra fun to the product.",
        "ExplainingStepsSectionItem4Header": "Shipping",
        "ExplainingStepsSectionItem4Paragraph": "So, you pledged to a project and added payment details, but no one asked for your address. Why? Because the road from pledging to shipping can be very long. Backers are asked to provide this information in the pledge manager, not in the crowdfunding stage, because many things can happen in the meantime. Shipping cost is defined before the pledge manager phase.",
        "ExplainingStepsSectionItem5Header": "Late pledge",
        "ExplainingStepsSectionItem5Paragraph": "Once the campaign ends successfully, the creator can enable the option to back or continue to support the project after the crowdfunding stage. At this point, the shipping cost may not yet be defined, but a backer can contribute to a project that's already been funded, often with the same or similar rewards available.",
        "ExplainingStepsSectionItem6Header": "Pledge manager",
        "ExplainingStepsSectionItem6Paragraph": "The pledge manager is opened by the creator once the fulfillment costs are known. In the pledge manager, you can choose and manage the content of your pledge. You can also add more items. Apart from that, the pledge manager is where you provide shipping details, choose the shipping method, and pay for it.",
        "ExplainingStepsSectionItem7Header": "Risks",
        "ExplainingStepsSectionItem7Paragraph": "Crowdfunding doesn't work like a store. You don't receive the product right after pledging. In crowdfunding, the process of creating a product takes time because the community influences its final form. Remember that the creator is responsible for the project, and we are here to facilitate the crowdfunding process. You can always contact the creator directly and ask any questions you have about the campaign. There is a possibility that the campaign may not reach its goal. If this is the case, you won't lose anything, as backers aren't charged if a campaign fails.",
        "GamefoundDefinitionSectionHeader": "Gamefound is not a store",
        "GamefoundDefinitionSectionParagraph": "Remember that pledging doesn't mean buying. A project is not a buyable item but an idea that can come to life. Backers can pledge to it to make it happen and then receive rewards. In short, you contribute to an idea that may or may not materialize.",
        "IntroductorySectionParagraph1": "The idea behind crowdfunding can be compared to pre-orders - a special kind of pre-order. In crowdfunding, you can influence the end product result and decide how much money you want to give to support it. The more you give, the more you get.",
        "IntroductorySectionParagraph2": "Crowdfunding is based on an idea. A creator shares it with the community to get their support in funding and realization. It becomes a group project in which everyone has a say about the final form of the project. The creator gets to determine not just whether there is an audience for their concept but also how that audience's opinion might influence the idea as well.",
        "IntroductorySectionParagraph3": "A crowdfunding platform isn't a store. It's a place where creators can show their ideas to the community without necessarily showing the final product, as its form may change over time. In the case of crowdfunding, backers have a real influence on the product's final form.",
        "IntroductorySectionParagraph4": "You support the project by pledging, and the creators offer something in return for your contribution. You choose the pledge options that seem the most interesting to you. The content of the project is described on the project page. The higher the pledge, the more rewards you receive for contributing to the project. Many of these rewards can be limited, representing some degree of exclusive items, early access, lower pricing, as well as many other compelling reasons to support the project, aside from being a part of the journey and having a say in the process. Once you pledge, you become a backer.",
        "PageHeader": "What is crowdfunding?",
        "ProjectPreviewSectionHeader": "Project preview",
        "ProjectPreviewSectionParagraph": "Before a crowdfunding campaign launches, the creator can create a preview and collect feedback from the community. It is during this time that projects are initially shown to backers. Previews give creators time to adapt, so remember that what you see might be very different from the final version. You can't pledge to the project yet, but you can get a notification about its launch."
    },
    "Faq": {
        "FaqDisabledCreatorMessage": "Users cannot see the FAQ page of your project because it's currently disabled. Please contact us at business@gamefound.com to enable FAQ.",
        "FaqDisabledUserMessage": "The creator hasn't enabled the FAQ section yet.",
        "FaqPageHeader": "FAQ",
        "FaqSectionNotFoundMessage": "Could not find requested FAQ section.",
        "FaqUserEmptyStateButton": "Contact the creator",
        "FaqUserEmptyStateParagraph": "Check again later or contact the creator directly.",
        "FaqUserEmptyStateTitle": "The creator hasn't added anything to the FAQ section yet",
        "FeedbackFormParagraph": "Was this information helpful?",
        "NegativeFeedbackSubmittedParagraph": "Sorry this didn't help. Thanks for your feedback. It will help the creator build a better FAQ. Check the other answers or {0} to contact the creator.",
        "PositiveFeedbackSubmittedParagraph": "Thanks for the feedback! We will pass it on to the creator of {0}.",
        "SearchFaqTopicsLabel": "Search in FAQ",
        "SearchFaqTopicsPlaceholder": "Start typing to see search results..."
    },
    "Footer": {
        "LanguageVersionLinksColumnHeader": "View Gamefound in",
        "PlatformLinksColumnHeader": "Platform",
        "CreatorsLinksColumnHeader": "Creators"
    },
    "Homepage": {
        "ActiveSectionSeeMore": "view all active projects",
        "ActiveSectionSubtitle": "See all crowdfunding campaigns and pledge managers ready for backing.",
        "ActiveSectionTitle": "Active",
        "BecomeACreatorButton": "Become a creator",
        "BecomeACreatorStatsBackersLabel": "Backers",
        "BecomeACreatorStatsCreatorsLabel": "Creators",
        "BecomeACreatorStatsProjectsLabel": "Projects",
        "BecomeACreatorStatsValueLabel": "Value of pledges",
        "BecomeACreatorStatsValueTooltip": "The total value in EUR has been calculated using monthly average exchange rates.",
        "BecomeACreatorValuesTypedText1": "We connect board gamers around the world",
        "BecomeACreatorValuesTypedText2": "We accelerate the creation of amazing board games",
        "BrowseProjectsProjectCount": "{0} projects",
        "BrowseProjectsSectionSubtitle": "Explore board games by categories.",
        "BrowseProjectsSectionTitle": "Browse projects",
        "BrowseProjectsViewAllLink": "view all projects",
        "CreatorBenefitAnalyticsTooltip": "You can add Facebook Pixel and Google Analytics tracking to your project to check on all the relevant information. We also have a dedicated referral system.",
        "CreatorBenefitAnalyticsTitle": "Extensive analytics",
        "CreatorBenefitCommunityTooltip": "Gamefound's community consists of board game fans who have already backed tabletop projects or are about to support one.",
        "CreatorBenefitCommunityTitle": "Huge community",
        "CreatorBenefitCrowdfundingTooltip": "You don't have to import your project thanks to the seamless transition from crowdfunding to pledge manager.",
        "CreatorBenefitCrowdfundingTitle": "Crowdfunding with pledge manager",
        "CreatorBenefitFeaturesTooltip": "Built-in setup of stretch goals, add-ons, gifts for followers, easy navigation between sections on the project page are just a few features we offer.",
        "CreatorBenefitFeaturesTitle": "Tailor-made features",
        "CreatorBenefitFeedbackTooltip": "We're on a constant lookout for feature ideas that creators and backers need. We also share our experience with creators to make sure their campaigns prosper.",
        "CreatorBenefitFeedbackTitle": "Feedback",
        "CreatorBenefitGrowthTooltip": "We're developing dynamically and regularly introduce new, useful features for backers and creators.",
        "CreatorBenefitGrowthTitle": "Platform growth",
        "CreatorBenefitMarketingTooltip": "Our in-house marketing agency can help you with external promotion, e.g. on Facebook or BGG. All internal promotion (newsletter, top banners, featuring on the main page) is free.",
        "CreatorBenefitMarketingTitle": "Marketing",
        "CreatorBenefitPaymentsTooltip": "Our payment operator, Adyen, guarantees full compliance with PCI DS 3.2 standard. Your and your backers' money is completely safe.",
        "CreatorBenefitPaymentsTitle": "Secure payments",
        "CreatorBenefitPricingTooltip": "You pay the base crowdfunding fee from the raised amount and a payment processing fee. You don't pay anything if your project is not funded.",
        "CreatorBenefitPricingTitle": "Competitive pricing",
        "CreatorBenefitServicesTooltip": "We have a dedicated team of specialists responsible for the proper technology behind Gamefound, its efficiency, and reliability.",
        "CreatorBenefitServicesTitle": "Reliable services",
        "CreatorBenefitSupportTooltip": "When you need help, we are easy to reach through our contact page.",
        "CreatorBenefitSupportTitle": "Personal support",
        "CreatorBenefitVATTooltip": "Gamefound collects and settles EU/UK VAT for creators from outside these regions.",
        "CreatorBenefitVATTitle": "EU/UK VAT handling",
        "FeaturedSectionSeeMore": "explore more",
        "FeaturedSectionTitle": "Featured",
        "FollowProjectSuccessToast": "You are now following {0}.",
        "FundedSectionSeeMore": "view all funded projects",
        "FundedSectionSubtitle": "Follow the recently funded projects to get notified about their progress.",
        "FundedSectionTitle": "Funded",
        "HideAdvertBannerButton": "hide this notification",
        "PledgeManagerLimitedWithNewOrdersTooltip": "Only specific backers can access the pledge manager.",
        "ProjectCardAgePropertyLabel": "Age",
        "ProjectCardBenefitsPropertyLabel": "Benefits",
        "ProjectCardByCreatorLabel": "by {0}",
        "ProjectCardCategoryPropertyLabel": "Category",
        "ProjectCardCrowdfundingActivePhaseLabel": "Crowdfunding",
        "ProjectCardCrowdfundingDraftPhaseLabel": "Upcoming",
        "ProjectCardCrowdfundingEndedCanceledPhaseLabel": "Crowdfunding - Canceled",
        "ProjectCardCrowdfundingEndedFailedPhaseLabel": "Crowdfunding - Unsuccessful",
        "ProjectCardCrowdfundingEndedSuccessPhaseLabel": "Crowdfunding - Funded",
        "ProjectCardFollowWithGiftCaption": "{0} will be added to your pledge.",
        "ProjectCardFollowWithGiftTextPart1": "{0} to get a {1} at launch.",
        "ProjectCardFollowWithGiftTextPart2": "Follow",
        "ProjectCardFollowWithNoGiftCaption": "Join {0} followers.",
        "ProjectCardFollowWithNoGiftTextPart1": "{0} to stay involved.",
        "ProjectCardFollowWithNoGiftTextPart2": "Follow",
        "ProjectCardFundingAmountPledgedCaption": "pledged",
        "ProjectCardFundingGoalPercentageTooltip": "The campaign raised {0}% of its goal.",
        "ProjectCardFundingGoalReachedInCaption": "goal reached in {0}",
        "ProjectCardFundingTotalCaption": "total funding",
        "ProjectCardFundingTotalTooltip": "All funds collected during and after the campaign.",
        "ProjectCardIsFollowingCaption": "You'll be updated on its progress.",
        "ProjectCardIsFollowingText": "You're following this project.",
        "ProjectCardLatePledgeOpenPhaseLabel": "Late Pledge - Open for all",
        "ProjectCardPlayersPropertyLabel": "Players",
        "ProjectCardPlaytimeHoursText": "{0} h",
        "ProjectCardPlaytimeMinutesText": "{0} min",
        "ProjectCardPlayTimePropertyLabel": "Play time",
        "ProjectCardPledgeManagerClosedPhaseLabel": "Pledge manager - Closed",
        "ProjectCardPledgeManagerLimitedPhaseLabel": "Pledge manager - Limited",
        "ProjectCardPledgeManagerOpenPhaseLabel": "Pledge manager - Open for all",
        "ProjectListItemTypeStoresAndPreordersTitle": "Preorders & Stores",
        "ProjectListItemTypeUpcomingTitle": "Upcoming",
        "ProjectListItemTypeSoloModeTitle": "Solo mode",
        "ProjectListItemTypeInstallmentsTitle": "Accepting Stretch Pay",
        "UnfollowProjectSuccessToast": "You are no longer following {0}.",
        "UnfollowProjectWarningText": "You're about to unfollow this campaign. If you unfollow it now, you won't be able to claim a free gift when you back this project later. Do you still want to unfollow?",
        "UnfollowProjectWarningTitle": "Warning",
        "UpcomingSectionSeeMore": "view all upcoming projects",
        "UpcomingSectionSubtitle": "Explore and follow projects to know what's coming up.",
        "UpcomingSectionTitle": "Upcoming",
        "PledgeManagerLimitedNoNewOrdersTooltip": "Only specific backers can access the pledge manager.",
        "PledgeManagerLimitedShippingOnlyTooltip": "Only specific backers can add shipping details.",
        "PledgeManagerOpenNoNewOrdersTooltip": "Only backers can access the pledge manager.",
        "PledgeManagerOpenShippingOnlyTooltip": "Backers can add only shipping details.",
        "ProjectCardPromoItemDiscountLabel": "discount",
        "ProjectCardPromoItemGiftLabel": "gift",
        "ProjectCardStablePledgeTooltip": "Creator of this project guarantees the price estimates are final. If any of the estimates increase by more than 10%, backers will get a full refund for their pledge.",
        "ProjectCardStretchPayTooltip": "This project accepts installments. If your payment is higher than {0}, you may pay in {1} monthly payments.",
        "ProjectCardPreorderPhaseLabel": "Preorder",
        "ProjectCardStorePhaseLabel": "Store",
        "ProjectListsSectionTitle": "Project lists",
        "RankingsAllCategoriesOption": "All categories",
        "RankingsAllTimeTimeSpanOption": "All time",
        "RankingsAudienceCategoryOption": "By audience",
        "RankingsFundingCategoryOption": "By funding",
        "RankingsLast30DaysTimeSpanOption": "Last 30 days",
        "RankingsLast7DaysTimeSpanOption": "Last 7 days",
        "RankingsSectionTitle": "Rankings",
        "SpotlightSectionTitle": "Spotlight",
        "ProjectListGlobalCategoriesTitle": "Categories",
        "ProjectListPopularCategoriesTitle": "Popular",
        "ExploreCategoryActionLabelActive": "view active projects",
        "ExploreCategoryActionLabelEndingSoon": "view ending soon projects",
        "ExploreCategoryActionLabelGeneral": "view all projects",
        "ExploreCategoryActionLabelInstallments": "view projects with Stretch Pay",
        "ExploreCategoryActive": "Active",
        "ExploreCategoryEndingSoon": "Ending soon",
        "ExploreCategoryForYou": "For you",
        "ExploreCategoryInstallments": "Accepting Stretch Pay",
        "ExploreCategoryYouMayLike": "You may like",
        "ExploreSectionTitle": "Explore",
        "RankingsAudienceTypeLabel": "Audience",
        "RankingsFundingTypeLabel": "Funding",
        "CrowdfundingProjectsActionLabel": "view crowdfunding projects",
        "CrowdfundingProjectsSectionTitle": "Crowdfunding",
        "LatePledgeProjectsActionLabel": "view late pledge projects",
        "LatePledgeProjectsSectionTitle": "Late Pledge",
        "UpcomingProjectsActionLabel": "view upcoming projects",
        "ProjectListItemTypeCrowdfundingTitle": "Crowdfunding",
        "ProjectListItemTypeLatePledgeTitle": "Late Pledge",
        "RankingsLast24HoursTimeSpanOption": "Last 24 hours",
        "ProjectListItemTypeTtrpgTitle": "TTRPG",
        "ExploreCategoryActionLabelHighestFunded": "view all highest funded projects",
        "ExploreCategoryActionLabelMostDiscussed": "view all most discussed projects",
        "ExploreCategoryActionLabelMostPopularUpcoming": "view all most popular upcoming projects",
        "ExploreCategoryActionLabelPreorders": "view all preorders",
        "ExploreCategoryActionLabelRecentlyAddedUpdates": "view all recently added updates projects",
        "MostDiscussedSectionTitle": "Most discussed",
        "MostFundedSectionTitle": "Highest funded",
        "MostPopularUpcomingSectionTitle": "Most popular upcoming",
        "PreordersSectionTitle": "Preorders",
        "RecentlyAddedUpdatesSectionTitle": "Recently added updates",
        "ProjectCardLaunchedLabel": "Launched!",
        "ProjectCardLaunchingInLabel": "Launching in:"
    },
    "JoinProject": {
        "ContinueToProjectHomeButton": "Continue to project home",
        "CreatorEmailCommunicationConsentLabel": "I agree to receiving email communication from this creator",
        "CreatorEmailCommunicationConsentTooltip": "By checking this you agree to that {0} can use your email address to communication in regards to this project outside our platform.",
        "CreditsGrantedToAccountParagraph": "The creators of {0} have granted your account with {1} credits to spend on your pledges.",
        "DiscardPledgeCreatedByCreatorOption": "No, I want to start from scratch",
        "KeepPledgeCreatedByCreatorOption": "Yes, I want to keep this",
        "ManageYourPledgeHeader": "You were invited to manage your pledge in {0}!",
        "ManageYourPledgeParagraph": "The creators of {0} have invited you to manage your pledge. Your pledge was imported from the platform where you backed their project to Gamefound, where you can make changes or additions as needed.",
        "PledgeCreatedByCreatorFollowUpParagraph": "Do you want to keep this in your pledge, or start from scratch and pick something else?",
        "PledgeCreatedByCreatorIntro": "The creators of {0} have set your pledge with the following products:",
        "ViewYourPledgeButton": "View your pledge",
        "WelcomeToPledgeManagerHeader": "You have joined the {0} pledge manager!"
    },
    "Layout": {
        "AboutUsLink": "About us",
        "ActionRequiredSectionTitle": "Actions required",
        "ActivitySectionTitle": "Activity",
        "BecomeACreatorLink": "Become a creator",
        "BlogLink": "Blog",
        "BrowserNotSupportSharingFeatureText": "Your browser does not support the sharing feature",
        "CareerLink": "Career",
        "CartBoxCheckoutButton": "Check out",
        "CartBoxEmptyPledge": "Your pledge is empty.",
        "CartBoxItemQuantity": "Quantity: {0}",
        "CartBoxModifyText": "You are modifying pledge {0}",
        "CartBoxMultiplePledgesText": "You have carts\nin {0} projects",
        "CartBoxMultiplePledgesTitle": "Your current pledges",
        "CartBoxNoPledgesTitle": "Your pledge",
        "CartBoxTotalItemsQuantity": "Items: {0}",
        "CartBoxViewAllButton": "View all",
        "CartBoxViewCartButton": "View your pledge",
        "CartBoxViewPledgesButton": "View pledges",
        "CommunicationPreferencesLink": "communication preferences",
        "ContactLink": "Contact",
        "CookieSettingsLink": "Cookie Settings",
        "CreatorProjectsHeader": "Creator {0}",
        "CreatorProjectsHeaderPluralizedProject": "project|projects",
        "CrowdfundingGuideLink": "Crowdfunding Guide",
        "DismissActionButtonText": "dismiss",
        "EndingInLabel": "Ending in",
        "FeesLink": "Fees",
        "HeaderLoginLink": "Log in",
        "HeaderSignupLink": "Sign up",
        "HelpLink": "Help",
        "HideThisMessageButton": "hide this message",
        "ImageFieldDropOrSelectLabel": "Drop your image here or select from disk",
        "ImageFieldSelectLabel": "Select image from disk",
        "ImageFieldStandardFormatsAcceptedLabel": "standard image formats accepted",
        "InLabel": "in",
        "LanguageDropdownReportTranslationIssueLink": "report translation errors",
        "LanguageDropdownReportTranslationIssueParagraph": "Platform translation (beta) was provided by AI. Help us improve: {0}",
        "LanguageSelectorModalDescription": "You will see interface of this platform translated into the selected language. If the creator added a translation of the project in this language, it will be automatically displayed to you as well.",
        "LanguageSelectorModalInformation": "The translation of the platform is provided by AI. If you find any errors in your language, you can report translation issues. Note: Project content, description and rewards are translated by creators. In case of any issues with the project translation, contact the creator.",
        "LanguageSelectorModalLabel": "Platform language",
        "LanguageSelectorModalTitle": "Platform language choice",
        "MarketingServicesLink": "Marketing services",
        "ModalCancelButton": "Cancel",
        "ModalSaveButton": "Save",
        "PerformanceMarketingLink": "Marketing with Adfound",
        "PrivacyPolicyLink": "Privacy Policy",
        "PushNotificationDenyButton": "Maybe later",
        "PushNotificationEnableButton": "Enable notifications",
        "PushNotificationModalDeniedText": "Your browser settings currently block push notifications. See our help center for how to enable them.",
        "PushNotificationModalText": "Enable notifications to receive updates on everything important related to the projects you back and follow.",
        "PushNotificationModalTitle": "Never miss a beat!",
        "SearchFormEmptyState": "Sorry, we found nothing. Try changing your search.",
        "SearchFormPlaceholder": "type something",
        "SearchFormResultsCreatorsCountLabel": "Creators: ",
        "SearchFormResultsProjectsCountLabel": "Projects:",
        "SearchFormViewMoreLink": "View more",
        "SeenLabel": "Seen already",
        "ShareActionText": "Check this out on Gamefound",
        "TermsOfServiceLink": "Terms of Service",
        "UnseenLabel": "New",
        "UserBoxAdminLink": "Go to admin area",
        "UserBoxCommunicationPreferencesLink": "Communication preferences",
        "UserBoxDisableTestModeButton": "Quit test mode",
        "UserBoxEnableTestModeButton": "Enter test mode",
        "UserBoxImpersonatingLabel": "Impersonating",
        "UserBoxLogoutButton": "Log out",
        "UserBoxMyAccountLink": "User settings",
        "UserBoxMyProjectsLink": "My projects",
        "UserBoxPlatformLink": "Go to Gamefound",
        "UserBoxProfileLink": "User profile",
        "UserBoxRevertImpersonationButton": "revert",
        "UserLocationBoxButtonLabel": "change location",
        "UserLocationModalConfiration": "Location saved",
        "UserLocationModalFieldsetHeading": "Delivery to",
        "UserLocationModalInformation": "Locations you are ordering to. This will be used to calculate taxes and present you stock dedicated to your location.",
        "UserLocationModalTitle": "Choose your location",
        "WebPushAdjustPreferencesParagraph": "You can adjust your preferences anytime under",
        "WebPushBlockedParagraph": "Browser settings currently block push notifications. See our help center for how to enable them.",
        "WebPushConfirmationSuccessMessage": "Notifications enabled.",
        "WebPushDeliveryTimeElapsedLabel": "{0} {1} ago",
        "WebPushEmptyStateParagraph": "There are no notifications to show. As soon as you follow or back projects, updates will appear here.",
        "WebPushEnableButton": "Enable push notifications",
        "WebPushEnableParagraph": "Keep up with the latest activities and updates on Gamefound by enabling push notifications.",
        "WebPushHowToUnblockLink": "Read instructions",
        "WebPushLabel": "Notifications",
        "WebPushNotSupportedErrorMessage": "Browser does not support push notifications",
        "WebPushUnaothorizedUserParagraph": "Notifications are available for logged-in users.",
        "WepPushDeliveryNowLabel": "Now"
    },
    "MyProjects": {
        "EmptyStateBoxActionButton": "Change filters",
        "EmptyStateBoxHeader": "There are no projects to show",
        "EmptyStateBoxParagraph": "You can try different filtering criteria to find what you're looking for.",
        "MyProjectClaimYourGiftActionButton": "Claim your gift",
        "MyProjectClaimYourGiftActionLabel": "You can pledge to this project and collect a gift for following the campaign.",
        "MyProjectCompletePaymentActionButton": "Complete payment",
        "MyProjectCompletePaymentActionLabel": "You haven't finished the payment process for this pledge.",
        "MyProjectCompletePledgeActionLabel": "Your shipping address is required to complete your pledge.",
        "MyProjectCompleteYourPledgeActionButton": "Complete your pledge",
        "MyProjectInvolvementBackerTypeLabel": "You've backed this project.",
        "MyProjectInvolvementCanceledPledgeTypeLabel": "You were a backer.",
        "MyProjectInvolvementFollowerTypeLabel": "You're following this project.",
        "MyProjectInvolvementStoreCustomerTypeLabel": "You have ordered from this store",
        "MyProjectInvolvementUnfinishedPledgeTypeLabel": "You have an unfinished pledge in this project.",
        "MyProjectInvolvementUnspentCreditsTypeLabel": "You have credits available in this project.",
        "MyProjectPendingEditActionButton": "Go to checkout",
        "MyProjectPendingEditActionLabel": "You haven't submitted the changes in your pledge.",
        "MyProjectUnfinishedCartActionButton": "Go to checkout",
        "MyProjectUnfinishedCartActionLabel": "You haven't finished your pledge.",
        "MyProjectViewYourPledgeActionButton": "View your pledge",
        "MyProjectViewYourPledgeActionLabel": "There's been a problem with collecting your installment.",
        "PageHeader": "My Projects",
        "ProjectArchivedActionTooltipText": "Project moved to the archive. You can find it using the \"archived\" filter.",
        "ProjectEngagementCardBackerNumberLabel": "You're backer no. {0}.",
        "ProjectEngagementCardCompletedOrdersCounterLabel": "Completed orders:",
        "ProjectEngagementCardDifferentCurrenciesTooltipText": "You paid for your pledges in different currencies, so the total value of all your pledges is shown in the project currency",
        "ProjectEngagementCardGoToCommunicationPreferencesActionLabel": "Go to communication preferences",
        "ProjectEngagementCardInstallmentProgressLabel": "Installment progress:",
        "ProjectEngagementCardInstallmentProgressValue": "{0} of {1} paid.",
        "ProjectEngagementCardInstallmentsLastPaymentDateLabel": "Last payment date",
        "ProjectEngagementCardInstallmentsNextPaymentAmountLabel": "Next installment amount",
        "ProjectEngagementCardInstallmentsNextPaymentDateLabel": "Next payment date",
        "ProjectEngagementCardMoveToArchiveActionLabel": "Move to archive",
        "ProjectEngagementCardOrderStageLabel": "Order stage:",
        "ProjectEngagementCardPaymentStatusLabel": "Payment status:",
        "ProjectEngagementCardPledgeValueLabel": "Pledge value:",
        "ProjectEngagementCardRestoreProjectActionLabel": "Restore project",
        "ProjectEngagementCardTotalPledgesCounterLabel": "Total pledges:",
        "ProjectEngagementCardValueOfAllOrdersLabel": "Value of all orders:",
        "ProjectEngagementCardValueOfAllPledgesLabel": "Value of all pledges:",
        "ProjectEngagementCardViewProjectLink": "view project",
        "ProjectEngagementCardViewYourOrdersLink": "view your orders",
        "ProjectEngagementCardViewYourPledgeLink": "view your pledge",
        "ProjectRestoredActionTooltipText": "Project restored.",
        "StoreMyProjectCompletePaymentActionLabel": "You haven't finished the payment process for this order.",
        "StoreMyProjectInvolvementUnfinishedCartTypeLabel": "You have an unfinished order in this project.",
        "StoreMyProjectUnfinishedCartActionLabel": "You haven't finished your order."
    },
    "OrderStatus": {
        "AwaitingPaymentStatusParagraph": "The pledge hasn't been paid.",
        "FinishPledgeButton": "checkout",
        "NotificatorPartiallyPaidMessage": "You've paid for part of your pledge. Remove unpaid items and/or pay the order balance.",
        "NotificatorPledgeNotFinishedMessage": "You haven't finished your pledge.",
        "NotificatorProblemWithCollectingInstallmentsMessage": "There's been a problem with collecting your installment. Details below.",
        "NotificatorShippingAddressMissingMessage": "Your shipping address is required to complete your pledge.",
        "OrderAwaitingPaymentStatusParagraph": "The order hasn't been paid.",
        "OrderStageHistoryNoteForDigitalCodes": "Your digital codes will be delivered in a separate email.",
        "OrderPaidStatusParagraph": "The order has been fully paid.",
        "OrderPartiallyPaidStatusParagraph": "The order has been partially paid.",
        "OrderPaymentStatusAwaitingPaymentDescription": "The pledge hasn't been paid.",
        "OrderPaymentStatusAwaitingPaymentName": "Awaiting payment",
        "OrderPaymentStatusNoneName": "None",
        "OrderPaymentStatusPaidDescription": "The pledge has been fully paid.",
        "OrderPaymentStatusPaidName": "Paid",
        "OrderPaymentStatusPartiallyPaidDescription": "The pledge has been partially paid.",
        "OrderPaymentStatusPartiallyPaidName": "Partially paid",
        "OrderPaymentStatusScheduledAndAwaitingPaymentDescription": "The pledge is charged according to the installment schedule. The products added to a pledge after crowdfunding are still awaiting payment.",
        "OrderPaymentStatusScheduledAndAwaitingPaymentName": "Scheduled and awaiting payment",
        "OrderPaymentStatusScheduledDescription": "The pledge is charged according to the installment schedule.",
        "OrderPaymentStatusScheduledName": "Scheduled",
        "OrderStageCanceledName": "Canceled",
        "OrderStageCompletedName": "Completed",
        "OrderStageEditedByCreatorName": "Edited by creator",
        "OrderStagePartiallyShippedName": "Partially shipped",
        "OrderStagePlacedName": "Placed",
        "OrderStagePledgedName": "Pledged",
        "OrderStagePreparingForShippingName": "Preparing for shipping",
        "OrderStageReadyForShippingName": "Ready for shipping",
        "OrderStageShippedName": "Shipped",
        "OrderStageUnfinishedName": "Unfinished (cart)",
        "OrderStatusExplanationAllSetHeader": "All set for now!",
        "OrderStatusExplanationCrowdfundingEndedPaymentPaidNextStepParagraph": "Wait for the project to open for late pledges or launch the pledge manager. In late pledge, you can edit your pledge's content. In the pledge manager, you can edit its content and settle the shipping cost.",
        "OrderStatusExplanationCrowdfundingEndedPaymentPaidParagraph": "Your payment was collected successfully.",
        "OrderStatusExplanationCrowdfundingEndedPaymentScheduledInstallmentsParagraph": "Your payments are collected according to the installment schedule.",
        "OrderStatusExplanationCrowdfundingPaidNextStepParagraph": "Wait for the campaign to finish.",
        "OrderStatusExplanationCrowdfundingPaymentScheduledInstallmentsNextStepParagraph": "Wait for the campaign to finish. You will be charged according to the installment schedule only after the campaign ends successfully.",
        "OrderStatusExplanationCrowdfundingPaymentScheduledInstallmentsParagraph": "You chose Stretch Pay and successfully verified your payment method. It wasn't charged yet.",
        "OrderStatusExplanationLatePledgePaymentPaidNextStepParagraph": "Wait for the project to launch the pledge manager. Once it opens, add shipping address and settle shipping cost.",
        "OrderStatusExplanationLatePledgePaymentPaidParagraph": "Your payment was collected successfully. You can freely modify your pledge now.",
        "OrderStatusExplanationLatePledgePaymentScheduledInstallmentsNextStepParagraph": "Wait for the project to launch the pledge manager. Once it opens, add shipping address and settle shipping cost.",
        "OrderStatusExplanationLatePledgePaymentScheduledInstallmentsParagraph": "Your payments are collected according to the installment schedule. You can freely modify your pledge now.",
        "OrderStatusExplanationPaymentNoneNextStepParagraph": "Wait for the campaign to finish. You will be charged only after the campaign ends successfully.",
        "OrderStatusExplanationPaymentNoneParagraph": "Your payment method has been verified to ensure its validity, but it wasn't charged yet.",
        "OrderStatusExplanationPledgeManagerCompletedHeader": "The creator completed your pledge.",
        "OrderStatusExplanationPledgeManagerPartiallyShippedHeader": "A part of your pledge was shipped.",
        "OrderStatusExplanationPledgeManagerPartiallyShippedNextStepParagraph": "You'll get the rest of your pledge in the next shipping wave.",
        "OrderStatusExplanationPledgeManagerPartiallyShippedParagraph": "The pledge is on the way! Wait for the delivery and enjoy :)",
        "OrderStatusExplanationPledgeManagerPaymentPaidHeader": "Your payment was collected successfully!",
        "OrderStatusExplanationPledgeManagerPaymentPaidNextStepParagraph": "The creator will send you your pledge when it's ready.",
        "OrderStatusExplanationPledgeManagerPaymentPaidParagraph": "Follow the project updates on progress and shipping timelines.",
        "OrderStatusExplanationPledgeManagerPaymentScheduledHeader": "Your pledge is charged according to the installment schedule.",
        "OrderStatusExplanationPledgeManagerPaymentScheduledNextStepParagraph": "The creator will send you your pledge when it's ready.",
        "OrderStatusExplanationPledgeManagerPaymentScheduledParagraph": "Follow the project updates on progress and shipping timelines.",
        "OrderStatusExplanationPledgeManagerPreparingForShippingCanAddNewItemsHeader": "The creator allows adding new items to pledges, but removing items or changing the shipping address is impossible.",
        "OrderStatusExplanationPledgeManagerPreparingForShippingCanAddNewItemsNextStepParagraph": "The creator will send you your pledge when it's ready.",
        "OrderStatusExplanationPledgeManagerPreparingForShippingCanAddNewItemsParagraph": "Follow the project updates on shipping timelines.",
        "OrderStatusExplanationPledgeManagerPreparingForShippingHeader": "Your pledge is getting prepared for shipping.",
        "OrderStatusExplanationPledgeManagerPreparingForShippingNextStepParagraph": "The creator will send you your pledge when it's ready.",
        "OrderStatusExplanationPledgeManagerPreparingForShippingParagraph": "Follow the project updates on shipping timelines.",
        "OrderStatusExplanationPledgeManagerPreparingShippingHeader": "Your pledge is getting ready for shipping.",
        "OrderStatusExplanationPledgeManagerPreparingShippingNextStepParagraph": "The creator will send you your pledge when it's ready.",
        "OrderStatusExplanationPledgeManagerPreparingShippingParagraph": "Follow the project updates on shipping timelines.",
        "OrderStatusExplanationPledgeManagerShippedHeader": "Your pledge has been shipped.",
        "OrderStatusExplanationPledgeManagerShippedParagraph": "The pledge is on the way! Wait for the delivery and enjoy :)",
        "OrderStatusExplanationStoreNextStepParagraph": "The creator will pack and ship your order.",
        "OrderStatusExplanationStorePaymentPaidParagraph": "Your order has been successfully placed and paid for.",
        "PaidStatusParagraph": "The pledge has been fully paid.",
        "PartiallyPaidStatusParagraph": "The pledge has been partially paid.",
        "ResumePaymentButton": "resume payment",
        "ResumePaymentMessage": "You haven't paid for your pledge.",
        "ScheduledAndAwaitingPaymentStatusParagraph": "You pledged using Stretch Pay and haven't paid yet for shipping or products added to your pledge after crowdfunding.",
        "ScheduledStatusParagraph": "You've chosen to pay for your pledge with installments.",
        "StoreNotificatorPartiallyPaidMessage": "Your order has been partially paid. Please make payment for the remaining balance of the order.",
        "StoreResumePaymentMessage": "You haven't paid for your order.",
        "OrderPaymentStatusAwaitingPaymentRetailDescription": "The order hasn't been paid.",
        "OrderPaymentStatusPaidRetailDescription": "The order has been fully paid.",
        "OrderPaymentStatusPartiallyPaidRetailDescription": "The order has been partially paid.",
        "OrderPaymentStatusScheduledAndAwaitingPaymentRetailDescription": "The order is charged according to the installment schedule. The products added to a order after placing it are still awaiting payment.",
        "OrderPaymentStatusScheduledRetailDescription": "The order is charged according to the installment schedule.",
        "OrderPaymentStatusCanceledName": "Canceled",
        "OrderPaymentStatusPaymentErrorName": "Payment error",
        "OrderPaymentStatusProcessingPaymentName": "Processing payment",
        "OrderPaymentStatusProcessingPaymentDescription": "The order payment is in process."
    },
    "PaymentSuccess": {
        "BackerNumberMessage": "You are backer #{0}",
        "CrowdfundingPageSubHeader": "You will be charged within 24 hours from the campaign's successful end.",
        "CrowdfundingPageSubHeaderInstantPayment": "You were charged for your pledge. In case you want to cancel it, contact the creator directly and request a refund.",
        "CrowdfundingPaymentSuccessStatisticHeader": "You've contributed to reaching the goal",
        "DetailsSectionHeader": "Congratulations! Your pledge is complete! 🎉",
        "EarlyShippingCollectionParagraph": "Shipping fee has been collected. You will be asked to provide your precise shipping address during the pledge manager stage.",
        "FaqSectionCommentsLink": "comments",
        "FaqSectionContactFormLink": "contact form",
        "FaqSectionHeaderItem1": "When will you charge me for my pledge?",
        "FaqSectionHeaderItem2": "What about the shipping?",
        "FaqSectionHeaderItem3": "Can I update my pledge?",
        "FaqSectionHeaderItem4": "Other questions?",
        "FaqSectionHeaderItem5": "Where can I find and manage my pledge?",
        "FaqSectionHeaderItem6": "Can I change my shipping address?",
        "FaqSectionHeaderItem7": "Can I add items to my pledge?",
        "FaqSectionParagraphItem1": "You will be charged within 24 hours from the campaign's successful end.",
        "FaqSectionParagraphItem2": "Shipping details and fees aren't collected at this stage. The project creator will ask you to settle the shipping cost at a later date.",
        "FaqSectionParagraphItem3": "Sure. You can change your pledge content before the campaign's end or in the pledge manager.",
        "FaqSectionParagraphItem4": "Visit the {0} section or use the {1} to get answers you need. Follow the project {2} and share your thoughts about the project in the {3}.",
        "FaqSectionParagraphItem5": "Go to {0} to review its details.",
        "FaqSectionParagraphItem6": "You can change your shipping address before the pledge is sent. Remember that changing your shipping address may influence its cost.",
        "FaqSectionParagraphItem7": "You can add items to your pledge as long as the project is open.",
        "FaqSectionProjectUpdatesLink": "updates",
        "FaqSectionWithInstantPaymentParagraphItem1": "You were already charged. You can contact the creator and withdraw your pledge before the campaign's end to get a full refund.",
        "NewsletterBannerConfirmationHeader": "Thank you for joining our community!",
        "NewsletterBannerConfirmationParagraph": "You can manage your subscription in the account settings.",
        "NewsletterBannerHeader": "Gaming updates in your mailbox",
        "NewsletterBannerParagraph": "Subscribe to our newsletter to get updates about interesting campaigns on Gamefound. We send these messages about once a month.",
        "NewsletterBannerSubscribeButton": "subscribe",
        "NotificatorOverpaidOrderErrorMessage": "Your pledge is overpaid. Please contact the creator to get a refund of the excess payment.",
        "PageCrowdfundingInstantSubheader": "Thank you for being part of the project! You paid for your pledge. If you change your mind, you can ask the creator to cancel your pledge and receive a refund up until the end of the crowdfunding campaign.",
        "PageHeader": "You backed {0}",
        "PageSubheader": "You've just pledged to the project and became part of it.",
        "PageTitle": "Order information",
        "PaymentSuccessCreatorCustomMessageTitle": "Special thanks from {0}",
        "PledgeManagerPaymentSuccessStatisticHeader": "You've backed the project",
        "ProjectHomeLink": "project home page",
        "RecommendationSectionHeader": "Explore more",
        "ReturnActionButton": "View your pledge",
        "SocialMediaMessage": "Share this project to help it grow.",
        "StepHeader": "Next step:",
        "StoreDetailsSectionHeader": "You placed an order",
        "StoreFaqSectionHeaderItem1": "Where can I find my order?",
        "StoreFaqSectionHeaderItem2": "Other questions?",
        "StoreFaqSectionParagraphItem1": "Go to {0} to review its details.",
        "StoreFaqSectionParagraphItem2": "Visit the {0} section or use the {1} to get answers you need.",
        "StorePageHeader": "Your order in {0} was placed successfully",
        "StorePageSubheader": "You've just placed an order for the store and became part of it.",
        "StoreReturnActionButton": "View your order",
        "ToastNewsletterSubscriptionSuccessMessage": "You subscribed to our newsletter.",
        "UnlockStreatchGoalSectionHeader": "... and helped unlock the following stretch goals",
        "UnlockStretchGoalMessage": "Unlocks at {0}",
        "YourOrderPageLink": "Your order",
        "YourPledgePageLink": "Your pledge"
    },
    "Product": {
        "MissingOrInvalidProductIdErrorMessage": "Missing or invalid productID param in request.",
        "ProductCardAddToOrderButton": "Add to order",
        "ProductLimitPluralizedCount": "{n} copy|{n} copies",
        "ProductValidationLimitInfoTooltipText": "You can add only {0} of this product.",
        "ProductValidationLimitReachedTooltipText": "You can't add any more copies of this product to your pledge.",
        "ProductValidationMinQuantityTooltipText": "The quantity field must be 1 or more.",
        "ProductValidationPledgeNotFinishedTooltipText": "Adding items is disabled because you haven't finished your current pledge.",
        "ProductValidationStockLimitReachedTooltipText": "You can't add this product to your pledge because there are no more copies of it available.",
        "RewardCardAddToPledgeButton": "Add to pledge",
        "RewardCardLowestPriceInLast30Days": "lowest price in last 30 days: {0}",
        "RewardCardMoreInfoLink": "more info",
        "RewardCardMostPopularLabel": "Most popular",
        "RewardCardPledgeCountText": "pledged {0}",
        "RewardCardPluralizedCount": "{n} time|{n} times",
        "RewardCardProductDescriptionTitle": "Product description",
        "RewardCardProductSetItemQuantityLabel": "Quantity: {0}",
        "RewardCardReviewsTitle": "Reviews",
        "RewardCardSetItemTableHeader": "This set consist of {0}:",
        "RewardCardStretchPayGetItForText": "Get it for {0}/month",
        "RewardCardStretchPayTooltipText": "Stretch Pay is available for payment amounts higher than {0}.",
        "SetItemsAppliedOptionLabel": "Applies to {0}",
        "SetItemsAppliedOptionsTooltipText": "This option applies to following {0}:",
        "SetItemsPluralized": "item|items",
        "SetItemsPluralizedCount": "{n} item|{n} items",
        "ProductNotExistErrorMessage": "Product doesn't exist.",
        "ProductNotBuyableErrorMessage": "Product is not buyable.",
        "ProductNotAvailableInCountryErrorMessage": "Product is not available in your country.",
        "ProductHasInvalidConfigurationErrorMessage": "This product has invalid contents and cannot be added to your pledge at this time.",
        "ProductValidationOrderNotFinishedTooltipText": "Adding items is disabled because you haven't finished your current order.",
        "StoreProductHasInvalidConfigurationErrorMessage": "This product has invalid contents and cannot be added to your order at this time.",
        "StoreProductValidationLimitReachedTooltipText": "You can't add any more copies of this product to your order.",
        "StoreProductValidationStockLimitReachedTooltipText": "You can't add this product to your order because there are no more copies of it available.",
        "RewardCardFeaturedLabel": "Featured",
        "ProductValidationPaymentProcessingTooltipText": "Adding items is disabled because we're processing your payment."
    },
    "Profile": {
        "CommentUserProfilePrivateStateParagraph": "This profile is private. No one can view it.",
        "EmptyStateCommentsParagraph": "{0} hasn't posted any comments.",
        "EmptyStateCommentsParagraphOwnProfile": "Add comment to fill in this space.",
        "EmptyStateCommentsTitle": "No comments to show",
        "EmptyStateFollowersParagraph": "The list is ready to grow.",
        "EmptyStateFollowersTitle": "No followers to show",
        "EmptyStateFollowingParagraph": "The list is ready to grow.",
        "EmptyStateFollowingTitle": "No followed users to show",
        "EmptyStateProfilePrivateStatusOwnProfileAction": "Manage privacy settings",
        "EmptyStateProfilePrivateStatusOwnProfileTitle": "Your profile is private",
        "EmptyStateProfileStatusPrivateOwnProfileParagraph": "You can change this in your account settings.",
        "EmptyStateProfileStatusPrivateParagraph": "This user has hidden profile details. No one can view it.",
        "EmptyStateProfileStatusPrivateTitle": "This profile is private",
        "EmptyStateProfileViewParagraph": "This space is waiting to be filled in by {0}.",
        "EmptyStateProfileViewTitle": "There is nothing to show",
        "EmptyStateProjectParagraph": "{0} hasn't backed or followed any projects yet.",
        "EmptyStateProjectParagraphOwnProfile": "Follow or back a project to fill in this space.",
        "EmptyStateProjectsExploreAction": "Explore projects",
        "EmptyStateProjectsTitle": "No projects to show",
        "FollowersCountLabel": "follower | followers",
        "NotificationUserFollowingParagraph": "You can adjust notifications in your communication preferences.",
        "NotificationUserFollowingTitle": "You're now following {0}.",
        "NotificationUserUnfollowingParagraph": "You won't be notified about this user activity.",
        "NotificationUserUnfollowingTitle": "You're no longer following {0}.",
        "SectionCommentsHeader": "Comments",
        "SectionCommentsParagraph": "Comments posted by {0}.",
        "SectionFollowersHeader": "Followers",
        "SectionFollowersParagraph": "Users following {0}.",
        "SectionFollowingHeader": "Following",
        "SectionFollowingParagraph": "Users followed by {0}.",
        "SectionOverviewHeader": "Overview",
        "SectionProjectsHeader": "Projects",
        "SectionProjectsParagraph": "Followed and backed projects.",
        "SectionViewAllAction": "view all",
        "UserFollowAction": "Follow",
        "UserFollowingLabel": "You're following this user.",
        "UserFollowLogInToFollowAction": "Log in",
        "UserFollowLogInToFollowParagraph": "To follow this user, you must be signed in or {0} if you don't have one.",
        "UserFollowLogInToFollowParagraphAction": "create an account",
        "UserFollowLogInToFollowTitle": "Log in to follow",
        "UserFollowSettingsCancelButton": "cancel",
        "UserFollowSettingsMakeMyProfilePublicLabel": "Make my profile public.",
        "UserFollowSettingsMakeYourProfilePublicParagraph": "Make your profile public to follow other users.",
        "UserFollowSettingsNicknameLabel": "Nickname",
        "UserFollowSettingsPublicProfileFieldName": "public profile",
        "UserFollowSettingsPublicStatusLabel": "This profile is public.",
        "UserFollowSettingsSetNicknameLabel": "Set a nickname to follow other users.",
        "UserFollowSettingsTitle": "Profile and nickname details",
        "UserProfileCardFollowingBadge": "Following",
        "UserProfileCardFollowThisUserAction": "follow this user",
        "UserUnfollowAction": "unfollow",
        "ViewProfileAction": "view profile"
    },
    "ProjectDescriptionSectionType": {
        "PledgeOptionsDisplayName": "Pledge options",
        "ProjectStoryDisplayName": "Project story",
        "PledgeOptionRewardsDisplayName": "Rewards",
        "PledgeOptionStretchGoalsDisplayName": "Stretch goals",
        "PledgeOptionAddonsDisplayName": "Add-ons",
        "RisksAndChallengesDisplayName": "Risks and challenges",
        "RefundsAndCancellationDisplayName": "Refunds and cancellation"
    },
    "ProjectHome": {
        "AcceptingStoreTermsOfServiceText": "By purchasing in this store, you agree to {0} {1}.",
        "AddonsRestrictionSubheader": "This project doesn't allow to pledge add-ons only.",
        "AddToCartOptionsModalShowItemsInSetButton": "show items",
        "AddToCartOptionsModalTitle": "Add product to your pledge",
        "AddToCartSuccessCheckoutButton": "Submit pledge",
        "AddToCartSuccessContinueBrowsingButton": "continue browsing",
        "AddToCartSuccessModalInstallmentMissingAmountParagraph": "Pledge {0} more to unlock split payment with {1}",
        "AddToCartSuccessModalTitle": "Product added to your pledge",
        "AddToCartSuccessSubmitChangesHint": "<strong>Tip:</strong> You've changed your pledge content. Submit changes if you've finished modifying your pledge.",
        "AnimatedNumberPluralizedBacker": "backer|backers",
        "BackerInfoMessage": "You are a backer!",
        "CannotAddItemsAtThisTimeText": "You can't add items to your pledge at this time.",
        "CannotAddProductInArchiveViewText": "You can't add this product to your pledge because this is an archive view of the original crowdfunding campaign.",
        "CannotAddProductInClosedProjectText": "You can't add items to your pledge because this project is closed.",
        "CannotAddProductInDraftText": "You can't add items to the pledge because the project hasn't been launched yet.",
        "CannotAddProductInPreviewText": "You can't add this product to your pledge because this is a preview of {0}.",
        "CannotAddProductPledgeManagerNotLaunchedText": "You can't add items to your pledge because the Pledge Manager for this project hasn't been launched yet.",
        "ChangeLanguageButton": "change language",
        "CreatorResponsibleForThisProject": "Creator responsible for this project: {0} doing business as {1}.",
        "CrowdfundingDraftInfoModalSection1Header": "This isn't even its final form.",
        "CrowdfundingDraftInfoModalSection1Text": "The campaign is under construction, and you'll see its final version if it launches. All items, descriptions, and prices may change before the launch.",
        "CrowdfundingDraftInfoModalSection2Header": "Your opinion is more than welcome!",
        "CrowdfundingDraftInfoModalSection2Text": "Let creators hear your voice. Your ideas will help shape the final version of the campaign. Share your thoughts or show some support in the comments section.",
        "CrowdfundingDraftInfoModalSection3Header": "The campaign does not accept pledges yet.",
        "CrowdfundingDraftInfoModalSection3Text": "If you want to back this campaign, you have to wait for its launch.",
        "CrowdfundingDraftInfoModalSubtitle": "What does this mean?",
        "CrowdfundingDraftInfoTitle": "This is a campaign preview",
        "CustomTermsOfServiceLink": "terms of service",
        "DigitalProductBadge": "Digital",
        "FeaturedProductBadge": "Featured",
        "FollowProjectButton": "Follow",
        "HumanizeDateAgoLabel": "{0} ago",
        "HumanizeDatePluralizedDay": "day|days",
        "HumanizeDatePluralizedHour": "hour|hours",
        "HumanizeDatePluralizedMilisecond": "milisecond|miliseconds",
        "HumanizeDatePluralizedMinute": "minute|minutes",
        "HumanizeDatePluralizedMinuteShort": "min|mins",
        "HumanizeDatePluralizedMonth": "month|months",
        "HumanizeDatePluralizedSecond": "second|seconds",
        "HumanizeDatePluralizedSecondShort": "sec|secs",
        "HumanizeDatePluralizedYear": "year|years",
        "HumanizeDateTwoPartsLabel": "{0} and {1}",
        "ItemCannotBeAddedSeparatelyText": "This item can't be added to your pledge separately.",
        "LegacyFeaturedProductsHeader": "Featured products",
        "LegacyFeaturedProductsSubheader": "Most recommended!",
        "LegacyOverviewHeader": "Project overview",
        "LimitedProductBadge": "Limited",
        "MiniWizardAddMoreItemsButton": "Add more items",
        "MiniWizardBackerNumberText": "You are backer #{0}",
        "MiniWizardBackerWithoutNumberText": "You are a backer",
        "MiniWizardCancelPledgeButton": "Cancel pledge",
        "MiniWizardCartItemsHeader": "Your items",
        "MiniWizardChooseAddonsButton": "Pick add-ons",
        "MiniWizardChooseRewardsButton": "Choose rewards",
        "MiniWizardCollapsibleHideItemsButton": "hide items",
        "MiniWizardCollapsibleShowItemsButton": "show items",
        "MiniWizardCompletePledgeButton": "Complete pledge",
        "MiniWizardCompletePledgeShortButton": "Complete",
        "MiniWizardCreditDiscountLabel": "Credits discount:",
        "MiniWizardCurrentPledgeLabel": "Current pledge",
        "MiniWizardDividerLabel": "OR",
        "MiniWizardEditPledgeButton": "Edit pledge",
        "MiniWizardEditPledgeShortButton": "Edit",
        "MiniWizardExpandablePledgeValueText": "You pledged",
        "MiniWizardFeaturedRewardBadge": "Featured",
        "MiniWizardManagePledgeButton": "manage your pledge",
        "MiniWizardModifyingPledgeCostAfterLabel": "After",
        "MiniWizardModifyingPledgeCostBeforeLabel": "Before",
        "MiniWizardModifyingPledgeHint": "Submit to apply changes",
        "MiniWizardModifyingPledgeTitle": "You are modyfing your pledge.",
        "MiniWizardMoreAddOnsButton": "Add more add-ons",
        "MiniWizardNoAddonsSubheader": "No add-ons selected yet.",
        "MiniWizardNoCartItemsHeader": "No rewards selected yet.",
        "MiniWizardNoShippingTooltip": "Does not include shipping.",
        "MiniWizardPledgeModifiedText": "You have modified your original {0} pledge.",
        "MiniWizardPledgeValueText": "Your pledge",
        "MiniWizardRemovedAllItemsText": "You have removed all items from your pledge.",
        "MiniWizardRemovedPaidItemsText": "You have removed all possible items from your pledge.",
        "MiniWizardResumePaymentButton": "Resume payment",
        "MiniWizardRevertChangesButton": "revert changes",
        "MiniWizardSelectThisRewardButton": "Select this reward",
        "MiniWizardSubmitChangesButton": "Submit changes",
        "MiniWizardSubmitPledgeButton": "Submit pledge",
        "MiniWizardTaxSummaryInclTaxLabel": "incl. tax",
        "MiniWizardTaxSummaryPlusTaxLabel": "+tax",
        "MiniWizardUpgradePledgeButton": "Upgrade pledge",
        "MiniWizardUpgradePledgeShortButton": "Upgrade",
        "MiniWizardYourPledgeLabel": "Your pledge",
        "PledgeWithoutRewardAmountFieldName": "bonus amount",
        "PledgeWithoutRewardAmountFieldPlaceholder": "Your pledge amount",
        "PledgeWithoutRewardDescription": "Help the project reach its goal or tip the creator by pledging any amount you want (for no reward).",
        "PledgeWithoutRewardHeader": "Show your support",
        "PledgeWithoutRewardSuccessToast": "Bonus amount added to your pledge",
        "ProductStockLeftLabel": "{0} left",
        "ProjectApproximateCurrencyConversionTooltip": "Approximate conversion from {0}",
        "ProjectArchivedViewLink": "Go to the current project view",
        "ProjectArchivedViewMessage": "This is an archived view of the original crowdfunding.",
        "ProjectHeaderByCreatorTitle": "by {0}",
        "ProjectOpenOnlyForCreditsText": "The project is currently open for backers with credits. You can't add anything to your pledge because you don't have any.",
        "ProjectPageNavbarLink": "Project",
        "ProjectPromoItemAuthenticatedAndDoesntFollowTooltip": "The gift will be added to your pledge at checkout if you follow this campaign before launch.",
        "ProjectPromoItemAuthenticatedAndFollowsTooltip": "The gift will be added to your pledge at checkout.",
        "ProjectPromoItemBackNowLabel": "Back now to claim your free gift",
        "ProjectPromoItemDiscountLabel": " - <strong>{0}</strong> discount",
        "ProjectPromoItemFollowNowLabel": "Follow now to get a free gift",
        "ProjectPromoItemIsFollowingLabel": "You will get a free gift",
        "ProjectPromoItemNotAuthenticatedAndDoesntFollowTooltip": "The gift will be added to your pledge at checkout if you follow this campaign before launch. To get the gift, you have to follow and back the campaign using the same email.",
        "ProjectPromoItemNotAuthenticatedAndFollowsTooltip": "The gift will be added to your pledge at checkout. To get the gift, you have to follow and back the campaign using the same email.",
        "ProjectPromoItemTimeLeftForDiscountText": "You have {0} to become a backer and claim your {1} discount.",
        "ProjectPromoItemTimeLeftForGiftText": "You have {0} to become a backer and get a free gift.",
        "ProjectPropertiesAgeLabel": "Age",
        "ProjectPropertiesGenreLabel": "Genre",
        "ProjectPropertiesPlayersLabel": "Players",
        "ProjectPropertiesPlayTimeLabel": "Play time",
        "ProjectRecommendationPluralizedFollower": "follower|followers",
        "ProjectRecommendationsSearchLink": "explore",
        "ProjectRecommendationsText": "Based on your favorites and popular trends, discover new projects to {0}!",
        "ProjectRecommendationsTitle": "Other projects you may like",
        "ProjectSectionReportTranlsationIssueLink": "Report translation issues",
        "ProjectSectionReportTranlsationIssueParagraph": "Translation for this project is incomplete. Untranslated descriptions will be displayed in platform default language. {0} to help us get better.",
        "ProjectStoryAvailableLanguagesCountText": "There {0} {1} other {2} available.",
        "ProjectStoryAvailableLanguagesTooltipText": "Project page available in: {0}.",
        "ProjectStoryContactLink": "contact",
        "ProjectStoryCreatorLabel": "Creator",
        "ProjectStoryDisplayedLanguageText": "You're viewing in {0}.",
        "ProjectStoryMoreInfoLink": "more info",
        "ProjectStoryNoTranslationInSelectedLanguageText": "There is no {0} translation for this page.",
        "ProjectStoryNoTranslationText": "The project page has no other languages available.",
        "ProjectStoryStablePledgeLink": "learn about Stable Pledge",
        "ProjectStoryStablePledgeText": "This project is a part of a Stable Pledge program.",
        "ProjectStretchGoalBackersCountNotUnlockedDescription": "This goal unlocks when the project reaches {0}.",
        "ProjectStretchGoalBackersCountUnlockedDescription": "This goal was unlocked when the project reached {0}.",
        "ProjectStretchGoalCampaignDayNotUnlockedDescription": "This goal unlocks on day {0} of the campaign.",
        "ProjectStretchGoalCampaignDayUnlockedDescription": "This goal was unlocked on day {0} of the campaign.",
        "ProjectStretchGoalPercentage": "{0}% completed",
        "ProjectStretchGoalPledgeValueNotUnlockedDescription": "This goal unlocks when the project's total pledge amount reaches {0}.",
        "ProjectStretchGoalPledgeValueUnlockedDescription": "This goal was unlocked when the project's total pledge amount reached {0}.",
        "ProjectStretchGoalProgressBackerCount": "{0} needed",
        "ProjectStretchGoalProgressCampaignDay": "{0} {1} left",
        "ProjectStretchGoalProgressPledgeValue": "{0} remaining",
        "ProjectStretchGoalTitle": "Next stretch goal to unlock",
        "ProjectStretchGoalUnlocksByBackerCount": "{0} backers",
        "ProjectStretchGoalUnlocksByDay": "day {0}",
        "ProjectStretchGoalUnlocksByPledgeValue": "{0} pledged",
        "ProjectSummaryBackerCountSubHeader": "{0} backers raised ",
        "ProjectSummaryBoxAddMoreItemsText": "add more items to your pledge",
        "ProjectSummaryBoxAllStretchGoalsText": "All of the stretch goals were unlocked",
        "ProjectSummaryBoxBackerCountCrowdfundingOnlyLabel": "crowdfunded by {0} backers",
        "ProjectSummaryBoxBackerCountCrowdfundingOnlyTooltipText": "The creator displays only funds collected during crowdfunding.",
        "ProjectSummaryBoxBackerCountTotalFundingLabel": "total funded by {0} backers",
        "ProjectSummaryBoxBackerCountTotalFundingTooltipText": "The creator displays funds collected during and after crowdfunding.",
        "ProjectSummaryBoxBackerTextWithNumber": "You are backer #{0}",
        "ProjectSummaryBoxBackerTextWithoutNumber": "You are a backer",
        "ProjectSummaryBoxBackProjectButton": "Back this project",
        "ProjectSummaryBoxClosedSubHeader": "<strong>Pledge manager is closed</strong><br/>Pledges cannot be added or modified in any way.",
        "ProjectSummaryBoxCompletePledgeButton": "Complete pledge",
        "ProjectSummaryBoxCreditsAvailableText": "You have {0} available credits",
        "ProjectSummaryBoxCrowdfundingSummaryButton": "view crowdfunding summary",
        "ProjectSummaryBoxDraftCountdownElapsed": "Start imminent",
        "ProjectSummaryBoxDraftCountdownTitle": "Campaign launch in",
        "ProjectSummaryBoxDraftInfoTooltip": "The creator has published this page for you and others to review and provide feedback. Everything you see now may change before the launch.",
        "ProjectSummaryBoxFundedAtText": "This project was funded on {0}",
        "ProjectSummaryBoxLastUpdate": "Last update:",
        "ProjectSummaryBoxLatePledgeButton": "Late pledge",
        "ProjectSummaryBoxLatePledgeHeader": "This is a late pledge",
        "ProjectSummaryBoxLimitedAllowedSubHeader": "You can pledge to this project. <br/>The creator limited access to the pledge manager.",
        "ProjectSummaryBoxLimitedNoNewOrdersSubHeader": "This project doesn't accept new pledges.<br/>Only specific backers can access the pledge manager.",
        "ProjectSummaryBoxLimitedNotAllowedSubHeader": "Only specific backers can pledge to this project. <br/>The creator limited access to the pledge manager.",
        "ProjectSummaryBoxLimitedShippingOnlySubHeader": "This project doesn't accept new pledges.<br/>Only specific backers can add shipping details.",
        "ProjectSummaryBoxNoNewOrdersSubHeader": "This project doesn't accept new pledges.<br/>Only backers can access the pledge manager.",
        "ProjectSummaryBoxOpenSubHeader": "<strong>Everyone</strong> can pledge to this project.",
        "ProjectSummaryBoxOriginalCampaignButton": "View original campaign",
        "ProjectSummaryBoxPledgeManagerHeader": "This is a pledge manager",
        "ProjectSummaryBoxShippingOnlySubHeader": "This project doesn't accept new pledges.<br/>Backers can add only shipping details.",
        "ProjectSummaryBoxSomeStretchGoalsText": "<strong>{0} of {1}</strong> stretch goals were unlocked.",
        "ProjectSummaryBoxSpendCreditsLink": "spend your credits",
        "ProjectSummaryBoxUpgradePledgeButton": "Upgrade pledge",
        "ProjectSummaryGoalReachedInText": "Goal reached in <strong> {0} </strong>",
        "ProjectSummaryLearnMoreAboutCrowdfundingHeader": "About crowdfunding",
        "ProjectSummaryLearnMoreAboutCrowdfundingReadMoreLink": "read more",
        "ProjectSummaryLearnMoreAboutCrowdfundingText": "Crowdfunding is an idea that comes to life thanks to the community. It's a group project that can't happen without backers' support.",
        "ProjectSummaryLearnMoreButton": "learn more",
        "ProjectSummaryLearnMoreEndDateHeader": "Campaign's end",
        "ProjectSummaryLearnMoreFundingModeHeader": "Funding mode",
        "ProjectSummaryLearnMoreFundingModeText": "This campaign is all-or-nothing. This means the creator receives funds only after the project meets its goal.",
        "ProjectSummaryLearnMorePossibleRisksHeader": "Possible risks",
        "ProjectSummaryLearnMorePossibleRisksLink": "here",
        "ProjectSummaryLearnMorePossibleRisksText": "Each campaign has potential risks. Read about this project's risks {0}.",
        "ProjectSummaryNoTimeLeftToFinishLabel": "ending now",
        "ProjectSummaryNoTimeLeftToLaunchLabel": "launching now",
        "ProjectSummaryOthersAreFollowingText": "{0} following.",
        "ProjectSummaryPluralizedBoardgamers": "<strong>{n}</strong> person|<strong>{n}</strong> people",
        "ProjectSummaryPluralizedOtherBoardgamers": "<strong>{n}</strong> other person|<strong>{n}</strong> other people",
        "ProjectSummaryProgressCurrencyConversionTooltip": "Converted from {0} pledged of {1} goal.",
        "ProjectSummaryProgressGoalHeader": "Goal: {0} ({1} funded)",
        "ProjectSummaryProgressPluralizedBacker": "{n} backer|{n} backers",
        "ProjectSummaryStatisticsBackerCountText": "{0} pledged {1}",
        "ProjectSummaryStatisticsCanceledReasonText": "The creator canceled this project.",
        "ProjectSummaryStatisticsCanceledTitle": "Project was canceled",
        "ProjectSummaryStatisticsEndDateText": "The campaign ended on {0}.",
        "ProjectSummaryStatisticsFailedReasonText": "The project didn't meet its goal.",
        "ProjectSummaryStatisticsFailedTitle": "Project was not funded",
        "ProjectSummaryStatisticsGoalReachedInLabel": "Goal reached in <strong>{0}</strong>",
        "ProjectSummaryStatisticsNoFundsCollectedText": "No funds were collected from backers.",
        "ProjectSummaryStatisticsPercentageOfGoalText": "This is {0} of the {1} goal.",
        "ProjectSummaryStatisticsSuccessfulTitle": "Project funded",
        "ProjectSummaryStretchGoalNextLink": "next stretch goal",
        "ProjectSummaryStretchGoalPreviousLink": "previous stretch goal",
        "ProjectSummaryTimeLeftToFinishLabel": "{0} {1} left",
        "ProjectSummaryTimeLeftToLaunchLabel": "{0} {1} to launch",
        "ProjectSummaryYouAndOthersAreFollowingText": "You and {0} are following this project.",
        "ProjectSummaryYouAreFollowingText": "You are following this project.",
        "SetContentSectionHeader": "This product consists of",
        "StablePledgeSectionHeader": "Stable Pledge",
        "StablePledgeSectionText": "This project is a part of the Stable Pledge program. The creator guarantees a full refund to backers if the change in any price estimates after the campaign's end is bigger than 10%. Backers will be granted a 100% refund upon request made within 14 days after the change announcement. Gamefound will pay back its provision to the creator, who is solely responsible for refunds. <a href=\"{0}\" target=\"_blank\" rel=\"noopener noreferrer\">Learn more</a>.",
        "StorePageNavbarLink": "Products",
        "StretchGoalsNextHeader": "Next to unlock",
        "StretchGoalsUnlockedHeader": "Already unlocked",
        "StretchGoalsUnlockedSubHeader": "({0}/{1} unlocked)",
        "StretchPayAvailableInfo": "You can now pay using {0}",
        "UnfollowProjectButton": "Unfollow",
        "UnspentCreditsMessage": "You have {0} unspent credits.",
        "ProjectSummaryBoxPreorderButton": "Preorder",
        "ProjectSummaryBoxPreorderHeader": "This is a preorder",
        "ProjectSummaryBoxPreorderSubHeader": "Order products before the actual release.<br/>Check project details for more.",
        "MiniWizardCreditValueText": "Your credits",
        "MiniWizardCreditValueTooltip": "This amount will reduce your final payment.",
        "MiniWizardCompleteOrderButton": "Submit order",
        "MiniWizardManageOrderButton": "manage your order",
        "MiniWizardOrderValueText": "Your order",
        "AddToCartRestrictedModalFirstParagraph": "This product can't be added as it's not available for the delivery destination of your existing pledge.",
        "AddToCartRestrictedModalFourthPagraph": "To view products available in your original shipping country, {0} To change the shipping country, cancel or edit your order, removing items not available in the new location.",
        "AddToCartRestrictedModalParagraphAction": "adjust the shipping destination.",
        "AddToCartRestrictedModalSecondParagraph": "Shipping country of existing pledge: {0}",
        "AddToCartRestrictedModalThridParagraph": "Currently set shipping country: {0}",
        "AddToCartRestrictedModalTitle": "Product unavailable in this location",
        "StoreCardButton": "View Store",
        "StoreAddToCartSuccessModalTitle": "Product added to your order",
        "AddToCartSubmitOrderButton": "Submit order",
        "MiniWizardOrderPlacedText": "You've placed an order",
        "MiniWizardYourOrderLabel": "Your order",
        "StoreAddToCartOptionsModalTitle": "Add product to your order",
        "HumanizeLanguageLabel": "language|languages",
        "HumanizeToBePluralized": "is|are",
        "ProjectContentsDescriptionMissingErrorMessage": "The description section is missing.",
        "CompareProductsBannerCompareButtonText": "compare rewards",
        "CompareProductsBannerDescription": "Use our product comparison tool to choose the best pledge for you and make sure you've added everything you wanted.",
        "CompareProductsBannerHeader": "Looking for the best value?",
        "ProductAvailableOnlyInSetLabel": "N/A separately",
        "MiniWizardItemRemoved": "Item has been removed.",
        "MiniWizardProcessingPaymentInfo": "Your payment is being processed.",
        "MiniWizardProcessingPaymentLabel": "Processing payment",
        "HumanizeDateThreePartsLabel": "{0} {1} {2}",
        "ProjectOvertimeActivatedText": "Endgame will be activated! Campaign time will be extended.",
        "ProjectOvertimeButtonLabel": "Back now, extend the time",
        "ProjectOvertimeExplanationParagraph": "Each new pledge or upgrade resets the timer, extending the campaign's lifespan through the dedication of backers.",
        "ProjectOvertimeExtendedText1": "thanks to the support of",
        "ProjectOvertimeExtendedText2": "Campaign extended by",
        "ProjectOvertimeExtendedTimeLabel": "in extended time",
        "ProjectOvertimeFoundedLabel": "funded",
        "ProjectOvertimeRemainingText": "Final {0}. Back now to activate endgame!",
        "ProjectOvertimeStatisticsParagraph": "Campaign extended by {0} thanks to the support of {1}",
        "ProjectOvertimeTimerText": "Campaign ends in:",
        "ProjectOvertimeTitle": "Endgame is in progress",
        "ProjectOvertimeTooltipText": "This campaign is in the endgame phase. Each new backer or pledge upgrade resets the funding countdown to 10 minutes.",
        "ProjectOvertimeTooltipTriggerText": "Every backer resets the counter",
        "ProjectOvertimeButtonUpgradeLabel": "Upgrade your pledge",
        "ProjectOvertimeSummaryBoxTooltipText": "The scheduled end date extends by the duration of the endgame phase. Each new backer or their first pledge upgrade after the campaign's scheduled end resets the final countdown to {0}.",
        "ProjectOvertimeBackersEmptyStateText": "Awaiting the first backer to push the endgame forward."
    },
    "ProjectUpdates": {
        "DetailsGoBackActionLink": "go back to updates",
        "EmptyStateEditorParagraph": "You can add and manage them in project settings.",
        "EmptyStateEditorParagraphButton": "Add an update",
        "EmptyStateEditorTitle": "You have no updates yet",
        "EmptyStateParagraph": "Try coming back later.",
        "EmptyStateSearchParagraph": "Try searching in a different way.",
        "EmptyStateSearchTitle": "There are no matching updates.",
        "EmptyStateTitle": "There are no updates to show.",
        "FiltersAllUpdatesLabel": "All updates",
        "FiltersUserSpecificUpdatesLabel": "My updates",
        "LastModified": "last modified: {0}",
        "LikeAction": "like",
        "OriginallyPublished": "originally published: {0}",
        "PageHeader": "Updates",
        "PollStatusActiveLabel": "Active poll",
        "PollStatusFinishedLabel": "Finished poll",
        "ProjectUpdatesDisabledCreatorMessage": "Users cannot see the updates page of your project because it's currently disabled. Please contact us at business@gamefound.com to enable updates.",
        "ProjectUpdatesDisabledUserMessage": "The creator hasn't enabled the update section yet.",
        "SearchFieldActionLink": "advanced search",
        "SearchFieldDateFrom": "Search after",
        "SearchFieldDateTo": "Search before",
        "SearchFieldLabel": "Search for updates",
        "SearchFieldPlaceholder": "Start typing to search updates",
        "UpdateSequenceNumberLabel": "Update #{0}",
        "UpdatesPageNavbarLink": "Updates",
        "UpdateStatusDraftBadge": "Draft",
        "UpdateStatusDraftParagraph": "This update is not visible to users."
    },
    "ResetPassword": {
        "LinkSentFollowInstructionParagraph": "<strong>Please check your email and follow the instructions found in our message.</strong>",
        "LinkSentHeader": "Reset password link has been sent",
        "LinkSentParagraph": "If there is an active Gamefound account matching your email, we will send you a message with a link to a page where you can reset your If there is an active Gamefound account matching your email, we will send you a message with a link to a page where you can reset your password.",
        "NewPasswordFieldLabel": "New password",
        "PasswordChangedHeader": "Your password has been changed.",
        "PasswordChangedParagraph": "You can use your new password to log in {0}.",
        "RedirectToLogInLink": "here",
        "RepeatPasswordFieldLabel": "Repeat password",
        "ResetPasswordEmailFieldLabel": "email",
        "ResetPasswordHeader": "Reset your password",
        "ResetPasswordInvalidLinkInfo": "Could not complete password reset request. The link is either invalid or expired.",
        "ResetPasswordInvalidNewPasswordErrorInfo": "New password is not valid.",
        "ResetPasswordParagraph": "Please enter your email address. We will send you a link to a page where you can change your password.",
        "ResetPasswordSubmitButton": "Reset password",
        "ResetPasswordUnknownErrorInfo": "Could not activate account due to system error. Please try again.",
        "ResetPasswordValidLinkInfo": "Please provide and confirm your new password."
    },
    "Rewards": {
        "RewardsNavbarLink": "Rewards"
    },
    "Search": {
        "SearchFiltersGroupAgeLabel": "Age",
        "SearchFiltersGroupCategoryLabel": "Category",
        "SearchFiltersGroupCommunityLabel": "Community",
        "SearchFiltersGroupContributionLabel": "Contribution",
        "SearchFiltersGroupCreatorLabel": "Creator",
        "SearchFiltersGroupPlayableLabel": "Playable",
        "SearchFiltersGroupPlaytimeLabel": "Playtime",
        "SearchFiltersGroupSearchPhraseLabel": "Search phrase",
        "SearchFiltersGroupStatusLabel": "Status",
        "SearchFiltersHeader": "Filters",
        "SearchFiltersSortLabel": "Sort:",
        "SearchProjectPlayerAgeSearchTypeSixteenLabel": "16+ only",
        "SearchProjectPlayerCountSearchTypeFourAndMoreLabel": "4+ players",
        "SearchProjectPlayerCountSearchTypeOneLabel": "1 player",
        "SearchProjectPlayerCountSearchTypeThreeLabel": "3 players",
        "SearchProjectPlayerCountSearchTypeTwoLabel": "2 players",
        "SearchProjectPlayTimeSearchTypeHalfHourLabel": "up to 30 min",
        "SearchProjectPlayTimeSearchTypeMoreThanTwoHoursLabel": "120+ min",
        "SearchProjectPlayTimeSearchTypeOneHourLabel": "30-60 min",
        "SearchProjectPlayTimeSearchTypeTwoHoursLabel": "60-120 min",
        "SearchProjectProjectContributionTypeArchivedProjectsLabel": "Archived projects",
        "SearchProjectProjectContributionTypeBackerLabel": "Backed projects",
        "SearchProjectProjectContributionTypeFollowerLabel": "Followed projects",
        "SearchProjectProjectContributionTypeUnusedCreditsLabel": "Unused credits",
        "SearchProjectProjectPhaseSearchTypeCrowdfundingLabel": "Crowdfunding",
        "SearchProjectProjectPhaseSearchTypeLatePledgeLabel": "Late pledge",
        "SearchProjectProjectPhaseSearchTypePreorderLabel": "Preorder",
        "SearchProjectProjectPhaseSearchTypeRecentlyFundedLabel": "Funded",
        "SearchProjectProjectPhaseSearchTypeStoreLabel": "Store",
        "SearchProjectProjectPhaseSearchTypeUpcomingLabel": "Upcoming",
        "SearchProjectsFiltersAgeLabel": "Player age",
        "SearchProjectsFiltersBenefitLabel": "Benefits",
        "SearchProjectsFiltersBenefitStablePledgeLabel": "Stable pledge",
        "SearchProjectsFiltersBenefitStretchPayLabel": "Stretch pay",
        "SearchProjectsFiltersCategoryLabel": "Category",
        "SearchProjectsFiltersCategoryPlaceholder": "Select category...",
        "SearchProjectsFiltersCommunityLabel": "Community",
        "SearchProjectsFiltersContributionLabel": "Project contribution",
        "SearchProjectsFiltersCreatorLabel": "Creator",
        "SearchProjectsFiltersCreatorPlaceholder": "Start typing to see creator list...",
        "SearchProjectsFiltersPlayableLabel": "Playable by",
        "SearchProjectsFiltersPlaytimeLabel": "Playtime",
        "SearchProjectsFiltersSearchApplyButton": "Apply",
        "SearchProjectsFiltersSearchClearButton": "clear all",
        "SearchProjectsFiltersSearchPhraseLabel": "Search phrase",
        "SearchProjectsFiltersSearchPhrasePlaceholder": "Type something to search...",
        "SearchProjectsFiltersStatusLabel": "Project status",
        "SearchProjectSortApplyButton": "Apply",
        "SearchProjectSortCancelButton": "cancel",
        "SearchProjectSortGroupLabel": "Sort results",
        "SearchProjectSortTitle": "Sort",
        "SearchProjectsResultsEmptyStateParagraph": "You can try different filtering criteria to find what you're looking for.",
        "SearchProjectsResultsEmptyStateParagraphButton": "Change filters",
        "SearchProjectsResultsEmptyStateTitle": "There are no projects to show",
        "SearchProjectsResultsTitle": "Projects",
        "SearchProjectUserCommunityTypeBackedByCommunityLabel": "Backed by your community",
        "SearchProjectUserCommunityTypeFollowedByCommunityLabel": "Followed by your community"
    },
    "Spotlight": {
        "CrowdfundingCampaign24HoursLeftNotificationTitle": "24 hours left",
        "CrowdfundingCampaign24HoursLeftNotificationText": "{0}",
        "CrowdfundingCampaignFundedNotificationTitle": "Campaign is funded",
        "CrowdfundingCampaignFundedNotificationText": "{0}",
        "CrowdfundingCampaignStartScheduledNotificationTitle": "Launch date",
        "CrowdfundingCampaignStartScheduledNotificationText": "{0} has a starting date.",
        "CrowdfundingCampaignStartedNotificationTitle": "Campaign start",
        "CrowdfundingCampaignStartedNotificationText": "{0}",
        "CrowdfundingDraftPublishedNotificationTitle": "New project",
        "CrowdfundingDraftPublishedNotificationText": "{0}",
        "ExternalLatePledgePublishedNotificationTitle": "New Late Pledge",
        "ExternalLatePledgePublishedNotificationText": "{0}",
        "ExternalPledgeManagerPublishedNotificationTitle": "New Pledge Manager",
        "ExternalPledgeManagerPublishedNotificationText": "{0}",
        "GamefoundBlogPostPublishedNotificationTitle": "New blog post",
        "GamefoundBlogPostPublishedNotificationText": "{0}"
    },
    "SubscriptionManagement": {
        "GamefoundFeastFieldDescription": "Updates with exciting upcoming campaigns.",
        "GamefoundFeastFieldLabel": "Gamefound Feast",
        "NewsletterFieldDescription": "Updates with hand-picked projects on Gamefound. We're not going to spam you.",
        "NewsletterFieldLabel": "Newsletter",
        "PageHeader": "Communication preferences",
        "PageTitle": "Communication preferences",
        "PageIntroParagraph": "Choose the options for newsletter subscriptions and notifications about creators or projects.",
        "SystemMessagesFieldDescription": "Automatic emails such as confirmations, pledge status updates, etc. These are essential to fulfil your pledges and use Gamefound.",
        "SystemMessagesFieldLabel": "System messages",
        "CommentRepliesFieldDescription": "Notifications about a new reply to your comment.",
        "CommentRepliesFieldLabel": "Comment replies",
        "CommunityLifeFieldDescription": "Notifications about profiles you follow, newest comments, your new followers, etc.",
        "CommunityLifeFieldLabel": "Community life",
        "NotificationPreferenceNoNotificationsDisplayName": "No notifications",
        "NotificationPreferenceOnlyEmailDisplayName": "Email",
        "UserDigestFrequencyNeverDisplayName": "Never",
        "UserDigestFrequencyDailyDisplayName": "Daily",
        "UserDigestFrequencyEvery2DaysDisplayName": "Every 2 days",
        "UserDigestFrequencyWeeklyDisplayName": "Weekly",
        "CreatorNotificationsSectionHeader": "Creator",
        "CreatorDigestFieldDescription": "Regular notifications about activity in your projects.",
        "CreatorDigestFieldLabel": "Creator digest",
        "CreatorNewProjectLaunchFieldDescription": "Notifications about the launch of a new project run by this creator.",
        "CreatorNewProjectLaunchFieldLabel": "New project launch",
        "ProjectNotificationsSubsectionHeader": "Project",
        "GeneralProjectNotificationsFieldDescription": "Notifications about changes in the lifecycle of this project, e.g. campaign start or end.",
        "GeneralProjectNotificationsFieldLabel": "General",
        "UpdatesProjectNotificationsFieldDescription": "Notifications about an update published by the creator.",
        "UpdatesProjectNotificationsFieldLabel": "Updates",
        "SettingsSavedMessageText": "Subscription settings saved.",
        "InvalidTopicBadRequestMessageText": "Invalid notification topic.",
        "MissingCreatorIdBadRequestMessageText": "Missing creator ID.",
        "MissingPreferenceBadRequestMessageText": "Missing preference setting.",
        "MissingProjectIdBadRequestMessageText": "Missing project ID.",
        "NotificationPreferenceOnlyPushDisplayName": "Push",
        "NotificationOptionDefaultLabel": "Default",
        "ProjectMilestonesFieldLabel": "General",
        "ProjectMilestonesInputFieldDescription": "Notifications about changes in the lifecycle of this project, e.g. campaign start or end. You can override this setting for individual projects below.",
        "ProjectNotificationHeader": "Project communication",
        "ProjectUpdatesFieldLabel": "Updates",
        "ProjectUpdatesInputFieldDescription": "Notifications about an update published by the creator. You can override this setting for individual projects below.",
        "SystemNotificationHeader": "Platform communication",
        "WebPushActivateLabel": "Activate",
        "WebPushBlockedByBrowserParagraph": "Browser settings currently block push notifications. See our help center for how to enable them.",
        "WebPushEnableParagraph": "Keep up with the latest activities and updates on Gamefound by activating push notifications.",
        "WebPushHideLabel": "hide this message",
        "PushNotificationsDeviceTokenInvalidErrorMessage": "There is a problem with your device token."
    },
    "TermsOfService": {
        "BackersTermsOfServiceLinkTitle": "Gamefound Terms of Service for Backers",
        "CommunityGuidelinesLinkTitle": "Gamefound Community Guidelines",
        "CreatorsDigitalTermsOfServiceLinkTitle": "Gamefound Digital content Terms of Service for Creators",
        "CreatorsTermsOfServiceLinkTitle": "Gamefound Terms of Service for Creators",
        "CreatorsTermsOfServiceSectionTitle": "For Creators",
        "CrowdfundingAndPMsSectionTitle": "Crowdfunding & Pledge Managers",
        "GeneralTermsOfServiceLinkTitle": "Gamefound Terms of Service",
        "PageDescription": "Gamefound provides various kinds of service for backers and creators. Please refer to the below list of our service areas to find their respective regulatory documents.",
        "PageHeader": "Terms of Service",
        "PrivacyPolicyLinkTitle": "Gamefound Privacy Policy",
        "SiteUsageTermsSectionTitle": "Site usage",
        "StoresTermsOfServiceLinkTitle": "Gamefound Terms of Service for Stores",
        "StoresTermsOfServiceSectionTitle": "Stores"
    },
    "YourPledge": {
        "AddItemsButton": "Add items",
        "AddMoreButton": "Add more items",
        "BillingAddressHeader": "Billing address",
        "CancelOrderButton": "Cancel order",
        "CancelOrderModalHeader": "Cancel your order",
        "CancelOrderModalParagraph": "Do you want to cancel this order?",
        "CancelOrderSuccessToastMessage": "Your order has been canceled.",
        "CancelPaymentMethodChangeButton": "cancel",
        "CancelPledgeButton": "Cancel pledge",
        "CancelPledgeCFModalParagraph": "This will cancel your pledge. You won't be charged for it. Do you want to cancel this pledge?",
        "CancelPledgeModalHeader": "Cancel your pledge",
        "CancelPledgeModalParagraph": "Do you want to cancel this pledge?",
        "CancelPledgeSuccessToastLabel": "Success",
        "CancelPledgeSuccessToastMessage": "Your pledge has been canceled.",
        "CardPaymentMethodName": "Card payment",
        "CartTableDetailsHeader": "Details",
        "CartTableItemDetailsHeader": "Item details",
        "CartTableItemsAddedOnTitle": "Items added on {0}",
        "CartTableOriginalOrderTitle": "Original order",
        "CartTablePredefinedItemsTitle": "Pre-defined items from creator",
        "CartTablePriceHeader": "Price",
        "CartTableQuantityHeader": "Quantity",
        "CartTableQuantityShortHeader": "Qty",
        "CartTableSetContentLabel": "This product consists of:",
        "CartTableSubtotalRowLabel": "Subtotal",
        "CartTableTotalPriceHeader": "Total price",
        "CartTableTotalWeightHeader": "Total weight",
        "CartTableUnpaidItemsTitle": "Unpaid items",
        "CartTableYourItemsTitle": "Your items",
        "ChangePaymentMethodButton": "use another payment method",
        "CheckoutButton": "Check out",
        "CheckoutDisabledEmptyPledgeToast": "You can't continue with an empty pledge.",
        "CheckoutDisabledInvalidCartToast": "It seems you need to take some extra action(s) in your pledge. Please review your pledge before checking out.",
        "EditingPledgeSuccessToast": "You are editing your pledge.",
        "EditPledgeButton": "Edit pledge",
        "EditPledgeImportedItemsEditableInfoToast": "If you'd like to upgrade your pledge or change its content, you can remove items from your pledge and add the ones you like.",
        "EditPledgeImportedItemsLockedInfoToast": "These items are pre-defined and non-removable from your pledge!",
        "EditPledgeImportedItemsPartiallyLockedInfoToast": "You can remove some of the items added by creator to your pledge - only those with the item deletion button (red X within the circle). Other items are mandatory within your pledge.",
        "EmptyOrderButton": "Add products to your order",
        "EmptyOrderMessage": "Your order is empty. You can buy products in the Products tab.",
        "EmptyPledgeButton": "Add products to your pledge",
        "EmptyPledgeClosedProject": "Your pledge is empty. You can't add products to your pledge because the project is closed.",
        "EmptyPledgeFailedProject": "Your pledge is empty. You can't add products to your pledge because this project has failed.",
        "EmptyPledgeNoCredits": "Your pledge is empty. You can't add products to your pledge because you don't have available credits.",
        "EmptyPledgeProjectLaunch": "Your pledge is empty. You will be able to add products to a pledge after the project launch.",
        "EmptyPledgeProjectTab": "Your pledge is empty. You can add products to your pledge in the project tab.",
        "EmptyPledgeRemovedAllItems": "You have removed all products from your pledge.",
        "EmptyPledgeTitle": "Nothing to show here",
        "HideStretchPayScheduleHeader": "Hide Stretch Pay schedule",
        "InstallmentCollectionText": "First installment for your pledge will be collected within 24 hours after campaign's successful end.",
        "InstallmentCountText": "Your pledge will be split into <span class=\"_fw-b\">{0} equal installments.</span>",
        "InstallmentPlanAmountLabel": "Amount to pay",
        "InstallmentPlanApproximateConversionLabel": "approx.",
        "InstallmentPlanApproximateConversionTooltip": "Final price may differ according to bank exchange rates and conversion fees.",
        "InstallmentPlanPaymentDateLabel": "Payment date",
        "InstallmentPlanStatusLabel": "Status",
        "InstallmentPlanTotalPaidLabel": "Total paid",
        "InstallmentProgressAllPaid": "All installments paid",
        "InstallmentProgressCanceledTitle": "This installment plan has been finished",
        "InstallmentProgressNextTitle": "Next installment",
        "InstallmentProgressOverdueDescription": "We've encountered an error when trying to collect funds for this installment. Make sure your payment method is valid and has enough funds before you retry.",
        "InstallmentProgressOverdueTitle": "Overdue installment",
        "InstallmentXOfYLabel": "Installment {0} of {1}",
        "InvoicesLabel": "Invoices:",
        "LocationChangedSuccessToast": "Shipping country saved.",
        "LocationChangedWithShippingModelAndTaxesSuccessToast": "Shipping country saved. Proper tax and shipping cost were added to your pledge.",
        "LocationChangedWithShippingModelSuccessToast": "Shipping country saved. Shipping cost was added to your pledge.",
        "LocationChangedWithTaxesSuccessToast": "Shipping country saved. Proper tax was added to your pledge.",
        "ManageShippingButton": "Manage shipping",
        "NoInvoicesText": "There are no invoices for this order.",
        "NoNewOrdersInfoToast": "This project does not accept any more pledges at this time.",
        "NotesLabel": "Notes:",
        "OrderIDHeader": "Order ID: {0}",
        "OrderPaymentStatusLabel": "Payment status:",
        "OrderStageLabel": "Stage: ",
        "OrderStageNextHintTitle": "Next:",
        "OrderSummaryCreditDiscountLabel": "Credit discount:",
        "OrderSummaryHeader": "Order summary",
        "OrderSummaryNetShippingCostLabel": "Shipping cost (net):",
        "OrderSummaryNetSubtotalLabel": "Subtotal (net):",
        "OrderSummaryNetTotalDiscountsLabel": "Total discounts (net):",
        "OrderSummaryOverchargedLabel": "Overcharged:",
        "OrderSummaryRefundedLabel": "Refunded:",
        "OrderSummaryRemainingInstallmentsLabel": "Remaining installments:",
        "OrderSummaryShippingCostLabel": "Shipping cost:",
        "OrderSummarySubtotalLabel": "Subtotal:",
        "OrderSummaryTaxLabel": "Tax:",
        "OrderSummaryTaxWithNameLabel": "Tax ({0}):",
        "OrderSummaryToPayLabel": "To pay:",
        "OrderSummaryTotalDiscountsLabel": "Total discounts:",
        "OrderSummaryTotalPaidLabel": "Total paid:",
        "OrderSummaryTotalPledgedLabel": "Total pledged:",
        "OrderSummaryTotalVatDeductionLabel": "Total VAT deduction:",
        "PaymentHistoryActionButton": "Payment history",
        "PaymentHistoryModalEmptyStateText": "There are no payments to show.",
        "PledgePaymentHistoryModalHeader": "Payment history for pledge {0}",
        "PaymentMethodInfoInstantPaymentText": "This payment method was used to settle your payment. If you want to cancel your pledge, {0} by the campaign's end to get a full refund.",
        "PaymentMethodInfoInstantPaymentTextLink": "contact the creator",
        "PaymentMethodInfoSinglePaymentText": "This payment method will be used to settle your payment when the campaign ends.",
        "PaymentMethodInfoWithInstallmentsText": "This payment method will be used to settle your installments.",
        "PaymentMethodSectionHeader": "Payment method",
        "PledgeDateSubheader": "Date: {0}",
        "PledgeIDHeader": "Pledge ID: {0}",
        "PledgeModifiedSuccessToast": "Your pledge has been modified.",
        "PreviousOrdersLabel": "Previous orders",
        "PreviousPledgesLabel": "Previous pledges",
        "ReCaptchaChangeDeferredPaymentVerificationFailedErrorMessage": "We've noticed some unusual activity. You can't save changes.",
        "ReCaptchaInvalidTokenErrorMessage": "Invalid reCaptcha token.",
        "ReCaptchaMissingTokenErrorMessage": "Missing reCaptcha token.",
        "ResumePaymentButton": "Resume payment",
        "RetryInstallmentButton": "Retry payment",
        "RetryInstallmentSuccessToast": "The installment has been paid.",
        "RevertChangesButton": "Revert changes",
        "RevertChangesButtonLong": "revert to original pledge",
        "RevertChangesSuccessToast": "You have successfully reverted the changes in your pledge.",
        "RevertOriginalActionButton": "Revert to original",
        "SavePaymentMethodChangeButton": "Save",
        "SetShippingButton": "Set shipping",
        "ShippingAddressHeader": "Shipping address",
        "ShippingInfoLabel": "Shipping:",
        "ShippingInfoNotRequiredNotificationText": "Shipping details and fees aren't collected at this stage. The project creator will ask you to settle the shipping cost at a later date.",
        "ShippingInfoPartiallyCollectedNotificationText": "Shipping fee has been collected. You will be asked to provide your precise shipping address during the pledge manager stage.",
        "ShippingInfoRequiredNotificationText": "To receive your reward, you need to add shipping information and choose the shipping method.",
        "ShippingInfoText": "{0} (estimated start of delivery: {1})",
        "ShippingSectionHeader": "Shipping",
        "ShowMoreButton": "show more",
        "StageHistoryActionButton": "Stage history",
        "StageHistoryModalHeader": "Stage history for order {0}",
        "StretchPaySectionHeader": "Stretch Pay (installments)",
        "ViewStretchPayScheduleHeader": "View Stretch Pay schedule",
        "YourCartPageTitle": "Your cart",
        "YourOrderPageTitle": "Your current order",
        "YourOrdersNavbarLink": "Your orders",
        "YourPledgeNavbarLink": "Your pledge",
        "YourPledgePageTitle": "Your current pledge",
        "YourPledgeRecommendationTitle": "Other users added",
        "OrderPaymentHistoryModalHeader": "Payment history for order {0}",
        "RemainingInstallmentsAmountToPayLabel": "Remaining amount",
        "RemainingInstallmentsBoxTitle": "Stretch Pay progress",
        "RemainingInstallmentsModalHeader": "Pay the remaining amount",
        "RemainingInstallmentsModalText": "You're about to pay all installments in full.",
        "RemainingInstallmentsPaymentConfirmationText": "Are you sure you want to pay the remaining {0}",
        "RemainingInstallmentsPayRemainingButton": "Pay remaining {0}",
        "RemainingInstallmentsProgressBarTitle": "{0} of {1} installments paid",
        "RemainingInstallmentsTotalPaidLabel": "Total paid",
        "YourPledgeEditOrderItemModalTitle": "Edit order item",
        "InvalidProductQuantityMessage": "Invalid quantity. Please remove this item.",
        "OneProductCopyLeftInStockMessage": "There is only one product left in stock.",
        "ProductCopiesPerUserLimitExceededMessage": "You cannot purchase any more copies of this product.",
        "ProductOutOfStockMessage": "This product is no longer available. It's out of stock.",
        "ProductQuantityExceededMessage": "You can't add this many items to your pledge.",
        "InvalidSetItemsMessage": "One or more items from the set require an action.",
        "CartTableOrderItemHideSetItemsLabel": "hide {0} ({1})",
        "CartTableOrderItemShowSetItemsLabel": "show {0} ({1})",
        "CartTableOrderItemTaxValueLabel": "{0} (tax)",
        "YourPledgeRemoveOrderItemConfirmationDescription": "Are you sure you want to remove this item?",
        "YourPledgeRemoveOrderItemConfirmationTitle": "Remove order item",
        "OneProductCopyAllowedForUserMessage": "You are only allowed to order one copy of this product.",
        "ProductCopiesAllowedForUserMessage": "You are only allowed to order {0} copies of this product.",
        "ProductCopiesLeftInStockMessage": "There are only {0} copies of this product left in stock.",
        "YourPledgeCartTableOriginalOrderSectionHeader": "Original order",
        "YourPledgeCartTableUnsubmittedOrderSectionHeader": "Unsubmitted {0}",
        "YourPledgePluralizedProductLabel": "product|products",
        "IncorrectQuantityErrorMessage": "Incorrect quantity was selected.",
        "InvalidOrderItemOptions": "Invalid options were selected.",
        "OrderItemNotFoundErrorMessage": "Order item was not found.",
        "OrderItemOptionsCannotBeChangedErrorMessage": "Cannot change options for this order item.",
        "OrderItemQuantityCannotBeChangedErrorMessage": "Cannot change quantity for this order item.",
        "InstallmentPlanNotFoundError": "Installment plan not found.",
        "InvalidSetItemActionRequired": "There is an issue with one or more items from the set. {0} to resolve them.",
        "InvalidSetItemActionRequiredLink": "Click here",
        "InvalidProductSetValidationMessage": "There is an issue with one or more items from the set.",
        "TooManyCopiesRemoveExcessMessage": "Too many copies of an item were added to the pledge. Remove excess items.",
        "YourPledgeInvalidValidationCallToActionMessage": "Solve the issue.",
        "YourPledgeAbortPaymentButton": "Abort payment",
        "YourPledgeAbortPaymentDescription": "Your payment is taking longer than expected (usually no more than {0} for the selected payment method). You can wait or abort it.",
        "YourPledgePaymentHistoryLabel": "view payment history",
        "YourPledgePluralizedDayLabel": "day|days",
        "YourPledgeProcessingPaymentDescription": "Your payment is being processed. For the chosen payment method, this may take up to {0}. {1}",
        "HumanizeDatePluralizedMinute": "minute|minutes",
        "YourPledgeAbortPaymentConfirmationButton": "Abort Payment",
        "YourPledgeAbortPaymentModalDescription": "If you confirm, we'll stop processing your payment. However, in rare cases, the payment may still be collected. If this happens, you'll need to contact the creator to request a refund for your overcharged pledge.",
        "YourPledgeAbortPaymentModalTitle": "Are you sure you want to abort the payment?",
        "YourPledgeAbortPaymentSuccessToast": "The payment has been successfully aborted.",
        "EditingPledgeLabel": "Editing the pledge",
        "YourPledgeAbortPaymentModalIssueWithPaymentDescription": "There was an issue processing your payment. We recommend aborting the transaction and trying again."
    }
};
    if (typeof window !== 'undefined' && !window.__RESOURCES__) {
        window.__RESOURCES__ = resources;
    }
    export default resources;
    export const rawResources = resources;
