var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_mt-5" },
    [
      _c("box", { staticClass: "_bxsh--none", attrs: { slim: "" } }, [
        !_vm.isUsefullness
          ? _c("div", { staticClass: "_flexcol _ai-c _gap-2" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.Resources.Faq.FeedbackFormParagraph)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "_flex _gap-2" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "_ttr",
                      attrs: {
                        theme: "searchPrimary",
                        width: "compact",
                        weight: "thin",
                        shadow: "tight",
                        "data-qa": "faq-feedback-actions-button:Yes",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.markSectionUsefulness(_vm.section, true)
                        },
                      },
                    },
                    [
                      _c("base-icon", {
                        staticClass: "_mr-1 _tc--accent",
                        attrs: { name: "thumbs-up", size: "lg" },
                      }),
                      _vm._v(" "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.Resources.Common.ModalYesButton)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "base-button",
                    {
                      staticClass: "_ttr",
                      attrs: {
                        theme: "searchPrimary",
                        width: "compact",
                        weight: "thin",
                        shadow: "tight",
                        "data-qa": "faq-feedback-actions-button:No",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.markSectionUsefulness(_vm.section, false)
                        },
                      },
                    },
                    [
                      _c("base-icon", {
                        staticClass: "_mr-1 _tc--accent",
                        attrs: { name: "thumbs-down", size: "lg" },
                      }),
                      _vm._v(" "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.Resources.Common.ModalNoButton)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _c(
              "div",
              { staticClass: "_flex _jc-c _gap-1" },
              [
                _vm.section.usefullness
                  ? [
                      _c("base-icon", { attrs: { name: "face-smile" } }),
                      _vm._v(" "),
                      _vm.section.usefullness === true
                        ? _c(
                            "span",
                            {
                              attrs: {
                                "data-qa": "faq-feedback-actions-helpful:Yes",
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.Resources.format(
                                      _vm.Resources.Faq
                                        .PositiveFeedbackSubmittedParagraph,
                                      _vm.projectName
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  : [
                      _c("base-icon", { attrs: { name: "heart-broken" } }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          attrs: {
                            "data-qa": "faq-feedback-actions-helpful:No",
                          },
                        },
                        [
                          _c(
                            "localize",
                            {
                              attrs: {
                                resource:
                                  _vm.Resources.Faq
                                    .NegativeFeedbackSubmittedParagraph,
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "gfu-link gfu-link--accent-plain",
                                  attrs: {
                                    href: "",
                                    "format-key": "0",
                                    "data-qa":
                                      "faq-feedback-actions-contact-link",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.onSectionNegativeFeedbackClick(
                                        _vm.section
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.Resources.Common.ClickHereButton
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
              ],
              2
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }