var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-button",
    {
      staticClass: "_flexbtwn gfu-1of1 _gap-2",
      attrs: {
        tag: "a",
        href: _vm.backProjectUrl,
        theme: _vm.theme,
        animation: _vm.buttonAnimationType,
        "data-qa": "back-project-button",
      },
      on: { click: _vm.onClick },
    },
    [
      _c("base-icon", {
        attrs: { name: "gamefound-flat-icon", "icon-namespace": "ga" },
      }),
      _vm._v(" "),
      _vm._t("default", function () {
        return [_vm._v("\n        " + _vm._s(_vm.title) + "\n    ")]
      }),
      _vm._v(" "),
      !_vm.isStacked
        ? _c("base-icon", {
            staticClass: "is-invisible",
            attrs: { name: "gamefound-flat-icon", "icon-namespace": "ga" },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }