var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "_pos-r _bgc--white gfu-background-swept _pa-o _gap-2 _flex _ai-c _rounded--common _bxsh--base",
    },
    [
      _c("skeleton-image", { attrs: { size: 80 } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "_flexcol _gap-1 _grow-1" },
        [
          _c(
            "div",
            { staticClass: "_flex _jc-sb" },
            [
              _c("skeleton-line", {
                staticClass: "_flex-basis-60",
                attrs: { height: 10 },
              }),
              _vm._v(" "),
              _c("skeleton-line", {
                staticClass: "_flex-basis-33",
                attrs: { height: 10 },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("skeleton-line", { attrs: { height: 11 } }),
          _vm._v(" "),
          _c("skeleton-line", { attrs: { height: 11 } }),
          _vm._v(" "),
          _c("skeleton-line", { attrs: { height: 12 } }),
          _vm._v(" "),
          _c("skeleton-line", { attrs: { height: 12 } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }