import { loadScript } from '@/Utils/ScriptUtility';
import { reCaptchaProvider, externalScripts } from '@/Config';

const reCaptchaUrl = externalScripts.reCaptchaUrl;
const siteKey = reCaptchaProvider.siteKey;
const isEnabled = reCaptchaProvider.isEnabled;

export default class ReCaptchaProvider {
    isInitialized = false;

    async initAsync() {
        if (isEnabled) {
            try {
                const result = await loadScript(`${ reCaptchaUrl }?render=${ siteKey }`);
                if (result) {
                    this.isInitialized = true;
                }
            }
            catch (error) {
                throw new Error(error);
            }
        }

        return this.isInitialized;
    }

    async getTokenAsync(action) {
        return await new Promise((resolve) => {
            try {
                window.grecaptcha.enterprise.ready(async () => {
                    const recaptchaToken = await window.grecaptcha.enterprise.execute(siteKey, { action });
                    resolve(recaptchaToken);
                });
            }
            catch (err) {
                throw new Error(err);
            }
        });
    }
}
