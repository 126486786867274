var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "gfu-layout-wrapper gfu-layout-wrapper--extranarrow gfu-4of5--l gfu-3of5--xl",
    },
    [
      _c(
        "h2",
        {
          ref: "anchor",
          staticClass: "gfu-hd gfu-hd--h2 gfu-heading--top-divider _mb-6",
          attrs: {
            "data-qa": `project-description-section-title:${_vm.header}`,
          },
        },
        [
          _vm._v("\n        " + _vm._s(_vm.header) + "\n        "),
          _c(
            "span",
            {
              staticClass: "_fw-n",
              attrs: { "data-qa": "project-stretch-goals-unlocked" },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.unlockedStretchGoalsText) +
                  "\n        "
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { attrs: { "data-qa": "project-stretch-goals-box" } }, [
        _c(
          "div",
          {
            staticClass: "_mb-6",
            attrs: { "data-qa": "project-stretch-goals-list:Locked" },
          },
          [
            _c("h3", { staticClass: "gfu-hd gfu-hd--h3 _ttu _mb-3" }, [
              _vm._v(_vm._s(_vm.Resources.ProjectHome.StretchGoalsNextHeader)),
            ]),
            _vm._v(" "),
            _c("project-stretch-goals-list", {
              attrs: {
                "stretch-goals": _vm.lockedStretchGoals,
                "currency-symbol": _vm.currencySymbol,
                "placeholder-text": "No new stretch goals to unlock.",
                "data-qa": "project-stretch-goals-list:PlaceholderText",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.shouldDisplayAlreadyUnlockedSection
          ? _c(
              "div",
              { attrs: { "data-qa": "project-stretch-goals-list:Unlocked" } },
              [
                _c("h3", { staticClass: "gfu-hd gfu-hd--h3 _ttu _mb-3" }, [
                  _vm._v(
                    _vm._s(_vm.Resources.ProjectHome.StretchGoalsUnlockedHeader)
                  ),
                ]),
                _vm._v(" "),
                _c("project-stretch-goals-list", {
                  attrs: {
                    "stretch-goals": _vm.unlockedStretchGoals,
                    "currency-symbol": _vm.currencySymbol,
                    "placeholder-text": `No stretch goals unlocked yet.`,
                    "data-qa": "project-stretch-goals-list:PlaceholderText",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }