var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasCategoriesProps
    ? _c(
        "div",
        {
          staticClass:
            "gfu-layout-wrapper gfu-layout-wrapper--extranarrow gfu-4of5--l gfu-3of5--xl",
          attrs: { "data-qa": `project-description-section:${_vm.sectionID}` },
        },
        [
          !_vm.allowAddonOnlyPledges
            ? _c(
                "div",
                { staticClass: "gfu-bt gfu-bt--b2 _mb-6" },
                [
                  _c("base-icon", {
                    staticClass: "_mr-1",
                    attrs: { name: "info" },
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.Resources.ProjectHome.AddonsRestrictionSubheader
                      ) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "gfu-heading--top-divider" }),
          _vm._v(" "),
          _vm._l(_vm.categoriesProps, function (category) {
            return _c(
              "div",
              { key: category.categoryId },
              [
                _c(
                  category.component,
                  {
                    ref: "addonsSubCategoryAnchors",
                    refInFor: true,
                    tag: "component",
                    class: category.class,
                    attrs: {
                      "data-qa": `project-description-section-title:${category.name}`,
                    },
                  },
                  [
                    !category.isChildCategory
                      ? _c("span", [_vm._v(_vm._s(category.name))])
                      : _c(
                          "span",
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(category.parentName) +
                                "\n                "
                            ),
                            _c("base-icon", {
                              staticClass: "_tc--accent _mx-h",
                              attrs: { name: "chevron-right", size: "xs" },
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(category.name))]),
                          ],
                          1
                        ),
                    _vm._v(" "),
                    category.infoText
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "gfu-heading__icon gfu-heading__icon--primary gfu-heading__icon--small",
                          },
                          [
                            _c("tooltip", {
                              attrs: {
                                text: category.infoText,
                                "icon-name": "info",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c("optional-text", {
                  attrs: {
                    content: category.description,
                    "is-article": true,
                    "is-rich-text": true,
                    "data-qa": "project-section-addons:AddonsMoreInfo",
                  },
                }),
                _vm._v(" "),
                _c("crowdfunding-addon-card-list", {
                  attrs: {
                    products: category.products,
                    "checkout-url": _vm.checkoutUrl,
                  },
                }),
              ],
              1
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }