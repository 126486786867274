var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("box", { staticClass: "_flexcol _gap-7" }, [
    _c(
      "div",
      { staticClass: "_grid _grid-cols-2--m _gap-7 _ai-c _js-c" },
      [
        _c("base-image", {
          staticClass: "_js-c",
          attrs: {
            src: "/content/images/performance-marketing/performance-marketing-start.svg",
            alt: "Your campaign deserves best-in-class marketing",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "_flexcol _gap-3" }, [
          _c("h3", { staticClass: "gfu-hd gfu-hd--h2" }, [
            _vm._v("Your campaign deserves best-in-class marketing"),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "\n                Adfound empowers creators to launch quick and easy ad campaigns on Meta, leveraging years of expertise and well-established ad accounts that were previously exclusive to select companies. Using advanced technology, we optimize campaigns 24/7 to ensure your budget is utilized as efficiently as possible.\n            "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "processing-button",
                {
                  attrs: {
                    "is-processing": _vm.isProcessing,
                    theme: "accent",
                    "data-qa": "try-adfound-button",
                  },
                  on: { click: _vm.onTryForFree },
                },
                [_vm._v("\n                    Try Adfound\n                ")]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "_flexcol _gap-3" }, [
      _c("h3", { staticClass: "gfu-hd gfu-hd--h2" }, [_vm._v("Why Adfound?")]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "_grid _grid-col-1 _grid-cols-2--m _grid-cols-3--l _gap-6",
        },
        _vm._l(_vm.benefits, function (benefit) {
          return _c(
            "div",
            { key: benefit.title, staticClass: "_flexcol _gap-2 _tac" },
            [
              _c(
                "div",
                { staticClass: "_px-3" },
                [
                  _c("base-icon", {
                    staticClass: "_tc--accent",
                    attrs: { name: benefit.icon, size: "3x" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("h5", { staticClass: "gfu-hd gfu-hd--h4" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(benefit.title) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "\n                    " +
                    _vm._s(benefit.description) +
                    "\n                "
                ),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "_flexcol _gap-2" },
      [
        _c("h3", { staticClass: "gfu-hd gfu-hd--h2 _ma-0" }, [
          _vm._v("Questions & Answers"),
        ]),
        _vm._v(" "),
        _vm._l(_vm.questions, function (question) {
          return _c("faq-box", {
            key: question.title,
            attrs: {
              nested: "",
              title: question.title,
              description: question.description,
              "is-initially-collapsed": "",
            },
          })
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }