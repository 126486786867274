import { getToken, getMessaging, isSupported } from 'firebase/messaging';
import { pushNotifications } from '@/Config';

export const browserNotificationsHelp = {
    ['Chrome']: 'https://support.google.com/chrome/answer/3220216?hl=en&co=GENIE.Platform%3DDesktop&sjid=1922211121441663234-EU',
    ['Firefox']: 'https://support.mozilla.org/en-US/kb/push-notifications-firefox',
    ['Safari']: 'https://support.apple.com/en-hk/guide/safari/sfri40734/17.0/mac/14.0',
    ['Samsung Internet']: 'https://www.samsung.com/hk_en/support/mobile-devices/deactivate-notifications-from-websites-on-my-samsung-phone/',
    ['Edge']: 'https://support.microsoft.com/en-us/microsoft-edge/manage-website-notifications-in-microsoft-edge-0c555609-5bf2-479d-a59d-fb30a0b80b2b',
    ['Other']: 'https://help.gamefound.com/article/385-how-do-i-enable-push-notifications-on-my-browser?',
};

let messaging = null;

export const states = {
    default: 'default',
    denied: 'denied',
    granted: 'granted',
    unauthorized: 'unauthorized',
};

export const isBrowserSupported = async () => {
    const checkForSupport = await isSupported();
    if (checkForSupport) {
        messaging = getMessaging();
    }
    return checkForSupport;
};

export const getPermission = async () => {
    const permission = await Notification.requestPermission();

    if (permission === states.granted) {
        return navigator.serviceWorker.ready
            .then((registration) => getToken(messaging, { vapidKey: pushNotifications.vapidKey, serviceWorkerRegistration: registration }))
            .then(currentToken => currentToken)
            .catch(() => undefined);
    }
    else if (permission === states.denied) {
        return false;
    }
};

export function iOSPushPermissionRequest() {
    window.webkit.messageHandlers['push-permission-request'].postMessage('push-permission-request');
}

export function getIOSPushToken() {
    window.webkit.messageHandlers['push-token'].postMessage('push-token');
}

export function iOSPushCapability() {
    return window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers['push-permission-request'] && window.webkit.messageHandlers['push-permission-state'];
}

export function webPushCapability() {
    return 'PushManager' in window && 'serviceWorker' in navigator && 'permissions' in navigator && 'Notification' in window;
}

export function getIOSPushPermissionState() {
    window.webkit.messageHandlers['push-permission-state'].postMessage('push-permission-state');
}