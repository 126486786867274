var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-layout__main gfu-layout__main--extranarrow _my-6" },
    [
      _c("div", { staticClass: "gfu-content-head" }, [
        _c(
          "h2",
          {
            staticClass: "gfu-hd gfu-hd--h1",
            attrs: { "data-qa": "page-title" },
          },
          [_vm._v(_vm._s(_vm.Resources.Faq.FaqPageHeader))]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-box _mt-3 _mb-6 _flexcol _gap-2" },
        [
          _vm.hasQuestions
            ? [
                _c(
                  "div",
                  {
                    staticClass: "gfu-filters",
                    attrs: { "data-qa": "faq-search" },
                  },
                  [
                    _c("text-field", {
                      staticClass: "gfu-flex__item--grow _mt-0",
                      attrs: {
                        "display-name": _vm.Resources.Faq.SearchFaqTopicsLabel,
                        placeholder:
                          _vm.Resources.Faq.SearchFaqTopicsPlaceholder,
                        "auto-complete": "off",
                      },
                      model: {
                        value: _vm.searchTerm,
                        callback: function ($$v) {
                          _vm.searchTerm = $$v
                        },
                        expression: "searchTerm",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.formattedFaqSections, function (question) {
                  return _c(
                    "div",
                    { key: question.projectFaqSectionID },
                    [
                      _c(
                        "faq-box",
                        {
                          attrs: {
                            title: question.header,
                            description: question.content,
                            "search-term": _vm.searchTerm,
                            "is-initially-collapsed": question.isCollapsed,
                            "data-qa": `question-id:${question.projectFaqSectionID}`,
                          },
                        },
                        [
                          question.editedAt
                            ? _c("div", { staticClass: "_tc--light _mt-3" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.Resources.Common.LastUpdatedAtLabel
                                    ) +
                                    " " +
                                    _vm._s(question.editedAtDateFormatted) +
                                    "\n                    "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("faq-feedback-actions", {
                            attrs: {
                              section: question,
                              "project-name": _vm.projectName,
                            },
                            on: {
                              "negative-feedback-click":
                                _vm.onNegativeFeedbackClick,
                              "usefulness-marked": _vm.onUsefulnessMarked,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
              ]
            : _c(
                "div",
                {
                  staticClass: "gfu-issues-empty-result _tac",
                  attrs: { "data-qa": "faq-page-no-content" },
                },
                [
                  _c(
                    "empty-state",
                    {
                      attrs: {
                        title: _vm.Resources.Faq.FaqUserEmptyStateTitle,
                        description:
                          _vm.Resources.Faq.FaqUserEmptyStateParagraph,
                        "data-qa": "empty-faq-customer",
                      },
                    },
                    [
                      _vm.isNotCrowdfundingDraftPhase
                        ? _c(
                            "base-link",
                            {
                              staticClass:
                                "gfu-btn gfu-btn--accent gfu-btn--soft gfu-btn--wide",
                              attrs: {
                                url: _vm.topicSelectionContactUrl,
                                "data-qa": "faq-page-button:AskTheCreator",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.Resources.Faq.FaqUserEmptyStateButton
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }