export const injectTypes = {
    projectContext: 'projectContext',
    userContext: 'userContext',
    loadUserContext: 'loadUserContext',
    availableProjects: 'availableProjects',
    loadAvailableProjects: 'loadAvailableProjects',
    availableCreators: 'availableCreators',
    applyFilters: 'applyFilters',
    refreshProjectContext: 'refreshProjectContext',
    loadAssetGroup: 'loadAssetGroup',
    // ViewProcessing
    startViewProcessing: 'startViewProcessing',
    stopViewProcessing: 'stopViewProcessing',
    withViewProcessing: 'withViewProcessing',
    isProcessingView: 'isProcessingView',
    // Adyen Payment Configuration
    adyenPaymentContext: 'adyenPaymentContext',
    // Project Performance Marketing
    performanceMarketingUserApiKey: 'performanceMarketingUserApiKey',
};