import { defineStore } from 'pinia';
import { useStretchGoalsStore } from '@/Stores/Web/stretchGoalsStore.js';
import { campaignEndgameStatuses } from '@/Models';
import InitialState from '@/InitialState';

const crowdfundingStatistics = InitialState.projectState?.statistics || {
    backersCount: null,
    backersCountOvertime: null,
    campaignEnd: null,
    campaignDay: null,
    campaignOutcome: null,
    currentStretchGoalID: null,
    fundsGatheredOvertime: null,
    fundedAt: null,
    fundsGathered: null,
    totalBackersCount: null,
    totalFundsGathered: null,
    userCampaingOvertimeInfos: [],
};

const overtimeStatistics = InitialState.projectState?.endgameState || {
    campaignEndgameStatus: null,
    userCampaignOvertimeInfoModels: [],
};

const projectState = {
    ...crowdfundingStatistics,
    ...overtimeStatistics,
};

export const useCrowdfundingStatisticsStore = defineStore({
    id: 'crowdfundingStatistics',
    state: () => ({
        crowdfundingProjectStatistics: projectState,
    }),
    actions: {
        updateProjectStatistics(projectStateStatistics) {
            if (!!this.crowdfundingProjectStatistics.foundedAt && projectStateStatistics.foundedAt === null) {
                projectStateStatistics.foundedAt = this.crowdfundingProjectStatistics.foundedAt;
            }
            this.crowdfundingProjectStatistics = projectStateStatistics;
            const stretchGoalsStore = useStretchGoalsStore();

            if (stretchGoalsStore.hasStretchGoals) {
                stretchGoalsStore.updateStretchGoalsProgress(projectStateStatistics);
            }
        },
    },
    getters: {
        foundedInSeconds() {
            const { fundedAt } = this.crowdfundingProjectStatistics || {};
            const { campaignStart } = InitialState.projectContext?.project || {};

            if (!campaignStart || !fundedAt) {
                return null;
            }

            const fundedAtDate = new Date(fundedAt);
            const campaignStartDate = new Date(campaignStart);

            if (fundedAtDate - campaignStartDate < 0) {
                // This can only happen when manually changing project phase
                return null;
            }

            return (fundedAtDate - campaignStartDate) / 1000;
        },
        isCrowdfundingOvertimeActive() {
            const { campaignEndgameStatus } = this.crowdfundingProjectStatistics || {};

            return campaignEndgameStatus === campaignEndgameStatuses.endgameActive;
        },
        isCrowdfundingOvertimePending() {
            const { campaignEndgameStatus } = this.crowdfundingProjectStatistics || {};

            return campaignEndgameStatus === campaignEndgameStatuses.endgamePending;
        },
    },
});