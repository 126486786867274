var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "_pos-r _bgc--white _rounded--common gfu-background-swept",
      attrs: { "data-qa": "project-card-skeleton" },
    },
    [
      _c("div", { staticClass: "_flexrow" }, [
        _c("div", { staticClass: "_pa-3 _grow-1" }, [_c("skeleton-image")], 1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "_flexcol _pa-3 _gap-2 _grow-1" },
          [
            _c("skeleton-line", { attrs: { height: 28 } }),
            _vm._v(" "),
            _c("skeleton-line"),
            _vm._v(" "),
            _c("skeleton-line"),
            _vm._v(" "),
            _c("skeleton-line"),
            _vm._v(" "),
            _c("div", { staticClass: "_grow-1" }),
            _vm._v(" "),
            _c("skeleton-button"),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }