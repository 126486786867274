var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("main", { staticClass: "gfu-layout-wrapper" }, [
    _c("div", { staticClass: "gfu-grid gfu-grid--gutter _flex" }, [
      _c(
        "div",
        {
          staticClass: "gfu-grid__cell gfu-1of3--l gfu-4of6--m gfu-1of1 _mx-a",
        },
        [
          _c("div", { staticClass: "gfu-box _my-3" }, [
            _c(
              "h2",
              {
                staticClass:
                  "gfu-heading gfu-heading--soft gfu-box__title gfu-box__title--success",
                attrs: { "data-qa": "account-submitted" },
              },
              [
                _vm._v(
                  "\n                    You are now a Gamefound creator!\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "_tac" },
              [
                _c("p", { staticClass: "_mb-3 _pt-1" }, [
                  _vm._v(
                    "\n                        Your creator profile is ready to use.\n                    "
                  ),
                ]),
                _vm._v(" "),
                _vm.creatorUrlName
                  ? [
                      _c(
                        "p",
                        { staticClass: "_mb-3 _pt-1" },
                        [
                          _c(
                            "base-button",
                            {
                              attrs: {
                                tag: "a",
                                width: "wide",
                                href: _vm.creatorProjectUrl,
                                "data-qa": "create-first-project",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                Create your first project\n                            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "_mb-3" }, [_vm._v("or")]),
                      _vm._v(" "),
                      _c("p", { staticClass: "_mb-3" }, [
                        _c(
                          "a",
                          {
                            staticClass: "gfu-link gfu-link--accent",
                            attrs: {
                              href: _vm.creatorSettingsUrl,
                              "data-qa": "go-to-creator-settings",
                            },
                          },
                          [
                            _vm._v(
                              "\n                                go to your creator settings\n                            "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }