var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-countdown-circle" }, [
    _c(
      "svg",
      {
        staticStyle: { transform: "rotateY(180deg)" },
        attrs: { width: _vm.size, height: _vm.size },
      },
      [
        _c("circle", {
          attrs: {
            cx: _vm.circleCenter.x,
            cy: _vm.circleCenter.y,
            r: _vm.outerCircleRadius,
            fill: "rgba(0, 0, 0, 0.5)",
            stroke: "none",
          },
        }),
        _vm._v(" "),
        _c("circle", {
          staticClass: "gfu-countdown-circle__progress",
          attrs: {
            cx: _vm.circleCenter.x,
            cy: _vm.circleCenter.y,
            r: _vm.innerCircleRadius,
            fill: "none",
            stroke: _vm.strokeColor,
            "stroke-width": _vm.strokeWidth,
            "stroke-dasharray": _vm.strokeDashArray,
            "stroke-dashoffset": _vm.strokeOffset,
            "stroke-linecap": _vm.strokeLinecap,
            transform: `rotate(-90 ${_vm.circleCenter.x} ${_vm.circleCenter.y})`,
          },
        }),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "gfu-countdown-circle__time",
        attrs: { "data-qa": "countdown-circle-time" },
      },
      [
        _vm._t("default", function () {
          return [_vm._v(_vm._s(_vm.formattedTime))]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }