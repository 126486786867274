var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_pos-r _bgc--white _rounded--common gfu-background-swept" },
    [
      _c(
        "div",
        { staticClass: "_flexcol _bxsh--base" },
        _vm._l(_vm.itemsArray, function (item) {
          return _c(
            "div",
            { key: item, staticClass: "_flex _ai-c _jc-sb _px-2 _py-2" },
            [
              _c("skeleton-line", {
                staticClass: "_flex-basis-50",
                attrs: { height: 20 },
              }),
              _vm._v(" "),
              _c("skeleton-line", {
                staticClass: "_flex-basis-20",
                attrs: { height: 20 },
              }),
              _vm._v(" "),
              _c("skeleton-line", {
                staticClass: "_flex-basis-10",
                attrs: { height: 20 },
              }),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }