var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-standalone-layout _flexcol _jc-c _ai-c" },
    [
      _c(
        "box",
        { staticClass: "_flexcol _jc-c _ai-c" },
        [
          _c("base-image", {
            staticClass: "_lh-1 _mb-2 _tc--accent fa-beat-fade",
            attrs: {
              src: "/content/images/site-icon.svg",
              width: "54",
              height: "54",
            },
          }),
          _vm._v(" "),
          _c(
            "base-heading",
            { attrs: { tag: "h2", "data-qa": "payment-processing:Title" } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.Resources.Checkout.PaymentProcessingBoxTitle) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "_tac _px-8 _my-3",
              attrs: { "data-qa": "payment-processing:Description" },
            },
            [
              _c(
                "localize",
                {
                  attrs: {
                    resource:
                      _vm.Resources.Checkout.PaymentProcessingBoxDescription,
                  },
                },
                [
                  _c("span", { attrs: { "format-key": "0" } }, [
                    _vm._v(_vm._s(_vm.maxProcessingTimeMinutes)),
                  ]),
                  _vm._v(" "),
                  _c("span", { attrs: { "format-key": "1" } }, [
                    _vm._v(_vm._s(_vm.pluralizedMaxProcessingTimeMinutes)),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "base-button",
            {
              attrs: {
                tag: "a",
                theme: "accent",
                href: _vm.projectHomePageUrl,
                "data-qa": "payment-processing:ProjectHomeButton",
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.Resources.Checkout.PaymentProcessingBoxActionLabel
                  ) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }