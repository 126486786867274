import { projectPhases, projectVersions, orderStages, orderPaymentStatuses } from '@/Models';

export function isPledgeImplicitEditMode(project, orderStage, orderPaymentStatus) {

    if (project.version === projectVersions.v2 && project.phase > projectPhases.latePledge) {
        return orderStage === orderStages.pledged
            && (orderPaymentStatus === orderPaymentStatuses.paid || orderPaymentStatus === orderPaymentStatuses.scheduled);
    }

    return false;
}