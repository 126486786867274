
    export default {
        props: {
            items: {
                type: Array,
                default: () => [],
            },
            sliderPosition: {
                type: Number,
                required: true,
            },
            canLoadMoreItems: {
                type: Boolean,
                default: true,
            },
        },
        computed: {
            itemsWithActions() {
                return this.checkItemType(p => p.hasPendingAction);
            },
            itemsWithoutActions() {
                return this.checkItemType(p => !p.hasPendingAction);
            },
        },
        methods: {
            checkItemType(callback) {
                return this.items.length ? this.items.filter(callback) : [];
            },
            buildNode(i) {
                return this.$scopedSlots.item({ item: i });
            },
            isEmpty(array) {
                return !array.length;
            },
        },
        render(h) {
            const itemsWithAction = this.itemsWithActions.map(this.buildNode);
            const itemsWithoutAction = this.itemsWithoutActions.map(this.buildNode);
            let nodes = [this.$slots.default];

            const isItemsWithActionEmpty = this.isEmpty(itemsWithAction);
            const isItemsWithoutActionEmpty = this.isEmpty(itemsWithoutAction);

            if (!isItemsWithActionEmpty && isItemsWithoutActionEmpty && !this.canLoadMoreItems) {
                nodes = [itemsWithAction, this.$slots.default];
            }
            else if (!isItemsWithActionEmpty && isItemsWithoutActionEmpty) {
                nodes = [itemsWithAction];
            }
            else if (!isItemsWithActionEmpty && !isItemsWithoutActionEmpty) {
                nodes = [itemsWithAction, this.$slots.default, itemsWithoutAction];
            }
            else if (isItemsWithActionEmpty && !isItemsWithoutActionEmpty) {
                if (itemsWithoutAction.length >= this.sliderPosition) {
                    nodes = [itemsWithoutAction.slice(0, this.sliderPosition), this.$slots.default, itemsWithoutAction.slice(this.sliderPosition)];
                }
                else if (itemsWithoutAction.length < this.sliderPosition) {
                    nodes = [itemsWithoutAction, this.$slots.default];
                }
            }

            return h('div', nodes);
        },
    };

