var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-project-summary-action gfu-media",
      attrs: { "data-qa": "project-summary-backer" },
    },
    [
      _c("span", {
        staticClass:
          "gfu-project-summary-action__side _ga _ga--gamefound-backer _tc--accent",
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-media__body" },
        [
          _c("h5", { staticClass: "gfu-hd gfu-hd--h4 _ma-0" }, [
            _vm._v(_vm._s(_vm.backerNumberText)),
          ]),
          _vm._v(" "),
          _c(
            "base-link",
            {
              staticClass: "gfu-link gfu-link--accent",
              attrs: { url: _vm.href },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.Resources.ProjectHome.ProjectSummaryBoxAddMoreItemsText
                  ) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }