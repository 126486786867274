var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_flexcol _gap-1" },
    _vm._l(_vm.items, function (item) {
      return _c("category-box", {
        key: item.url,
        attrs: { url: item.url, "data-qa": item.qa },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "_flex _jc-sb _ai-c" },
                    [
                      _c("span", { staticClass: "_fw-b" }, [
                        _vm._v(" " + _vm._s(item.name) + " "),
                      ]),
                      _vm._v(" "),
                      _c("base-icon", {
                        class: item.iconColor,
                        attrs: {
                          "is-fixed-width": "",
                          name: item.icon,
                          size: "lg",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }