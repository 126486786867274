import ServiceBase from './ServiceBase';

export default class UserNotificationsService extends ServiceBase {
    getUserNotifications() {
        return this.request('get', 'userNotifications/getUserNotifications');
    }

    getUnreadNotificationsCount() {
        return this.request('get', 'userNotifications/getUnreadNotificationsCount');
    }

    dismissUserPendingAction(userPendingActionID) {
        return this.request('post', 'userNotifications/dismissUserPendingAction', { userPendingActionID });
    }
}