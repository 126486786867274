var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-project-mini-wizard-wrapper" },
    [
      _c(
        "box",
        { class: _vm.boxClasses, attrs: { main: true } },
        [
          _c(
            "div",
            { staticClass: "gfu-labels" },
            [
              _vm.isCrowdfundingOvertimeActive
                ? _c("countdown-badge", {
                    staticClass: "_ml-3",
                    attrs: {
                      theme: "urgent",
                      "campaign-end": _vm.campaignEndToDate,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "_fo _rounded--inherit" },
            [
              _vm._t("header"),
              _vm._v(" "),
              _c(
                "detect-overflow",
                {
                  staticClass: "gfu-project-mini-wizard__content",
                  attrs: { "data-qa": "mini-wizard-content" },
                },
                [_vm._t("default")],
                2
              ),
              _vm._v(" "),
              _vm._t("footer"),
            ],
            2
          ),
          _vm._v(" "),
          _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }