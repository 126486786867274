var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("go-to-checkout", {
    attrs: { "continue-url": _vm.checkoutUrl },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function ({ onInvoke }) {
            return [
              _c(
                "tooltip",
                {
                  attrs: {
                    enabled: !_vm.canProceedToCheckout,
                    text: _vm.message,
                  },
                },
                [
                  _c(
                    "base-button",
                    {
                      attrs: {
                        theme: _vm.submitPledgeButtonTheme,
                        animation: _vm.buttonAnimationType,
                        display: "block",
                        width: "narrow",
                        tag: "a",
                        href: _vm.checkoutUrl,
                        disabled: !_vm.canProceedToCheckout,
                        "data-qa": "mini-wizard-button:SubmitPledge",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return onInvoke.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._t("default")],
                    2
                  ),
                ],
                1
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }