import { userPendingActionTypes, projectPhases } from '@/Models.js';
import Resources from '@/Resources.js';

export function getProjectActionLabel(actionType, phase) {
    const isStore = phase === projectPhases.store;
    const projectActionLabels = {
        [userPendingActionTypes.unfinishedCart]: isStore
            ? Resources.MyProjects.StoreMyProjectUnfinishedCartActionLabel
            : Resources.MyProjects.MyProjectUnfinishedCartActionLabel,
        [userPendingActionTypes.pendingEdit]: Resources.MyProjects.MyProjectPendingEditActionLabel,
        [userPendingActionTypes.claimYourGift]: Resources.MyProjects.MyProjectClaimYourGiftActionLabel,
        [userPendingActionTypes.completeYourPledge]: Resources.MyProjects.MyProjectCompletePledgeActionLabel,
        [userPendingActionTypes.completePayment]: isStore
            ? Resources.MyProjects.StoreMyProjectCompletePaymentActionLabel
            : Resources.MyProjects.MyProjectCompletePaymentActionLabel,
        [userPendingActionTypes.viewYourPledge]: Resources.MyProjects.MyProjectViewYourPledgeActionLabel,
        [userPendingActionTypes.settleInstallments]: Resources.MyProjects.MyProjectViewYourPledgeActionLabel,
    };
    return projectActionLabels[actionType];
}
