var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("skeleton-line", { staticClass: "_mb-3", attrs: { height: 20 } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "_rounded--common _bxsh--base _flexcol _bgc--white" },
        _vm._l(_vm.itemsArray, function (item) {
          return _c(
            "div",
            {
              key: item,
              staticClass:
                "_flexaicntr _gap-2 _px-2 _py-2 _bdb--default _bc--disabled _pos-r gfu-ranking-list",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "_pos-r _bgc--white gfu-background-swept _gap-2 _flex _ai-c _grow-1",
                },
                [
                  _c(
                    "div",
                    { staticClass: "_flexaicntr _gap-1" },
                    [
                      _c("skeleton-image", { attrs: { size: 54 } }),
                      _vm._v(" "),
                      _c("skeleton-image", { attrs: { size: 64 } }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "_flex _gap-1 _grow-1 _jc-sb" }, [
                    _c(
                      "div",
                      { staticClass: "_flexcol _flex-basis-40 _gap-1" },
                      [
                        _c("skeleton-line", { attrs: { height: 22 } }),
                        _vm._v(" "),
                        _c("skeleton-line", { attrs: { height: 16 } }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "_flexcol _flex-basis-40 _gap-2 _jc-c" },
                      [
                        _c("skeleton-line", { attrs: { height: 16 } }),
                        _vm._v(" "),
                        _c("skeleton-line", { attrs: { height: 22 } }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }