import config from '@/Config';
import Log from '@/Utils/Log';

const cdnSettings = config.cdn;

/**
 * Ensures the given URL is using the CDN.
 * @param url {string}
 * @returns {string}
 */

export function ensureCdnUrl(url) {
    try {
        if (!cdnSettings.enabled || typeof url !== 'string') {
            return url;
        }

        url = url.toLowerCase();

        const transformKey = Object.keys(cdnSettings.transformations).find(k => url.startsWith(k));

        if (transformKey) {
            const transformation = cdnSettings.transformations[transformKey];
            const versionPath = transformation.versionNumber ? `${ transformation.versionNumber }/` : '';

            return `${transformation.baseUrl}${versionPath}${url.substring(transformKey.length)}`;
        }

        return url;
    }
    catch (error) {
        // This is a fallback for bugs in our app.
        // If we return null then image is simply not displayed.
        // If ensureCdnUrl throws an error it can crash entire component.

        Log.error(`ensureCdnUrl failed: ${ error }`);
        return url;
    }
}