var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: ["gfu-product", { "gfu-product--modal": _vm.isModal }],
      attrs: { "data-qa": `product-details-modal:${_vm.productID}` },
    },
    [
      _c(
        "div",
        { class: { "gfu-product__layout _pt-6": !_vm.isModal } },
        [
          _vm._t("top"),
          _vm._v(" "),
          _c("div", { staticClass: "gfu-product__row" }, [
            _c(
              "div",
              {
                class: [
                  "gfu-product__gallery",
                  { _mt_0: !_vm.productCanBePurchased },
                  "gfu-1of1 gfu-1of2--m",
                ],
              },
              [
                _vm.imageList.length
                  ? _c("product-details-gallery", {
                      attrs: { images: _vm.imageList },
                      on: { zoom: _vm.onZoom },
                    })
                  : _c("div", { staticClass: "gfu-product-details-gallery" }, [
                      _c("img", {
                        attrs: { src: _vm.mainImageUrl, alt: _vm.productName },
                      }),
                    ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "gfu-product__info",
                  { _mt_0: !_vm.productCanBePurchased },
                  "gfu-1of1 gfu-1of2--m",
                ],
                attrs: { "data-qa": "productinfo:TopRightBox" },
              },
              [
                _c(
                  "div",
                  { staticClass: "gfu-product-info" },
                  [
                    _vm.isDigital
                      ? _c(
                          "div",
                          { staticClass: "gfu-product-info__badge" },
                          [
                            _c(
                              "base-badge",
                              {
                                attrs: {
                                  "data-qa": "product-details:DigitalBadge",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.Resources.ProjectHome
                                        .DigitalProductBadge
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "_flex" }, [
                      _c(
                        "h1",
                        {
                          staticClass:
                            "gfu-product-info__name gfu-hd gfu-hd--d1 gfu-hd--decorative",
                          attrs: { "data-qa": "productinfo:ProductName" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.productName) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.isBuyable
                      ? [
                          _c("display-currency-price", {
                            attrs: {
                              price: _vm.price,
                              "effective-price": _vm.effectivePrice,
                              "is-discounted": _vm.isDiscounted,
                              "data-qa": "product-modal:Price",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "effective-price",
                                  fn: function ({ effectivePrice }) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "gfu-price gfu-price--big _as-b",
                                          attrs: {
                                            "data-qa": "price-type:Effective",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(effectivePrice) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "price",
                                  fn: function ({ price }) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "gfu-price gfu-price--old _fw-n _as-b _ml-1",
                                          attrs: {
                                            "data-qa": "price-type:Old",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(price) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3256433447
                            ),
                          }),
                          _vm._v(" "),
                          _vm.isDiscounted
                            ? _c(
                                "localize",
                                {
                                  staticClass:
                                    "_flex _ai-b gfu-bt gfu-bt--caption _tc--lighter",
                                  attrs: {
                                    resource:
                                      _vm.Resources.Product
                                        .RewardCardLowestPriceInLast30Days,
                                    tag: "div",
                                  },
                                },
                                [
                                  _c("display-currency-price", {
                                    attrs: {
                                      "format-key": "0",
                                      "effective-price": _vm.effectivePrice,
                                      price: _vm.price,
                                      tag: "span",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "effective-price",
                                          fn: function ({ effectivePrice }) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "_mx-1",
                                                  attrs: {
                                                    "data-qa":
                                                      "price-type:ThirtyDaysLowest",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(effectivePrice) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      574834570
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.shouldShowStockLimit
                            ? _c(
                                "base-badge",
                                {
                                  staticClass: "gfu-product-info__badge",
                                  attrs: { "data-qa": "productinfo:StockLeft" },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.Resources.format(
                                          _vm.Resources.ProjectHome
                                            .ProductStockLeftLabel,
                                          _vm.$format(
                                            _vm.remainingStockLimit,
                                            "N0"
                                          )
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.estimatedDeliveryAt
                      ? _c(
                          "div",
                          { staticClass: "_flex _my-3" },
                          [
                            _c("span", {
                              staticClass: "_fa _fa--shipping-fast",
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "_mx-1" }, [
                              _vm._v(_vm._s(_vm.estimatedDeliveryDateToLabel)),
                            ]),
                            _vm._v(" "),
                            _vm.deliveryDateRemarks
                              ? _c(
                                  "tooltip",
                                  {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "content",
                                          fn: function () {
                                            return [
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.deliveryDateRemarks
                                                  ),
                                                },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3721022686
                                    ),
                                  },
                                  [_c("span", { staticClass: "_fa _fa--info" })]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("optional-text", {
                      staticClass: "gfu-product-info__abstract",
                      attrs: {
                        content: _vm.abstract,
                        "data-qa": "productinfo:Abstract",
                      },
                    }),
                    _vm._v(" "),
                    _vm.addProductToCartModel.readOnly
                      ? _c("div", { staticClass: "_flex _mt-3" }, [
                          _c("span", { staticClass: "_fa _fa--info _mr-1" }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { attrs: { "data-qa": "productinfo:NonBuyable" } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.addProductToCartModel.tooltipText
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ])
                      : _vm.canAddToCart
                      ? _c(
                          "add-product-to-cart",
                          _vm._b(
                            {
                              ref: "add-to-cart-button",
                              staticClass:
                                "gfu-product-info__add-to-cart _mt-3",
                              attrs: { section: "reward-card-top" },
                              on: {
                                "item-added": _vm.onItemAdded,
                                "quantity-changed": _vm.onQuantityChange,
                              },
                            },
                            "add-product-to-cart",
                            _vm.addProductToCartModel,
                            false
                          )
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.productType === _vm.productTypes.set && _vm.setItems.length
            ? _c(
                "div",
                { staticClass: "gfu-product-content _mt-3" },
                [
                  _c(
                    "div",
                    { staticClass: "gfu-hd gfu-hd--h2 _tc--base _mb-3" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.Resources.ProjectHome.SetContentSectionHeader
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("product-details-set-items", {
                    attrs: {
                      "set-items": _vm.setItems,
                      context: _vm.context,
                      "product-details-display-mode":
                        _vm.productDetailsDisplayMode,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(
            _vm.productsDetailsData,
            function ({ headerText, content, type }, index) {
              return _c("optional-text", {
                key: `${index}-${headerText}`,
                staticClass: "gfu-product-content _mt-3",
                attrs: {
                  content: content,
                  "is-rich-text": true,
                  "is-article": true,
                  type: type,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c(
                            "h2",
                            {
                              staticClass: "gfu-hd gfu-hd--h2 _tc--base _mb-2",
                            },
                            [_vm._v(_vm._s(headerText))]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              })
            }
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm.canAddToCart
        ? _c(
            "div",
            { ref: "sticky-footer", class: _vm.stickyFooterClassList },
            [
              _c(
                "add-product-to-cart",
                _vm._b(
                  {
                    staticClass:
                      "gfu-product-info__add-to-cart gfu-product-info__add-to-cart--sticky _mb-0",
                    attrs: {
                      section: "reward-card-bottom",
                      "wrapper-class-list": [
                        "gfu-product__amount-wrapper--sticky",
                      ],
                    },
                    on: {
                      "item-added": _vm.onItemAdded,
                      "quantity-changed": _vm.onQuantityChange,
                    },
                  },
                  "add-product-to-cart",
                  _vm.addProductToCartModel,
                  false
                )
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { ref: "footer-section-trigger" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }