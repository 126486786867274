import Formatting from '@/Utils/Formatting';
import { pluralize } from '@/Utils/ResourceUtility';
import { rawResources as defaultResources } from '~/Scripts/Resources/resources.en.js';

const Resources = window.__RESOURCES__ || {};

// Creating a Proxy to prevent "Cannot read properties of undefined" errors
const safeProxy = (obj) => {
    return new Proxy(obj, {
        get(target, prop) {
            if (prop in target) {
                return typeof target[prop] === 'object' && target[prop] !== null
                    ? safeProxy(target[prop]) // Recursively safeguard nested objects
                    : target[prop];
            }
            return ''; // Return an empty string instead of undefined to prevent errors
        },
    });
};

const safeResources = safeProxy({ ...defaultResources, ...Resources });

const module = {
    format() {
        return Formatting.String.format(...arguments);
    },
    pluralize(count, str) {
        return pluralize(count, str);
    },
    ...safeResources,
};

export default module;