var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.recommendedProducts.length
        ? _c(
            "slider-container",
            {
              attrs: {
                "arrow-style": "circle",
                centered: false,
                "has-negative-margin": true,
                "data-qa": "product-recommendation-slider",
              },
              on: {
                "scroll-left": function ($event) {
                  return _vm.onScroll("left")
                },
                "scroll-right": function ($event) {
                  return _vm.onScroll("right")
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("h3", { staticClass: "gfu-heading _py-3" }, [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.YourPledge
                                .YourPledgeRecommendationTitle
                            )
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3497144332
              ),
            },
            [
              _vm._v(" "),
              _vm._l(_vm.recommendedProducts, function (item) {
                return _c("product-recommendation-item", {
                  key: item.productID,
                  class: _vm.slideWidthClasses,
                  attrs: {
                    item: item,
                    section: _vm.section,
                    "data-qa": `recommendation-item-number:${item.productID}`,
                  },
                  on: { "product-added": _vm.onProductAdded },
                })
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isProcessing
        ? _c("skeleton-product-recommendations-slider", {
            attrs: {
              "slide-width-classes": _vm.slideWidthClasses,
              "item-count": _vm.slidesVisibleOnDesktop,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }