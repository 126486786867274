var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "project-summary-box",
    {
      staticClass: "gfu-project-summary-overtime-card _tac _fo",
      attrs: { borderless: "", "data-qa": "project-summary-box:Endgame" },
    },
    [
      _c("div", { staticClass: "_tac" }, [
        _c(
          "h4",
          { staticClass: "gfu-hd gfu-hd--h4 _flex _ai-c _jc-c _mb-1" },
          [
            _c("base-icon", {
              staticClass: "_tc--urgent _mr-1",
              attrs: { size: "lg", name: "arrow-right-from-line" },
            }),
            _vm._v(" "),
            _c("span", [
              _vm._v(_vm._s(_vm.Resources.ProjectHome.ProjectOvertimeTitle)),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("span", { staticClass: "gfu-bt--caption" }, [
          _vm._v(
            _vm._s(
              _vm.Resources.ProjectHome.ProjectOvertimeExplanationParagraph
            )
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "h2",
          { staticClass: "gfu-hd gfu-hd--h2 _my-0 _tc--dark" },
          [
            _c("display-currency-value", {
              attrs: { tag: "span" },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function ({ displayCurrency }) {
                    return [
                      _c("animated-number", {
                        staticClass: "gfu-hd gfu-hd--h2 _flexinline",
                        attrs: {
                          tag: "h3",
                          number: _vm.roundToIntegers(
                            _vm.fundsGathered / displayCurrency.relativeFactor
                          ),
                          format: "C0",
                          "currency-symbol": displayCurrency.symbol,
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c("animated-number", {
                        staticClass: "gfu-hd gfu-hd--h2 _flexinline",
                        attrs: {
                          tag: "h3",
                          number: _vm.fundsGatheredFormatted,
                          format: "C0",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(_vm.Resources.ProjectHome.ProjectOvertimeFoundedLabel)
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("display-currency-value", {
              attrs: { tag: "span" },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function ({ displayCurrency }) {
                    return [
                      _c("animated-number", {
                        staticClass: "_flexinline",
                        attrs: {
                          tag: "span",
                          number: _vm.roundToIntegers(
                            _vm.fundsGatheredOvertime /
                              displayCurrency.relativeFactor
                          ),
                          format: "C0",
                          "currency-symbol": displayCurrency.symbol,
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c("animated-number", {
                        staticClass: "_flexinline",
                        attrs: {
                          tag: "span",
                          number: _vm.fundsGatheredOvertimeFormatted,
                          format: "C0",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(
              " " +
                _vm._s(
                  _vm.Resources.ProjectHome.ProjectOvertimeExtendedTimeLabel
                ) +
                "\n        "
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "box",
        {
          staticClass: "_bgc--urgent _flexcol _tc--white gfu-flash _jc-c _ai-c",
          attrs: { slim: "" },
        },
        [
          _c("span", { staticClass: "_mb-1" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.Resources.ProjectHome.ProjectOvertimeTimerText) +
                "\n            "
            ),
            _c(
              "h2",
              {
                staticClass: "gfu-hd--h2 _my-0 _flexinline",
                attrs: { "data-qa": "project-summary:EndgameTimer" },
              },
              [
                _c("timer-component", {
                  staticClass: "_mt-a",
                  attrs: { timestamp: _vm.campaignEndToDate },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ seconds, minutes }) {
                        return [
                          _vm._v(
                            "\n                    " +
                              _vm._s(minutes) +
                              " " +
                              _vm._s(
                                _vm.Resources.pluralize(
                                  minutes,
                                  _vm.Resources.ProjectHome
                                    .HumanizeDatePluralizedMinuteShort
                                )
                              ) +
                              "\n                    " +
                              _vm._s(seconds) +
                              " " +
                              _vm._s(
                                _vm.Resources.pluralize(
                                  seconds,
                                  _vm.Resources.ProjectHome
                                    .HumanizeDatePluralizedSecondShort
                                )
                              ) +
                              "\n                "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "back-project-button",
            {
              staticClass:
                "gfu-project-summary-action gfu-btn--urgent-alt _bxsh--base _bgc--white _tc--urgent",
              attrs: { theme: "default", "data-qa": "project-summary:BackNow" },
            },
            [_vm._v("\n            " + _vm._s(_vm.buttonLabel) + "\n        ")]
          ),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "gfu-bt--caption _mt-1 _fw-b" },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.Resources.ProjectHome.ProjectOvertimeTooltipTriggerText
                  ) +
                  "\n            "
              ),
              _c("tooltip", {
                attrs: {
                  "icon-name": "info",
                  text: _vm.Resources.ProjectHome.ProjectOvertimeTooltipText,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-bt--caption" }, [
        !_vm.hasOvertimeBackers
          ? _c("span", [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.Resources.ProjectHome
                      .ProjectOvertimeBackersEmptyStateText
                  ) +
                  "\n        "
              ),
            ])
          : _c("span", [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.Resources.ProjectHome.ProjectOvertimeExtendedText2
                  ) +
                  "\n            "
              ),
              _c("strong", [_vm._v(_vm._s(_vm.campaignExtendedBy))]),
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.Resources.ProjectHome.ProjectOvertimeExtendedText1
                  ) +
                  "\n            "
              ),
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.Resources.pluralize(
                      _vm.backersCountOvertime,
                      _vm.Resources.ProjectHome
                        .ProjectSummaryProgressPluralizedBacker
                    )
                  )
                ),
              ]),
              _vm._v(".\n        "),
            ]),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "avatarStackContainer",
            class: _vm.avatarStackContainerClasses,
          },
          [
            _c(
              "transition-group",
              {
                class: _vm.avatarListClasses,
                attrs: { name: "gfu-avatar-stack-container" },
              },
              _vm._l(_vm.displayedBackers, function (backer) {
                return _c("base-avatar", {
                  key: backer.userID,
                  staticClass: "gfu-avatar-stack-container__item _-mr-1",
                  attrs: {
                    size: "xxs",
                    bordered: "",
                    "avatar-url": backer.userAvatarImageUrl,
                    "avatar-nickname": backer.userNickname,
                  },
                })
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "gfu-project-card__action-button",
          on: { click: _vm.toggleShowDetails },
        },
        [
          _c("base-icon", {
            attrs: { "icon-namespace": "ga", name: "revert" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }