var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "_bgc--white gfu-announcement-box__slider gfu-announcement-box",
    },
    [
      _c("div", { staticClass: "gfu-base-slider-slide is-active" }, [
        _c(
          "div",
          {
            staticClass: "gfu-background-swept gfu-announcement-content _gap-5",
          },
          [
            _c("skeleton-image", {
              staticClass: "gfu-announcement-content__packshot-wrapper",
            }),
            _vm._v(" "),
            _c("div", { staticClass: "gfu-1of1 gfu-1of2--m" }, [
              _c(
                "div",
                { staticClass: "_flexcol _gap-1 _grow-1" },
                [
                  _c("skeleton-line", { attrs: { height: 18 } }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "_flex _jc-sb" },
                    [
                      _c("skeleton-line", {
                        staticClass: "_flex-basis-60",
                        attrs: { height: 10 },
                      }),
                      _vm._v(" "),
                      _c("skeleton-line", {
                        staticClass: "_flex-basis-33",
                        attrs: { height: 10 },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("skeleton-line", { attrs: { height: 10 } }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "_flex _jc-c _mt-3" },
                    [_c("skeleton-button", { staticClass: "_flex-basis-50" })],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }