import { defineStore } from 'pinia';
import { states } from '@/Utils/WebPushUtility';

export const usePushNotificationStore = defineStore({
    id: 'pushNotification',
    state: () => ({
        isModalVisible: true,
        pushPermissionStatus: states.unauthorized,
    }),
    actions: {
        onModalOpen() {
            this.isModalVisible = true;
        },
        onModalClose() {
            this.isModalVisible = false;
        },
        setPushPermissionStatus(status) {
            this.pushPermissionStatus = status;
        },
    },
    getters: {
        isPushPermissionStatusDenied() {
            return this.pushPermissionStatus === states.denied;
        },
        isPushPermissionStatusDefault() {
            return this.pushPermissionStatus === states.default;
        },
        isPushPermissionStatusUnauthorized() {
            return this.pushPermissionStatus === states.unauthorized;
        },
    },
});