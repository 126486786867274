var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.wrapperElement.tag,
    _vm._b(
      { tag: "component", staticClass: "gfu-link-underline _block" },
      "component",
      _vm.wrapperElement.props,
      false
    ),
    [
      _c(
        "base-card",
        {
          attrs: { "extended-wrapper-class": "_flexrow _pa-1" },
          scopedSlots: _vm._u([
            {
              key: "media",
              fn: function () {
                return [
                  _c("base-image", {
                    staticClass: "_mr-2 _rounded--common",
                    attrs: { width: "80", src: _vm.imageUrl, loading: "lazy" },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "_ma-0 _flex _jc-sb" },
            [
              _vm._t("message-title", function () {
                return [
                  _vm.messageTitle
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "gfu-bt gfu-bt--caption _tc--lighter _ttu",
                        },
                        [_vm._v(_vm._s(_vm.messageTitle))]
                      )
                    : _vm._e(),
                ]
              }),
              _vm._v(" "),
              _vm.createdAt
                ? _c("notification-delivery-time", {
                    staticClass:
                      "gfu-bt gfu-bt--caption _tc--lighter _shrink-0",
                    attrs: { timestamp: _vm.notificationCreatedAt },
                  })
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _vm._t("message-text", function () {
            return [
              _vm.messageText
                ? _c("p", { staticClass: "_ma-0" }, [
                    _vm._v(_vm._s(_vm.messageText)),
                  ])
                : _vm._e(),
            ]
          }),
          _vm._v(" "),
          _vm._t("actions"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }