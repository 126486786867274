import { projectPhaseSearchType, projectBenefitTypes, projectSearchSortType, projectListItemTypes } from '@/Models';
import Resources from '@/Resources.js';
import Config from '@/Config.js';

export const projectExplorationCategoryKeys = {
    recommendations: 'recommendations',
    active: 'active',
    endingSoon: 'endingSoon',
    stretchPay: 'stretchPay',
    crowdfunding: 'crowdfunding',
    latePledge: 'latePledge',
    upcoming: 'upcoming',
    mostFunded: 'mostFunded',
    mostPopular: 'mostPopular',
    mostPopularUpcoming: 'mostPopularUpcoming',
    mostDiscussed: 'mostDiscussed',
    recentlyAddedUpdates: 'recentlyAddedUpdates',
    preorders: 'preorders',
};

export const projectExplorerSearchParamsValues = {
    [projectExplorationCategoryKeys.recommendations]: {},
    [projectExplorationCategoryKeys.active]: {
        projectPhaseSearchTypes: [
            projectPhaseSearchType[projectPhaseSearchType.crowdfunding].name,
            projectPhaseSearchType[projectPhaseSearchType.latePledge].name,
        ],
        includeRefs: false, // TODO: Remove in #20854
    },
    [projectExplorationCategoryKeys.endingSoon]: {
        projectPhaseSearchTypes: [
            projectPhaseSearchType[projectPhaseSearchType.crowdfunding].name,
        ],
        sortType: projectSearchSortType[projectSearchSortType.endingSoon].name,
        includeRefs: false, // TODO: Remove in #20854
    },
    [projectExplorationCategoryKeys.stretchPay]: {
        projectBenefits: [
            projectBenefitTypes[projectBenefitTypes.stretchPay].name,
        ],
        projectPhaseSearchTypes: [
            projectPhaseSearchType[projectPhaseSearchType.crowdfunding].name,
            projectPhaseSearchType[projectPhaseSearchType.latePledge].name,
        ],
        includeRefs: false, // TODO: Remove in #20854
    },
    [projectExplorationCategoryKeys.crowdfunding]: {
        projectPhaseSearchTypes: [
            projectPhaseSearchType[projectPhaseSearchType.crowdfunding].name,
        ],
        includeRefs: false, // TODO: Remove in #20854
    },
    [projectExplorationCategoryKeys.upcoming]: {
        projectPhaseSearchTypes: [
            projectPhaseSearchType[projectPhaseSearchType.upcoming].name,
        ],
        includeRefs: false, // TODO: Remove in #20854
    },
    [projectExplorationCategoryKeys.latePledge]: {
        projectPhaseSearchTypes: [
            projectPhaseSearchType[projectPhaseSearchType.latePledge].name,
        ],
        includeRefs: false, // TODO: Remove in #20854
    },
    [projectExplorationCategoryKeys.mostFunded]: {
        projectPhaseSearchTypes: [
            projectPhaseSearchType[projectPhaseSearchType.crowdfunding].name,
            projectPhaseSearchType[projectPhaseSearchType.latePledge].name,
        ],
        sortType: projectSearchSortType[projectSearchSortType.mostFunded].name,
        includeRefs: false,
    },
    [projectExplorationCategoryKeys.mostPopularUpcoming]: {
        projectPhaseSearchTypes: [
            projectPhaseSearchType[projectPhaseSearchType.upcoming].name,
        ],
        sortType: projectSearchSortType[projectSearchSortType.mostPopular].name,
        includeRefs: false,
    },
    [projectExplorationCategoryKeys.mostDiscussed]: {
        projectPhaseSearchTypes: [
            projectPhaseSearchType[projectPhaseSearchType.upcoming].name,
            projectPhaseSearchType[projectPhaseSearchType.crowdfunding].name,
            projectPhaseSearchType[projectPhaseSearchType.latePledge].name,
            projectPhaseSearchType[projectPhaseSearchType.preorder].name,
        ],
        sortType: projectSearchSortType[projectSearchSortType.mostDiscussed].name,
        includeRefs: false,
    },
    [projectExplorationCategoryKeys.recentlyAddedUpdates]: {
        projectPhaseSearchTypes: [
            projectPhaseSearchType[projectPhaseSearchType.upcoming].name,
            projectPhaseSearchType[projectPhaseSearchType.crowdfunding].name,
            projectPhaseSearchType[projectPhaseSearchType.latePledge].name,
            projectPhaseSearchType[projectPhaseSearchType.preorder].name,
        ],
        sortType: projectSearchSortType[projectSearchSortType.recentUpdates].name,
        includeRefs: false,
    },
    [projectExplorationCategoryKeys.preorders]: {
        projectPhaseSearchTypes: [
            projectPhaseSearchType[projectPhaseSearchType.preorder].name,
        ],
        includeRefs: false,
    },
};

export const buildExploreCategories = (isAuthenticated = false) => [
    {
        label: isAuthenticated
            ? Resources.Homepage.ExploreCategoryForYou
            : Resources.Homepage.ExploreCategoryYouMayLike,
        key: projectExplorationCategoryKeys.recommendations,
        searchParams: projectExplorerSearchParamsValues[projectExplorationCategoryKeys.recommendations],
        actionLabel: Resources.Homepage.ExploreCategoryActionLabelGeneral,
        url: buildExploreUrlParams(projectExplorerSearchParamsValues[projectExplorationCategoryKeys.recommendations]),
    },
    {
        label: Resources.Homepage.ExploreCategoryActive,
        key: projectExplorationCategoryKeys.active,
        searchParams: projectExplorerSearchParamsValues[projectExplorationCategoryKeys.active],
        actionLabel: Resources.Homepage.ExploreCategoryActionLabelActive,
        url: buildExploreUrlParams(projectExplorerSearchParamsValues[projectExplorationCategoryKeys.active]),
    },
    {
        label: Resources.Homepage.ExploreCategoryEndingSoon,
        key: projectExplorationCategoryKeys.endingSoon,
        searchParams: projectExplorerSearchParamsValues[projectExplorationCategoryKeys.endingSoon],
        actionLabel: Resources.Homepage.ExploreCategoryActionLabelEndingSoon,
        url: buildExploreUrlParams(projectExplorerSearchParamsValues[projectExplorationCategoryKeys.endingSoon]),
    },
    {
        label: Resources.Homepage.ExploreCategoryInstallments,
        key: projectExplorationCategoryKeys.stretchPay,
        searchParams: projectExplorerSearchParamsValues[projectExplorationCategoryKeys.stretchPay],
        actionLabel: Resources.Homepage.ExploreCategoryActionLabelInstallments,
        url: buildExploreUrlParams(projectExplorerSearchParamsValues[projectExplorationCategoryKeys.stretchPay]),
    },
];

function buildExploreUrlParams(searchParams) {
    const params = removeIncludeRefsProp(searchParams); // TODO: Remove in #20854

    const query = new URLSearchParams();
    Object.entries(params).forEach(([k, v]) => query.set(k, v));

    return `${Config.navigation.searchUrl}/?${query}`;
}

export function buildProjectExplorerModel(label, key, searchParams, actionLabel) {
    const params = removeIncludeRefsProp(searchParams); // TODO: Remove in #20854

    return [{
        label,
        key,
        searchParams,
        actionLabel,
        url: buildExploreUrlParams(params),
    }];
}

export const homeProjectListsCategoryKeys = {
    [projectListItemTypes.storesAndPreorders]: 'storesAndPreorders',
    [projectListItemTypes.upcoming]: 'upcoming',
    [projectListItemTypes.crowdfunding]: 'crowdfunding',
    [projectListItemTypes.latePledge]: 'latePledge',
    [projectListItemTypes.soloMode]: 'soloMode',
    [projectListItemTypes.stretchPay]: 'stretchPay',
    [projectListItemTypes.ttrpg]: 'ttrpg',
};
// TODO: Remove in #20854
function removeIncludeRefsProp(searchParams) {
    const params = { ...searchParams };
    delete params.includeRefs;
    return params;
}
